import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

import { AlertComponent, BadgeComponent, ButtonComponent, IconButtonComponent } from '@core/components';
import { AlertService, ClassifiersService } from '@core/services';
import { Classifier, ClassifiersWithTotalList, ClassifierType, SubdivisionClassifier } from '@core/models';
import { ClassifiersEnum, SubdivisionTypesEnum } from '@core/utils';
import { ClassifiersPositionType, TotalDataType } from '@core/types';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import { Overlay } from '@angular/cdk/overlay';
import {
  AddSubdivisionClassifierModalComponent,
} from '@scripter-admin/app/modals/classifiers-modal/add-subdivision-classifier-modal/add-subdivision-classifier-modal.component';
import {
  AddSimpleClassifierModalComponent,
} from '@scripter-admin/app/modals/classifiers-modal/add-simple-classifier-modal/add-simple-classifier-modal.component';
import {
  EditSubdivisionClassifierModalComponent,
} from '@scripter-admin/app/modals/classifiers-modal/edit-subdivision-classifier-modal/edit-subdivision-classifier-modal.component';
import {
  EditSimpleClassifierModalComponent,
} from '@scripter-admin/app/modals/classifiers-modal/edit-simple-classifier-modal/edit-simple-classifier-modal.component';
import { AddClassifierModal } from '../modals/classifiers-modal/add-classifier-modal';
import { EditClassifierModal } from '../modals/classifiers-modal/edit-classifier-modal';

@Component({
  selector: 'app-classifier-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    BadgeComponent,
    NgOptimizedImage,
    CdkDrag,
    CdkDropList,
  ],
  templateUrl: './classifiers.component.html',
  styleUrl: './classifiers.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassifiersComponent implements OnInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);

  public classifiers = signal<ClassifiersWithTotalList>({
    [ClassifiersEnum.applicantCategories]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.departments]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.divisions]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.lifeSituations]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.territoriality]: {
      total: 0,
      data: [],
    },
  });
  public classifiersTypes: ClassifierType[] = [
    {
      name: 'Жизненные ситуации',
      key: ClassifiersEnum.lifeSituations,
      opened: true,
    },
    {
      name: 'Категория заявителей',
      key: ClassifiersEnum.applicantCategories,
      opened: true,
    },
    {
      name: 'Территориальная принадлежность',
      key: ClassifiersEnum.territoriality,
      opened: true,
    },
    {
      name: 'Подразделение',
      key: ClassifiersEnum.departments,
      opened: true,
    },
    {
      name: 'Ведомство',
      key: ClassifiersEnum.divisions,
      opened: true,
    },
  ];
  subdivisionsTypesList: { value: SubdivisionTypesEnum; label: string }[] = [
    {
      value: SubdivisionTypesEnum.Affiliate,
      label: 'Филиал',
    },
    {
      value: SubdivisionTypesEnum.Department,
      label: 'Отделение',
    },
    {
      value: SubdivisionTypesEnum.URM,
      label: 'УРМ',
    },
    {
      value: SubdivisionTypesEnum.TOSP,
      label: 'ТОСП',
    },
    {
      value: SubdivisionTypesEnum.BusinessOffice,
      label: 'Бизнес офис',
    },
  ];

  public currentPositions: ClassifiersPositionType[] = [];
  public isSearchClassifiersBlocked: boolean = false;
  public isDropBlocked: boolean = false;
  public classifiersCountStep: number = 3;

  constructor(
    private readonly classifiersService: ClassifiersService,
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ classifiers }) => {
      this.classifiers.set(classifiers);
    });
  }

  openAddModal(type: ClassifierType) {
    const modal = openModal<AddClassifierModal>(
      this.overlay,
      type.key === ClassifiersEnum.departments
        ? AddSubdivisionClassifierModalComponent
        : AddSimpleClassifierModalComponent,
      {
        title: type.name,
        type: type,
      },
    );

    if (type.key === ClassifiersEnum.departments) {
      modal.componentRef.instance.submit.subscribe((classifier: SubdivisionClassifier) => {
        this.classifiers.update((classifiers: ClassifiersWithTotalList) => {
          classifiers[type.key].data.unshift(classifier);
          this.cdr.markForCheck();
          return classifiers;
        });
      });
    } else {
      modal.componentRef.instance.submit.subscribe((classifier: Classifier) => {
        this.classifiers.update((classifiers: ClassifiersWithTotalList) => {
          (classifiers[type.key].data as Classifier[]).unshift(classifier);
          this.cdr.markForCheck();
          return classifiers;
        });
      });
    }
  }

  openEditModal(type: ClassifierType, classifier: Classifier) {
    const modal = openModal<EditClassifierModal>(
      this.overlay,
      type.key === ClassifiersEnum.departments
        ? EditSubdivisionClassifierModalComponent
        : EditSimpleClassifierModalComponent,
      {
        title: type.name,
        type: type,
        classifier: classifier,
      }
    )

    modal.componentRef.instance.submit.subscribe((classifier: Classifier | SubdivisionClassifier) => {
      const index = this.classifiers()[type.key].data.findIndex(x => x.id === classifier.id);

      if (index !== -1) {
        this.classifiers.update((classifiers: ClassifiersWithTotalList) => {
          classifiers[type.key].data[index] = classifier;
          this.cdr.markForCheck();
          return classifiers;
        })
      } else {
        throw new Error();
      }
    });
  }

  onDeleteClick(id: number, type: ClassifiersEnum, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить классификатор "${name}"?`,
    );

    if (isAccepted) {
      this.classifiersService.deleteClassifier(id, { type }).subscribe({
        next: () => {
          this.classifiers.update((classifiers: ClassifiersWithTotalList) => {
            classifiers[type].data = this.classifiers()[type].data.filter(
              classifier => classifier.id !== id,
            );
            this.cdr.markForCheck();
            return classifiers;
          });
          this.alertService.toggle('Классификатор удален');
        },
        error: (err: Error) => console.error(err),
      });
    } else return;
  }

  toggleOpened(key: string): void {
    const type = this.classifiersTypes.find(
      type => type.key === key,
    ) as ClassifierType;
    type.opened = !type.opened;
  }

  showMoreClick(classifierType: ClassifierType): void {
    if (!this.isSearchClassifiersBlocked) {
      this.isSearchClassifiersBlocked = true;
      this.classifiersService
        .getClassifiers({
          [classifierType.key]: {
            from: 0,
            to:
              this.classifiers()[classifierType.key].data.length +
              this.classifiersCountStep,
          },
        })
        .subscribe({
          next: (response: ClassifiersWithTotalList) => {
            this.classifiers.update((classifiers: ClassifiersWithTotalList) => {
              if (classifierType.key === 'departments') {
                classifiers[classifierType.key] =
                  response[classifierType.key] as TotalDataType<SubdivisionClassifier[]>;
              } else {
                classifiers[classifierType.key] =
                  response[classifierType.key] as TotalDataType<Classifier[]>;
              }
              this.cdr.markForCheck();
              return classifiers;
            });
            this.isSearchClassifiersBlocked = false;
          },
        });
    }
  }

  public drop(type: ClassifiersEnum, event: CdkDragDrop<Classifier[]>): void {
    if (this.isDropBlocked) {
      return;
    }
    moveItemInArray(
      this.classifiers()[type].data,
      event.previousIndex,
      event.currentIndex,
    );
    this.currentPositions = this.classifiers()[type].data.map(
      (classifier: Classifier, position: number) => ({
        id: classifier.id,
        position,
      }),
    );
    this.isDropBlocked = true;
    this.classifiersService
      .updateClassifiersPositions(type, this.currentPositions)
      .subscribe({
        next: (response: Classifier[]) => {
          this.classifiers.update((classifiers: ClassifiersWithTotalList) => {
            classifiers[type].data = response.slice(
              0,
              classifiers[type].data.length,
            );
            this.cdr.markForCheck();
            return classifiers;
          });
          this.isDropBlocked = false;
        },
        error: (err: Error) => {
          console.error(err);
          this.isDropBlocked = false;
        },
      });
  }

  public getSubdivisionByKey(key: ClassifiersEnum, classifierId: number): {
    name: string;
  } {
    const element = this.classifiers()['departments'].data.find(x => x.id === classifierId);
    const classifierType = this.subdivisionsTypesList.find(x => x.value === element!.type)!.label;

    if (!element) {
      throw new Error();
    }

    return {
      name: classifierType,
    };
  }

  protected readonly ClassifiersEnum = ClassifiersEnum;
}
