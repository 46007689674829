import { Component, EventEmitter, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService, DocsSampleService } from '@core/services';
import { DocumentTemplate } from '@core/models';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class DocSampleModal extends Modal {
  abstract formGroup: FormGroup;
  protected readonly docsSampleService = inject(DocsSampleService)
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<DocumentTemplate>();
  public files = signal<File[]>([])
  public titleError = signal<string>('');

  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (!values.title?.trim().length) {
      errors['title'] = 'Заголовок не должен быть пустым';
    }

    this.titleError.set(errors['title']);

    return !Object.keys(errors).length;
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }

  public resetInputError(): void {
    this.titleError.set('');
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files: File[] = Array.from(event.dataTransfer.files);

      for (const file of files) {
        if (!this.files().some((e: File) => e.name === file.name)) {
          this.files().push(file);
        }
      }
    }
  }

  onFileSelected(event: any): void {
    const files: File[] = event.target.files;

    for (const file of files) {
      this.files().push(file);
    }
  }

  removeFile(index: number): void {
    this.files().splice(index, 1);
  }
}
