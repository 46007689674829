import { Component, EventEmitter } from "@angular/core";

@Component({
    template: ``,
})
export abstract class Modal {
    public close = new EventEmitter<void>();
    public closeModal() {
        this.close.emit();
    }
}