import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, TextInputComponent } from '@core/components';
import { EditClassifierModal } from '@scripter-admin/app/modals/classifiers-modal/edit-classifier-modal';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-edit-simple-classifier-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './edit-simple-classifier-modal.component.html',
  styleUrl: './edit-simple-classifier-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSimpleClassifierModalComponent extends EditClassifierModal implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  ngOnInit() {
    this.formGroup.controls.name.setValue(this.classifier().name);
  }

  formSubmit() {
    this.onFormSubmit({
      name: this.formGroup.value.name!,
      type: this.type().key
    });
  }
}
