<div class="users-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">
      Пользователи
      <span class="top-div__badge">
        <app-badge
          size="normal"
          display="tint"
          color="secondary-container"
          size_text="default"
          badge_content="{{ users().length }}"></app-badge>
      </span>
    </h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="openAddModal()"
      ></app-button>
    </div>
  </div>
  <div class="search-zone area_white_bg">
    <div class="search-zone__input">
      <core-text-input
        placeholder="Поиск пользователя"
        leftIcon="search"
        [formControl]="name"
      ></core-text-input>
    </div>
  </div>
  <div class="tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!--    <button class="filter">-->
    <!--      В алфавитном порядке-->
    <!--      <img src="../../assets/icons/keyboard_arrow_down.svg" alt="↓">-->
    <!--    </button>-->
  </div>
</div>

<div class="users">
  @switch (currentTab) {
    @case ('tabs__available') {
      @if (users().length === 0) {
        <p class="text-bigger">Нет пользователей</p>
      }
      @for (cont of users(); track cont.id) {
        <article class="user">
          <div class="user__row">
            <h3 class="user__text text-big">
              {{ cont.firstName + ' ' + cont.middleName + ' ' + cont.lastName }}
            </h3>
            <div class="user__buttons">
              <app-icon-button
              size="small"
                type="circle"
                icon="cancel"
                display="outline"
                color="outline-low"
                (click)="handleClickDeleteUser(cont.id, cont.firstName + ' ' + cont.lastName)"
              ></app-icon-button>
              <app-icon-button
                size="small"
                type="circle"
                icon="edit_square"
                display="outline"
                color="outline-low"
                (click)="openEditModal(cont)"
              ></app-icon-button>
            </div>
          </div>
          <div class="user__row">
            <div class="user__badges">
              @if (isNew(cont.createdAt)) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="secondary-container"
                  size_text="default"
                  badge_content="Новый"></app-badge>
              }
              @for (badge of cont.usersRoles; track badge; let i = $index) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="tertiary-container"
                  size_text="default"
                  badge_content="{{ badge.role.name }}"></app-badge>
              }
            </div>
          </div>
        </article>
      }
    }
    @case ('tabs__blocked') {
      @if (blockedUsers().length === 0) {
        <p class="text-bigger">Нет пользователей с приостановленным доступом</p>
      }
      @for (cont of blockedUsers(); track cont.id) {
        <article class="user">
          <div class="user__row">
            <h3 class="user__text text-big">
              {{ cont.firstName + ' ' + cont.middleName + ' ' + cont.lastName }}
            </h3>
            <div class="user__buttons">
              <app-icon-button
                size="small"
                type="circle"
                icon="cancel"
                display="outline"
                color="outline-low"
                (click)="
                  handleClickDeleteUser(
                    cont.id,
                    cont.firstName + ' ' + cont.lastName
                  )
                "></app-icon-button>
              <app-icon-button
                size="small"
                type="circle"
                icon="edit_square"
                display="outline"
                color="outline-low"
                (click)="openEditModal(cont)"
              ></app-icon-button>
            </div>
          </div>
          <div class="user__row">
            <div class="user__badges">
              @if (isNew(cont.createdAt)) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="secondary-container"
                  size_text="default"
                  badge_content="Новый"></app-badge>
              }
              @for (badge of cont.usersRoles; track badge; let i = $index) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="tertiary-container"
                  size_text="default"
                  badge_content="{{ badge.role.name }}"></app-badge>
              }
            </div>
          </div>
        </article>
      }
    }
  }
</div>
