import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, SelectComponent, TextInputComponent } from '@core/components';
import { SubdivisionTypesEnum } from '@core/utils';
import { EditClassifierModal } from '@scripter-admin/app/modals/classifiers-modal/edit-classifier-modal';
import { AlertService } from '@core/services';
import { SubdivisionClassifier } from '@core/models';

@Component({
  selector: 'app-edit-subdivision-classifier-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
  ],
  templateUrl: './edit-subdivision-classifier-modal.component.html',
  styleUrl: './edit-subdivision-classifier-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSubdivisionClassifierModalComponent extends EditClassifierModal implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    subdivisionType: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  ngOnInit() {
    this.formGroup.controls.name.setValue(this.classifier().name);
    const subdivisionType = this.subdivisionsTypesList.find(x => x.value === (this.classifier() as SubdivisionClassifier).type);
    this.formGroup.controls.subdivisionType.setValue(subdivisionType!.value);
  }

  subdivisionsTypesList: { value: SubdivisionTypesEnum; label: string }[] = [
    {
      value: SubdivisionTypesEnum.Affiliate,
      label: 'Филиал',
    },
    {
      value: SubdivisionTypesEnum.Department,
      label: 'Отделение',
    },
    {
      value: SubdivisionTypesEnum.URM,
      label: 'УРМ',
    },
    {
      value: SubdivisionTypesEnum.TOSP,
      label: 'ТОСП',
    },
    {
      value: SubdivisionTypesEnum.BusinessOffice,
      label: 'Бизнес офис',
    },
  ];

  formSubmit() {
    this.onFormSubmit({
      name: this.formGroup.value.name!,
      type: this.type().key,
      extra: {
        type: this.formGroup.value.subdivisionType,
      }
    });
  }
}
