import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AlertComponent, BadgeComponent, ButtonComponent, IconButtonComponent } from '@core/components';
import { AlertService, UsersService } from '@core/services';
import { ActivatedRoute } from '@angular/router';
import { Role, User } from '@core/models';
import { isNew } from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { debounceTime } from 'rxjs';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import { Overlay } from '@angular/cdk/overlay';
import { AddUserModalComponent } from '@scripter-admin/app/modals/users-modal/add-user-modal/add-user-modal.component';
import {
  EditUserModalComponent,
} from '@scripter-admin/app/modals/users-modal/edit-user-modal/edit-user-modal.component';
import { UserModal } from '../modals/users-modal/user-modal';

type Tab = {
  title: string;
  class: string;
};

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-users-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    BadgeComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements OnInit, AfterViewInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  public searchForm: FormGroup<SearchForm>;
  public users = signal<User[]>([]);
  public blockedUsers = signal<User[]>([]);
  public roles: Role[] = [];
  public isSearchBlocked: boolean = false;
  public tabs: Tab[] = [
    {
      title: 'С Доступом',
      class: 'tabs__available',
    },
    {
      title: 'Доступ остановлен',
      class: 'tabs__blocked',
    },
  ];
  public currentTab: string = this.tabs[0].class;
  public isNew = isNew;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly usersService: UsersService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ users, roles }) => {
      this.users.set(users.filter((user: User) => !user.isBlocked));
      this.blockedUsers.set(users.filter((user: User) => user.isBlocked));
      this.roles = roles;
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.usersService
        .getUsers({
          name: query,
        })
        .subscribe({
          next: (users: User[]) => {
            this.users.set(users);
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__available':
        document.querySelector('.tabs__available')?.classList.add('tab_active');
        this.currentTab = 'tabs__available';
        break;
      case 'tabs__blocked':
        document.querySelector('.tabs__blocked')?.classList.add('tab_active');
        this.currentTab = 'tabs__blocked';
        break;
    }
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddUserModalComponent,
      {
        roles: this.roles,
      }
    )

    modal.componentRef.instance.submit.subscribe((user: User) => {
      this.users.set([user].concat(this.users()));
    })
  }

  openEditModal(user: User) {
    const modal = openModal(
      this.overlay,
      EditUserModalComponent,
      {
        roles: this.roles,
        user: user,
        userRoles: user.usersRoles,
      }
    );

    modal.componentRef.instance.submit.subscribe((result: User) => {
      const index = this.users().findIndex(user => user.id === user.id);

      if (index >= 0 && result.isBlocked) {
        this.users.set(this.users().filter(user => user.id !== result.id));
        this.blockedUsers.set([result].concat(this.blockedUsers()));
      } else if (index < 0 && result.isBlocked) {
        const blockedIndex = this.blockedUsers().findIndex(
          user => user.id === result.id
        );
        this.blockedUsers.update((users: User[]) => {
          users[blockedIndex] = result;
          this.cdr.markForCheck();
          return users;
        })
      } else if (index >= 0 && !result.isBlocked) {
        this.users.update((users: User[]) => {
          users[index] = result;
          this.cdr.markForCheck();
          return users;
        })
        this.blockedUsers.set(this.blockedUsers().filter(
          user => user.id !== result.id
        ));
      } else if (index < 0 && !result.isBlocked) {
        this.blockedUsers.set(this.blockedUsers().filter(
          user => user.id !== result.id
        ));
        this.users.set([result].concat(this.users()));
      }
    });
  }

  handleClickDeleteUser(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить пользователя ${name}?`
    );

    if (isAccepted) {
      this.usersService.deleteUser(id).subscribe({
        next: () => {
          this.users.set(this.users().filter(user => user.id !== id));
          this.blockedUsers.set(this.blockedUsers().filter(user => user.id !== id));
          this.alertService.toggle('Пользователь удален');
        },
        error: (error: any) => console.error(error),
      });
    } else return;
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }
}
