import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, ButtonComponent, CardComponent } from '@core/components';
import { Tab } from '../services-page/service-page.component';
import { AlertService, TemplatesService } from '@core/services';
import { Template, TemplatesList } from '@core/models';
import { parsePeriodHelper } from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { debounceTime } from 'rxjs';

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-templates',
  standalone: true,
  imports: [
    ButtonComponent,
    CardComponent,
    NgClass,
    TextInputComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './templates.component.html',
  styleUrl: './templates.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesComponent implements OnInit, AfterViewInit {
  public templates = signal<Template[]>([]);
  public archivedTemplates = signal<Template[]>([]);
  public searchForm: FormGroup<SearchForm>;
  public isSearchBlocked: boolean = false;

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
  ];
  public currentTab: string = this.tabs[0].class;

  constructor(
    protected router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplatesService,
    private readonly alertService: AlertService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ templates }) => {
      this.templates.set(templates.active);
      this.archivedTemplates.set(templates.archive);
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.templateService
        .getTemplates({
          name: query,
        })
        .subscribe({
          next: (templatesList: TemplatesList) => {
            this.templates.set(templatesList.active);
            this.archivedTemplates.set(templatesList.archive);
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  handleShowClick(id: number): void {
    this.router.navigate([`/templates/${id}/schema`]);
  }

  handleEditClick(id: number): void {
    this.router.navigate([`/templates/${id}/schema`]);
  }

  handleDeleteClick(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить шаблон "${name}"?`
    );

    if (isAccepted) {
      this.templateService.deleteTemplate(id).subscribe({
        next: () => {
          this.templates.set(
            this.templates().filter(template => template.id !== id)
          );
          this.alertService.toggle('Шаблон удален');
        },
        error: (err: Error) => console.error(err),
      });
    } else return;
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  parseActivity = parsePeriodHelper;
}
