import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AlertComponent, BadgeComponent, ButtonComponent, IconButtonComponent } from '@core/components';
import { Permission, Role } from '@core/models';
import { AlertService, RolesService } from '@core/services';
import { isNew, RolesTypesEnum } from '@core/utils';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import { AddRoleModalComponent } from '@scripter-admin/app/modals/roles-modal/add-role-modal/add-role-modal.component';
import {
  EditRoleModalComponent
} from '@scripter-admin/app/modals/roles-modal/edit-role-modal/edit-role-modal.component';

@Component({
  selector: 'app-roles-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    BadgeComponent,
  ],
  providers: [AlertService, AlertComponent],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesComponent implements OnInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  public permissions: Permission[] = [];
  public selectedPermissions: Permission[] = [];
  public roles = signal<Role[]>([]);
  public isNew = isNew;

  constructor(
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly rolesService: RolesService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ roles, permissions }) => {
      this.roles.set(roles);
      this.permissions = permissions;
    });
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddRoleModalComponent,
      {
        permissions: this.permissions,
        selectedPermissions: this.selectedPermissions,
      }
    );

    modal.componentRef.instance.submit.subscribe((role: Role) => {
      this.roles.update((roles: Role[]) => {
        roles.unshift(role);
        this.cdr.markForCheck();
        return roles;
      });
    });
  }

  openEditModal(role: Role) {
    const modal = openModal(
      this.overlay,
      EditRoleModalComponent,
      {
        role: role,
        permissions: this.permissions,
        selectedPermissions: role.rolesPermissions?.map(rP => ({
          ...rP.permission,
          access: rP.access,
        })) ?? [],
      }
    );

    modal.componentRef.instance.submit.subscribe((role: Role) => {
      const index = this.roles().findIndex(x => x.id === role.id);

      if (index !== -1) {
        this.roles.update((roles: Role[]) => {
          roles[index] = role;
          this.cdr.markForCheck();
          return roles;
        });
      } else {
        throw new Error();
      }
    });
  }

  deleteRole(id: number, name: string) {
    const isAccepted = confirm(
      `Вы действительно хотите удалить роль "${name}"?`
    );

    if (isAccepted) {
      this.rolesService.deleteRole(id).subscribe({
        next: (role: Role) => {
          this.roles.set(this.roles().filter(r => r.id !== role.id));
          this.alertService.toggle('Роль удалена');
        },
        error: (error: Error) => {
          console.log(error);
        },
      });
    } else return;
  }

  protected readonly RolesTypesEnum = RolesTypesEnum;
}
