import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from '@core/components';
import { Service } from '@core/models';
import { ButtonType } from '@core/types';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@core/services';
import { getDocumentActualVersionHelper } from '@core/utils';
import { SectionHeaderComponent } from '@core/components/section-header/section-header.component';
import { ServiceAboutComponent } from '@scripter-admin/app/services-page/service/tabs/service-about/service-about.component';
import { ServiceReferenceComponent } from '@scripter-admin/app/services-page/service/tabs/service-reference/service-reference.component';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  EditServiceModalComponent
} from '@scripter-admin/app/modals/services-modal/edit-service-modal/edit-service-modal.component';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    SectionHeaderComponent,
    ServiceAboutComponent,
    ServiceReferenceComponent,
    CommonModule,
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss',
  providers: [AlertService, AlertComponent],
})
export class ServiceComponent implements OnInit {
  private readonly overlay = inject(Overlay);
  service!: Service;
  buttons: ButtonType[] = [
    {
      color: 'container-lowest',
      icon: 'arrow_left_alt',
      display: 'overlay',
      click: this.navigate.bind(this),
    },
    {
      color: 'inverse-container',
      icon: 'explore',
      display: 'inverse',
      content: 'Схема',
      routerLink: 'schema',
    },
    {
      color: 'inverse-container',
      icon: 'edit_document',
      display: 'inverse',
      content: 'Редактировать услугу',
      click: this.openEditModal.bind(this),
    },
    {
      color: 'outline-low',
      display: 'outline',
      content: 'Закрыть',
      click: this.navigate.bind(this),
    },
  ];
  isAbout: boolean = true;

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ service }) => {
      this.service = service;
    });
  }

  get isNeedAttention(): boolean {
    return !!this.service.documents?.filter(
      d =>
        getDocumentActualVersionHelper(d)?.activityEnd &&
        new Date(getDocumentActualVersionHelper(d)?.activityEnd as string) <
        new Date()
    ).length;
  }

  navigate() {
    this.router.navigate(['/services']);
  }

  openEditModal() {
    const modal = openModal(
      this.overlay,
      EditServiceModalComponent,
      {
        service: this.service,
      }
    );

    modal.componentRef.instance.submit.subscribe((service: Service) => {
      this.service = service;
    });
  }
}
