import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AddClassifierModal } from '@scripter-admin/app/modals/classifiers-modal/add-classifier-modal';
import { SubdivisionTypesEnum } from '@core/utils';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, SelectComponent, TextInputComponent } from '@core/components';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-add-subdivision-classifier-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
  ],
  templateUrl: './add-subdivision-classifier-modal.component.html',
  styleUrl: './add-subdivision-classifier-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSubdivisionClassifierModalComponent extends AddClassifierModal {
  formGroup = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    subdivisionType: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  subdivisionsTypesList: { value: SubdivisionTypesEnum; label: string }[] = [
    {
      value: SubdivisionTypesEnum.Affiliate,
      label: 'Филиал',
    },
    {
      value: SubdivisionTypesEnum.Department,
      label: 'Отделение',
    },
    {
      value: SubdivisionTypesEnum.URM,
      label: 'УРМ',
    },
    {
      value: SubdivisionTypesEnum.TOSP,
      label: 'ТОСП',
    },
    {
      value: SubdivisionTypesEnum.BusinessOffice,
      label: 'Бизнес офис',
    },
  ];

  formSubmit() {
    this.onFormSubmit({
      name: this.formGroup.value.name!,
      type: this.type().key,
      extra: {
        type: this.formGroup.value.subdivisionType,
      }
    });
  }
}
