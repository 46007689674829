@if (type() === 'normal') {
  <div class="select container text-big" (click)="isOpen.set(!isOpen())"
       [ngClass]="isOpen()? 'select_active on-container':'on-container-low'"
       [ngStyle]="{'background-color': bg() === 'white'? '#fff':'var(--neutral-color-tone-96)'}">
    @if (placeholder() && !value()) {
      <span class="select__value container on-container-low text-big"
            [ngStyle]="{'background-color': bg() === 'white'? '#fff':'var(--neutral-color-tone-96)'}">{{ placeholder() }}</span>
    } @else {
      <span class="select__value">
        {{ activeLabel }}
      </span>
    }
    <img src="../../../assets/icons/check-icon.svg" class="select__check-icon"
         [ngClass]="isOpen() ? 'select__check-icon_up' : 'select__check-icon_down'">
  </div>
} @else {
  <div class="select container text-big" (click)="isOpen.set(!isOpen())"
       [ngClass]="isOpen() ? 'select_active on-container' :' on-container-low'"
       [ngStyle]="{'background-color': bg() === 'white' ? '#fff' : 'var(--neutral-color-tone-96)'}">
    @if (placeholder() && (type() === 'picked-list' || !value())) {
      <span class="select__value container on-container-low text-big"
            [ngStyle]="{'background-color': bg() === 'white'? '#fff':'var(--neutral-color-tone-96)'}">{{ placeholder() }}</span>
    } @else {
      <span class="select__value">
        {{ activeLabel }}
      </span>
    }
    <img src="../../../assets/icons/check-icon.svg" class="select__check-icon"
         [ngClass]="isOpen() && options().length ? 'select__check-icon_up' : 'select__check-icon_down'">
  </div>
  @if (isOpen()) {
    <div class="classic-options">
      @for (option of options(); track option) {
        <div class="option" (click)="handleSelect(option)">
          <div class="option__text-container">
            <p class="option__p">{{ option.label }}</p>
          </div>
        </div>
      }
    </div>
  }
}
<!-- TODO решить обрабатывать ли ошибки, пока что временное решение -->
@if (error()) {
  <p class="text-small on-container-lowest primary-color">{{ error() }}</p>
}
