import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  Service,
  ServiceApplicantCategory,
  ServiceBlock,
  ServiceDepartment,
  ServiceDocuments,
  ServiceLifeSitutation,
  ServicesList,
  ServiceTag,
} from '@core/models';

import {
  CreateServiceBlockDto,
  CreateServiceDto,
  ServicesSearchQueryDto,
  UpdateServiceDto,
} from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class ServicesService {
  constructor(private readonly http: HttpClient) {}

  getServices(searchDto?: ServicesSearchQueryDto): Observable<ServicesList> {
    // На этом примере можете глянуть как разбивать DTO на query-параметры
    // 1. Создаем сущность URLSearchParams, она работает как обычный объект Map<string, string | unknown>
    const params = new URLSearchParams();

    // 2. Проверяем DTO на наличие полей и, в зависимости от ситуации, стрингуем
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }
    if (searchDto?.activityStart) {
      params.set(
        'activityStart',
        new Date(searchDto.activityStart).toISOString()
      );
    }
    if (searchDto?.activityEnd) {
      params.set('activityEnd', new Date(searchDto.activityEnd).toISOString());
    }
    if (searchDto?.applicantCategoriesIds) {
      params.set(
        'applicantCategories',
        searchDto.applicantCategoriesIds.toString()
      );
    }
    if (searchDto?.departmentsIds) {
      params.set('departments', searchDto.departmentsIds.toString());
    }
    if (searchDto?.lifeSituationsIds) {
      params.set('lifeSituations', searchDto.lifeSituationsIds.toString());
    }
    if (searchDto?.territorialityIds) {
      params.set('territoriality', searchDto.territorialityIds.toString());
    }
    if (searchDto?.divisionsIds) {
      params.set('divisions', searchDto.divisionsIds.toString());
    }
    if (searchDto?.orderBy) {
      params.set(
        'orderBy',
        `${searchDto.orderBy.field}+${searchDto.orderBy.by}`
      );
    }
    if (searchDto?.from) {
      params.set('from', searchDto.from.toString());
    }
    if (searchDto?.to) {
      params.set('to', searchDto.to.toString());
    }
    if (typeof searchDto?.isNotEmpty === 'boolean') {
      params.set('isNotEmpty', searchDto.isNotEmpty + '');
    }
    if (typeof searchDto?.isNotForTosp === 'boolean') {
      params.set('isNotForTosp', searchDto.isNotForTosp + '');
    }
    if (typeof searchDto?.isNotForUrm === 'boolean') {
      params.set('isNotForUrm', searchDto.isNotForUrm + '');
    }
    if (searchDto?.type && searchDto.type.length) {
      params.set('type', searchDto.type.toString());
    }
    if (typeof searchDto?.isEmployeeDepartments === 'boolean') {
      params.set('isEmployeeDepartments', searchDto.isEmployeeDepartments + '');
    }

    let uri = '/services';
    if (params.size) uri += `?${params.toString()}`;
    // 3. Отправляем на сервер
    return this.http.get<ServicesList>(uri);
  }

  getServiceById(id: number): Observable<Service> {
    return this.http.get<Service>(`/services/${id}`);
  }

  createService(createServiceDto: CreateServiceDto): Observable<Service> {
    return this.http.post<Service>(`/services`, createServiceDto);
  }

  updateService(
    id: number,
    updateServiceDto: UpdateServiceDto
  ): Observable<Service> {
    return this.http.put<Service>(`/services/${id}`, updateServiceDto);
  }

  cloneService(id: number, updateDto: UpdateServiceDto): Observable<Service> {
    return this.http.post<Service>(`/services/${id}/clone`, updateDto);
  }

  createServiceBlocks(
    id: number,
    createBlocksDto: CreateServiceBlockDto[]
  ): Observable<{ count: number }> {
    return this.http.post<{ count: number }>(
      `/services-blocks/` + id,
      createBlocksDto
    );
  }

  getServiceBlocks(id: number): Observable<ServiceBlock[]> {
    return this.http.get<ServiceBlock[]>('/services-blocks/' + id);
  }

  deleteService(id: number): Observable<Service> {
    return this.http.delete<Service>(`/services/${id}`);
  }

  bindDocument(id: number, documentId: number): Observable<ServiceDocuments> {
    return this.http.post<ServiceDocuments>(
      `/services/${id}/documents/${documentId}`,
      {}
    );
  }

  unbindDocument(id: number, documentId: number): Observable<ServiceDocuments> {
    return this.http.delete<ServiceDocuments>(
      `/services/${id}/documents/${documentId}`
    );
  }

  bindLifeSituation(
    id: number,
    lifeSituationId: number
  ): Observable<ServiceLifeSitutation> {
    return this.http.post<ServiceLifeSitutation>(
      `/services/${id}/life_situations/${lifeSituationId}`,
      {}
    );
  }

  unbindLifeSituation(
    id: number,
    lifeSituationId: number
  ): Observable<ServiceLifeSitutation> {
    return this.http.delete<ServiceLifeSitutation>(
      `/services/${id}/life_situations/${lifeSituationId}`
    );
  }

  bindApplicantCategories(
    id: number,
    applicantCategoryId: number
  ): Observable<ServiceApplicantCategory> {
    return this.http.post<ServiceApplicantCategory>(
      `/services/${id}/applicant_categories/${applicantCategoryId}`,
      {}
    );
  }

  unbindApplicantCategories(
    id: number,
    applicantCategoryId: number
  ): Observable<ServiceApplicantCategory> {
    return this.http.delete<ServiceApplicantCategory>(
      `/services/${id}/applicant_categories/${applicantCategoryId}`
    );
  }

  bindDepartment(
    id: number,
    departmentId: number
  ): Observable<ServiceDepartment> {
    return this.http.post<ServiceDepartment>(
      `/services/${id}/departments/${departmentId}`,
      {}
    );
  }

  unbindDepartment(
    id: number,
    departmentId: number
  ): Observable<ServiceDepartment> {
    return this.http.delete<ServiceDepartment>(
      `/services/${id}/departments/${departmentId}`
    );
  }

  bindTag(id: number, tagId: number): Observable<ServiceTag> {
    return this.http.post<ServiceTag>(`/services/${id}/tags/${tagId}`, {});
  }

  unbindTag(id: number, tagId: number): Observable<ServiceTag> {
    return this.http.delete<ServiceTag>(`/services/${id}/tags/${tagId}`);
  }
}
