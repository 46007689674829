import { Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Modal } from './modal';

export function openModal<T extends Modal>(overlay: Overlay, componentType: Type<T>, inputs: {[key: string]: unknown}) {
  const overlayConfig = new OverlayConfig({
    hasBackdrop: true,
    backdropClass: 'modal-backdrop',
    positionStrategy: overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });
  const overlayRef = overlay.create(overlayConfig);
  const componentPortal = new ComponentPortal(componentType);
  const componentRef = overlayRef.attach(componentPortal);

  Object.entries(inputs).forEach(([key, value]) => {
    componentRef.setInput(key, value);
  })

  overlayRef.backdropClick().subscribe(() => {
    componentRef.instance.closeModal();
  });
  componentRef.instance.close.subscribe(() => {
    overlayRef.detach();
  });

  return  {
    overlayRef: overlayRef,
    componentRef: componentRef,
  }
}
