import { Component, EventEmitter, inject, input, OnInit, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Role, User } from '@core/models';
import { AlertService, UsersService } from '@core/services';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class UserModal extends Modal {
  public roles = input.required<Role[]>();
  public selectedRoles = signal<Role[]>([]);
  abstract formGroup: FormGroup;
  protected readonly usersService = inject(UsersService);
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<User>();
  public firstNameError = signal<string>('');
  public lastNameError = signal<string>('');
  public middleNameError = signal<string>('');

  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (values.firstName?.trim().length < 2) {
      errors['firstName'] = 'Имя не может быть пустым и быть короче двух символов';
    }
    if (values.lastName?.trim().length < 2) {
      errors['lastName'] = 'Фамилия не может быть пустой и быть короче двух символов';
    }
    if (values.middleName?.trim().length < 2) {
      errors['middleName'] = 'Отчество не может быть пустым и быть короче двух символов';
    }

    this.firstNameError.set(errors['firstName']);
    this.lastNameError.set(errors['lastName']);
    this.middleNameError.set(errors['middleName']);

    return !Object.keys(errors).length;
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }

  public resetInputError(): void {
    this.firstNameError.set('');
    this.lastNameError.set('');
    this.middleNameError.set('');
  }

  get rolesList(): { value: number; label: string }[] {
    const list: { value: number; label: string }[] = [];
    const selectedIdsList: number[] = this.selectedRoles().map(
      selected => selected.id
    );
    for (const role of this.roles()) {
      if (!selectedIdsList.includes(role.id)) {
        list.push({
          value: role.id,
          label: role.name,
        });
      }
    }

    return list;
  }

  handleClickDeleteSelectedRole(id: number): void {
    this.selectedRoles.set(this.selectedRoles().filter(role => role.id !== id));
  }
}
