<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Фильтры услуг
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form area_white_bg"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
      <div class="modal__general-period" style="margin-top: 0">
        <p class="modal__input-title title-grid1">Дата начала активности</p>
        <core-date-input
          class="input-grid1"
          formControlName="activityStart"
        ></core-date-input>
        <p class="modal__input-title title-grid2">Дата окончания активности</p>
        <core-date-input
          class="input-grid2"
          formControlName="activityEnd"
        ></core-date-input>
      </div>
      <div class="modal__search_general">
        <core-checkbox
          title="Не предоставляется для ТОСП"
          [formControl]="formGroup.controls.isNotForTosp"
        ></core-checkbox>
      </div>
      <div class="modal__search_general">
        <core-checkbox
          title="Не предоставляется для УРМ"
          [formControl]="formGroup.controls.isNotForUrm"
        ></core-checkbox>
      </div>
      <div class="modal__search_title">Способы предоставления</div>
      @for (type of Object.values(ServicesTypesEnum); track type) {
        <div class="modal__search_general">
          <core-checkbox
            [title]="getTypeName(type)"
            [ngModel]="typeIsExists(type)"
            (ngModelChange)="toggleType(type)"
            [ngModelOptions]="{ standalone: true }"
          ></core-checkbox>
        </div>
      }

      @for (classifierType of classifiersTypes; track classifierType.key) {
        <div class="modal__classifier-element">
          <div class="modal__classifier-element-header">
            <div class="modal__classifier-element-header-title">
              <span>{{ classifierType.name }}</span>

              <app-badge
                class="modal__classifier-element-header-title-badge"
                size="small"
                display="tint"
                color="secondary-container"
                size_text="default"
                badge_content="{{ getClassifiersTotalByType(classifierType.key) }}" />
            </div>
            <div class="modal__classifier-element-header-open-icon">
              @if (classifierType.opened) {
                <app-icon-button
                  size="normal"
                  type="square"
                  icon="keyboard_arrow_up"
                  display="fill"
                  color="container-lowest"
                  (click)="toggleOpened(classifierType.key)">
                </app-icon-button>
              } @else {
                <app-icon-button
                  size="normal"
                  type="square"
                  icon="keyboard_arrow_down"
                  display="fill"
                  color="container-lowest"
                  (click)="toggleOpened(classifierType.key)">
                </app-icon-button>
              }
            </div>
          </div>
          <div [style.display]="classifierType.opened ? 'block' : 'none'">
            @if (getClassifiersListByType(classifierType.key).length) {
              <div class="modal__classifier-element-list">
                @for (
                    classifier of getClassifiersListByType(classifierType.key);
                  track classifier
                  ) {
                  <div class="modal__classifier-element-list-row">
                    <core-checkbox
                      [title]="classifier.name"
                      [ngModel]="classifierHasId(classifierType.key, classifier.id)"
                      (ngModelChange)="toggleClassifier(classifierType.key, classifier.id)"
                      [ngModelOptions]="{ standalone: true }"
                    ></core-checkbox>
                  </div>
                }
                @if (
                  getClassifiersTotalByType(classifierType.key) >
                  getClassifiersListByType(classifierType.key).length
                  ) {
                  <app-button
                    size="normal"
                    type="left"
                    icon="add_circle"
                    [fill]="true"
                    display="fill"
                    color="neutral-40"
                    sizeText="default"
                    buttonContent="Показать больше"
                    (click)="showMoreClick(classifierType)">
                  </app-button>
                }
              </div>
            } @else {
              <p>Классификаторы не найдены</p>
            }
          </div>
        </div>
      }
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Сохранить
      </button>
    </div>
  </form>
</div>
