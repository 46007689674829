import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { inject } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { ServicePageComponent } from './services-page/service-page.component';
import { ServiceComponent } from './services-page/service/service.component';
import { RegDocsPageComponent } from './reg-docs-page/reg-docs-page.component';
import { DocsSamplePageComponent } from '@scripter-admin/app/docs-sample-page/docs-sample-page.component';
import { UsersComponent } from './users/users.component';
import { RegDocComponent } from './reg-docs-page/reg-doc/reg-doc.component';
import { RolesComponent } from './roles/roles.component';
import { RecoverComponent } from './login/recover/recover.component';
import { RestorePasswordComponent } from './login/restore-password/restore-password.component';
import { TemplatesComponent } from './templates/templates.component';
import { DocsTransferMethodPageComponent } from '@scripter-admin/app/docs-transfer-method-page/docs-transfer-method-page.component';

import { authGuard, loginGuard, permissionGuard } from '@core/guards';
import { NotFoundComponent } from '@core/components';
import { ChartComponent } from '@core/components/chart/chart.component';
import { ClassifiersComponent } from '@scripter-admin/app/classifiers/classifiers.component';
import {
  ClassifiersService,
  RolesService,
  PermissionsService,
  UsersService,
  DocumentsTransferMethodsService,
  DocsSampleService,
  RegDocsService,
  TemplatesService,
  ServicesService,
  ResultsTransferMethodsService,
} from '@core/services';
import { PermissionsEnum } from '@core/utils';
import { OverlayAdminComponent } from '@core/components/overlay/overlay-new/overlay-admin/overlay-admin.component';
import { ResultsTransferMethodPageComponent } from './results-transfer-method-page/results-transfer-method-page.component';

export const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [loginGuard()],
      },
      {
        path: 'recover',
        component: RecoverComponent,
        canActivate: [loginGuard()],
      },
      {
        path: 'restore-password',
        component: RestorePasswordComponent,
        canActivate: [loginGuard()],
      },
    ],
  },
  {
    path: '',
    component: OverlayAdminComponent, //overlayComponent
    children: [
      {
        path: '',
        redirectTo: 'services',
        pathMatch: 'full',
      },
      {
        path: 'docs-transfer-methods',
        canActivate: [authGuard, permissionGuard(PermissionsEnum.SERVICES)],
        children: [
          {
            path: '',
            component: DocsTransferMethodPageComponent,
            canActivate: [authGuard],
            resolve: {
              documentsTransferMethods: () =>
                inject(
                  DocumentsTransferMethodsService
                ).getDocumentTransferMethods(),
            },
          },
        ],
      },
      {
        path: 'results-transfer-methods',
        canActivate: [authGuard, permissionGuard(PermissionsEnum.SERVICES)],
        children: [
          {
            path: '',
            component: ResultsTransferMethodPageComponent,
            canActivate: [authGuard],
            resolve: {
              resultsTransferMethods: () =>
                inject(
                  ResultsTransferMethodsService
                ).getResultsTransferMethods(),
            },
          },
        ],
      },
      {
        path: 'services',
        canActivate: [authGuard, permissionGuard(PermissionsEnum.SERVICES)],
        children: [
          {
            path: '',
            component: ServicePageComponent,
            canActivate: [authGuard],
            resolve: {
              services: () =>
                inject(ServicesService).getServices({ from: 0, to: 10 }),
            },
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: ServiceComponent,
                canActivate: [authGuard],
                resolve: {
                  service: (route: ActivatedRouteSnapshot) =>
                    inject(ServicesService).getServiceById(route.params['id']),
                },
              },
              {
                path: 'schema',
                component: ChartComponent,
                canActivate: [authGuard],
                resolve: {
                  service: (route: ActivatedRouteSnapshot) =>
                    inject(ServicesService).getServiceById(route.params['id']),
                  serviceBlocks: (route: ActivatedRouteSnapshot) =>
                    inject(ServicesService).getServiceBlocks(route.params['id']),
                },
              },
            ],
          },
        ],
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [authGuard, permissionGuard(PermissionsEnum.TEMPLATES)],
        resolve: {
          templates: () => inject(TemplatesService).getTemplates(),
        },
      },
      {
        path: 'templates/create',
        component: ChartComponent,
        canActivate: [authGuard],
        resolve: {
          template: () => null,
          documentsTemplates: () => inject(DocsSampleService).getDocSamples(),
          documentsTransferMethods: () =>
            inject(DocumentsTransferMethodsService).getDocumentTransferMethods(),
        },
      },
      {
        path: 'templates/:id/schema',
        component: ChartComponent,
        canActivate: [authGuard],
        resolve: {
          template: (route: ActivatedRouteSnapshot) =>
            inject(TemplatesService).getTemplateById(route.params['id']),
          templatesBlocks: (route: ActivatedRouteSnapshot) =>
            inject(TemplatesService).getTemplateBlocks(route.params['id']),
          documentsTemplates: () => inject(DocsSampleService).getDocSamples(),
        },
      },
      {
        path: 'reg-docs',
        canActivate: [authGuard, permissionGuard(PermissionsEnum.DOCUMENTS)],
        children: [
          {
            path: '',
            component: RegDocsPageComponent,
            canActivate: [authGuard],
            resolve: {
              regDocs: () => inject(RegDocsService).getRegDocs(),
            },
          },
          {
            path: ':id',
            component: RegDocComponent,
            canActivate: [authGuard],
            resolve: {
              document: (route: ActivatedRouteSnapshot) =>
                inject(RegDocsService).getRegDocById(route.params['id']),
            },
          },
        ],
      },
      {
        path: 'docs-sample',
        component: DocsSamplePageComponent,
        canActivate: [
          authGuard,
          permissionGuard(PermissionsEnum.DOCUMENTS_TEMPLATES),
        ],
        resolve: {
          docsSamples: () => inject(DocsSampleService).getDocSamples(),
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [authGuard, permissionGuard(PermissionsEnum.USERS)],
        resolve: {
          users: () => inject(UsersService).getUsers({}),
          roles: () => inject(RolesService).getRoles(),
        },
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [authGuard, permissionGuard(PermissionsEnum.ROLES)],
        resolve: {
          roles: () => inject(RolesService).getRoles(),
          permissions: () => inject(PermissionsService).getPermissions(),
        },
      },
      {
        path: 'classificators',
        component: ClassifiersComponent,
        canActivate: [authGuard, permissionGuard(PermissionsEnum.CLASSIFIERS)],
        resolve: {
          classifiers: () => inject(ClassifiersService).getClassifiers(),
        },
      },
      {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent,
      },

    ]
  }
];
