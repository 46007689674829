import { Component, input } from '@angular/core';
import { Classifier, ClassifierType } from '@core/models';
import { CreateClassifierDto } from '@core/models/dto';
import { ClassifierModal } from '@scripter-admin/app/modals/classifiers-modal/classifier-modal';

@Component({
  template: ``,
})
export abstract class AddClassifierModal extends ClassifierModal {
  public title = input.required<string>();
  public type = input.required<ClassifierType>();

  protected onFormSubmit(requestData: CreateClassifierDto): void {
    if (!this.checkErrors()) return;

    this.classifiersService.createClassifier(requestData).subscribe({
      next: (classifier: Classifier) => {
        this.alertService.toggle('Классификатор добавлен');
        this.submit.emit(classifier);
        this.close.emit();
      },
      error: (err: Error & { statusCode: number }) => {
        if (err.statusCode === 409) {
          this.nameError.set('Классификатор с таким названием уже существует');
        } else {
          this.nameError.set('Произошла неизвестная ошибка, пожалуйста, попробуйте позже');
        }
      },
    });
  }
}
