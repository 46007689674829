<div
  class="wrapper"
  [ngClass]="{
    'input-complete': value().length,
    'input-error': error(),
    'input-disabled': disabled,
    'input-focused': focused()
  }"
  [style.height.px]="height()">
  <input
    [type]="visible ? 'text' : 'password'"
    class="input on-container text-big"
    [disabled]="disabled"
    [placeholder]="placeholder()"
    (focus)="focused.set(true)"
    (blur)="focused.set(false)"
    [ngModel]="value()"
    (ngModelChange)="onInput($event)" />
  <div class="actions">
    @if (error()) {
      <span
        class="material-symbols-outlined material-symbols-filled primary-color">
        warning
      </span>
    }
    @if (value()) {
      <app-icon-button
        size="tiny"
        type="square"
        [icon]="!visible ? 'visibility' : 'visibility_off'"
        display="overlay"
        color="container-lowest"
        class="material-symbols-outlined"
        [tabindex]="-1"
        (click)="visible = !visible">
      </app-icon-button>
    }
    @if (value()) {
      <app-icon-button
        size="tiny"
        type="square"
        icon="cancel"
        display="overlay"
        color="container-lowest"
        class="material-symbols-outlined"
        [tabindex]="-1"
        (click)="clear()"></app-icon-button>
    }
  </div>
  <hr
    [ngClass]="{
      'hr-active': focused()
    }" />
</div>
@if (tooltip()) {
  <p class="tooltip text-small on-container-lowest">{{ tooltip() }}</p>
}
@if (error()) {
  <p class="tooltip text-small on-container-lowest primary-color">
    {{ error() }}
  </p>
}
