import { ChangeDetectionStrategy, Component, inject, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ServiceModal } from '@scripter-admin/app/modals/services-modal/service-modal';
import { ClassifierTypes, SelectedClassifiers, ServicesTypesEnum } from '@core/utils';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  DateInputComponent,
  InputComponent, TextareaInputComponent,
  TextInputComponent,
} from '@core/components';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { SelectedDocumentsComponent } from '@core/components/selected-documents/selected-documents.component';
import { Classifier, Document, DocumentsList, DocumentTransferMethod, Service, ServicesList } from '@core/models';
import { AlertService, ClassifiersService, DocumentsTransferMethodsService, RegDocsService, ResultsTransferMethodsService } from '@core/services';

interface UpdateServiceForm {
  name: FormControl<string>;
  title: FormControl<string>;
  sierNumber: FormControl<string>;
  activityStart: FormControl<Date | string | null>;
  activityEnd: FormControl<Date | string | null>;
  type: FormControl<ServicesTypesEnum>;
  isNotForTosp: FormControl<boolean>;
  isNotForUrm: FormControl<boolean>;
}

@Component({
  selector: 'app-clone-service-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AutocompleteComponent,
    BadgeComponent,
    ButtonComponent,
    DateInputComponent,
    InputComponent,
    NgForOf,
    NgIf,
    SelectedDocumentsComponent,
    TextInputComponent,
    TextareaInputComponent,
    NgClass,
  ],
  providers: [AlertService, AlertComponent],
  templateUrl: './clone-service-modal.component.html',
  styleUrl: './clone-service-modal.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  // TODO: Разобраться, почему из-за него не пропадает dropdown список
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloneServiceModalComponent extends ServiceModal implements OnInit {
  private readonly regDocsService = inject(RegDocsService);
  private readonly classifiersService = inject(ClassifiersService);
  private readonly documentsTransferMethodsService = inject(DocumentsTransferMethodsService);
  private readonly resultsTransferMethodsService = inject(ResultsTransferMethodsService);
  public serviceToClone = signal<Service | null>(null);
  formGroup = new FormGroup<UpdateServiceForm>({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    sierNumber: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    title: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    type: new FormControl(ServicesTypesEnum.Federal, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    activityStart: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    activityEnd: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    isNotForTosp: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isNotForUrm: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });
  public cloneSearchForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });
  public servicesToClone: Service[] = [];
  public suggestionDocuments: Document[] = [];
  public selectedDocuments: Document[] = [];

  public suggestionClassifiers = {
    lifeSituations: new Array<Classifier>(),
    applicantCategories: new Array<Classifier>(),
    departments: new Array<Classifier>(),
    divisions: new Array<Classifier>(),
    territoriality: new Array<Classifier>()
  };
  public selectedClassifiers: SelectedClassifiers = new SelectedClassifiers();
  public selectedServiceType: ServicesTypesEnum = ServicesTypesEnum.Federal;

  public selectedDocumentsTransferMethods = signal<DocumentTransferMethod[]>([]);
  public suggestedDocumentsTransferMethods = signal<DocumentTransferMethod[]>([]);

  public suggestedResultsTransferMethods = signal<DocumentTransferMethod[]>([]);
  public selectedResultsTransferMethod = signal<DocumentTransferMethod | null>(null);

  ngOnInit() {
    this.cloneSearchForm.get('name')!.valueChanges.subscribe((value: any) => {
      this.handleSearchServicesToClone(value);
    });
    this.handleSearchServicesToClone('');
  }

  private handleSearchServicesToClone(name: string): void {
    this.servicesService
      .getServices({
        name,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (services: ServicesList) => {
          this.servicesToClone = services.active;
        },
        error: (err: Error) => console.error(err),
      });
  }

  handleSelectCloneServiceClick(service: Service): void {
    this.serviceToClone.set(service);
    this.servicesToClone = [];

    this.formGroup.patchValue({
      name: service.name,
      title: service.title,
      sierNumber: service.sierNumber,
      activityStart: service.activityStart?.slice(0, 10) ?? '',
      activityEnd: service.activityEnd?.slice(0, 10) ?? '',
      type: service.type,
      isNotForTosp: service.isNotForTosp,
      isNotForUrm: service.isNotForUrm,
    });
    this.selectedServiceType = service.type;
    this.selectedDocuments = service.documents ?? [];
    this.selectedClassifiers.insert(
      'lifeSituations',
      service.lifeSituations.map(item => item.lifeSituation) ?? []
    );
    this.selectedClassifiers.insert(
      'applicantCategories',
      service.applicantCategories.map(item => item.applicantCategory) ?? []
    );
    this.selectedClassifiers.insert(
      'departments',
      service.departments.map(item => item.department) ?? []
    );
    this.selectedClassifiers.insert(
      'territoriality',
      service.territoriality.map(item => item.territoriality) ?? []
    );
    this.selectedClassifiers.insert(
      'divisions',
      service.divisions.map(item => item.division) ?? []
    );
    this.selectedDocumentsTransferMethods.set(service.documentsTransferMethods);
    this.selectedResultsTransferMethod.set(service.resultsTransferMethod);
    this.searchDocuments('');
    this.searchClassifiers('lifeSituations', '');
    this.searchClassifiers('applicantCategories', '');
    this.searchClassifiers('departments', '');
    this.searchClassifiers('territoriality', '');
    this.searchClassifiers('divisions', '');
    this.searchDocumentsTransferMethods('');
    this.searchResultsTransferMethods('');
  }

  public handleChangeServiceTypeClick(
    type: ServicesTypesEnum,
  ): void {
    this.formGroup.get('type')?.setValue(type);
    this.selectedServiceType = type;
  }

  getDocumentName(doc: Document) {
    return doc.title;
  }

  addDocument(doc: Document) {
    if (this.selectedDocuments.indexOf(doc) < 0) {
      this.selectedDocuments.push(doc);
      this.suggestionDocuments.splice(this.suggestionDocuments.indexOf(doc), 1);
    }
  }

  searchDocuments(name: string) {
    this.regDocsService
      .getRegDocs({
        name,
        from: 0,
        to: 50,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          const selectedIds = this.selectedDocuments.map((document: Document) => document.id);
          this.suggestionDocuments = documents.active.data
            .filter((doc) => !selectedIds.includes(doc.id));
        },
        error: (err: Error) => console.error(err),
      });
  }

  removeDocument(doc: Document) {
    if (this.selectedDocuments.indexOf(doc) >= 0) {
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(doc), 1);
      this.suggestionDocuments.push(doc);
    }
  }

  searchClassifiers(type: ClassifierTypes, name: string) {
    this.classifiersService.getClassifiers({ name, [type]: { from: 0, to: 50 } })
      .subscribe((result) => {
        const selectedIds = this.selectedClassifiers.getIds(type);
        this.suggestionClassifiers[type] = result[type].data.filter((item) => !selectedIds.includes(item.id));
      });
  }

  getClassifierName(classifier: Classifier): string {
    return classifier.name;
  }

  addClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.add(type, classifier as Classifier);
    this.suggestionClassifiers[type].splice(this.suggestionClassifiers[type].indexOf(classifier), 1);
  }

  removeClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.remove(type, classifier);
    this.suggestionClassifiers[type].push(classifier);
  }

  searchDocumentsTransferMethods(name: string){
    this.documentsTransferMethodsService.getDocumentTransferMethods({ name })
      .subscribe((results) => {
        const selectedMethodsIds = this.selectedDocumentsTransferMethods().map(method => method.id);
        this.suggestedDocumentsTransferMethods.set(results.filter(method => !selectedMethodsIds.includes(method.id)));
      });
  }

  addDocumentsTransferMethod(transferMethod: DocumentTransferMethod){
    this.selectedDocumentsTransferMethods.update(methods => [...methods, transferMethod]);
    this.suggestedDocumentsTransferMethods.update(methods => methods.filter(method => method != transferMethod));
  }

  removeDocumentsTransferMethod(transferMethod: DocumentTransferMethod){
    this.suggestedDocumentsTransferMethods.update(methods => [...methods, transferMethod]);
    this.selectedDocumentsTransferMethods.update(methods => methods.filter(method => method != transferMethod));
  }

  searchResultsTransferMethods(name: string){
    this.resultsTransferMethodsService.getResultsTransferMethods({ name })
      .subscribe((results) => {
        this.suggestedResultsTransferMethods.set(results.filter(method => method.id != this.selectedResultsTransferMethod()?.id));
      });
  }

  selectResultsTransferMethod(transferMethod: DocumentTransferMethod | null){
    this.suggestedResultsTransferMethods.update(methods => {
      const deselectedMethod = this.selectedResultsTransferMethod();
      if(transferMethod){
        methods.splice(methods.indexOf(transferMethod), 1);
      }
      if(deselectedMethod){
        methods.push(deselectedMethod);
      }
      return methods;
    });

    this.selectedResultsTransferMethod.set(transferMethod);
  }

  getTransferMethodName(transferMethod: DocumentTransferMethod){
    return transferMethod.name;
  }

  handleResetCloneServiceClick(): void {
    this.serviceToClone.set(null);
    this.selectedDocuments = [];
    this.selectedClassifiers.reset();
    this.servicesToClone = [];
    this.cloneSearchForm.reset();
  }

  formSubmit() {
    const values = this.formGroup.value;

    const requestData = {
      name: values.name,
      title: values.title,
      sierNumber: values.sierNumber,
      type: values.type,
      activityStart: values.activityStart ? new Date(values.activityStart).toISOString() : undefined,
      activityEnd: values.activityEnd ? new Date(values.activityEnd).toISOString() : undefined,
      applicantCategoriesIds: this.selectedClassifiers.getIds(
        'applicantCategories'
      ),
      documentsIds: this.selectedDocuments.map((doc: Document) => doc.id),
      lifeSituationsIds: this.selectedClassifiers.getIds('lifeSituations'),
      territorialityIds: this.selectedClassifiers.getIds('territoriality'),
      departmentsIds: this.selectedClassifiers.getIds('departments'),
      divisionsIds: this.selectedClassifiers.getIds('divisions'),
      isNotForTosp: values.isNotForTosp,
      isNotForUrm: values.isNotForUrm,
      documentsTransferMethodsIds: this.selectedDocumentsTransferMethods().map(method => method.id),
      resultsTransferMethodId: this.selectedResultsTransferMethod()?.id
    };

    this.onFormSubmit(requestData)
  }

  onFormSubmit(requestData: any) {
    if (this.serviceToClone()) {
      this.servicesService.cloneService(this.serviceToClone()!.id, requestData)
        .subscribe({
          next: (service: Service) => {
            this.alertService.toggle('Услуга скопирована');
            this.submit.emit(service);
            this.close.emit();
          },
          error: (err: Error) => console.error(err),
        });
    }
  }

  protected readonly ServicesTypesEnum = ServicesTypesEnum;
}
