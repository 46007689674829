<div class="card" [ngClass]="{'card_warning': isWarning(), 'card_whiteBg': whiteBg(), 'card_template': isTemplate()}">
    <div class="card__head">
        <p class="on-container subtitle-big">{{name()}}</p>
    </div>
    <div class="card__body on-container-low">
      <div class="card__row">
        <p class="card__period">
            <span class="material-symbols-outlined">
                calendar_today
            </span>
            {{period()}}
        </p>
      </div>
      @if (attachments() && !isTemplate()) {
        <div class="card__row">
            <div class="card__period card__period_no-margin">
                <span class="material-symbols-outlined">
                    attach_file
                </span>
                Вложения
            </div>
            <span>{{attachments().length}}</span>
        </div>
      }
      @if (relations() && !isTemplate()) {
        <div class="card__row">
            <div class="card__period card__period_no-margin">
                <span class="material-symbols-outlined">
                    description
                </span>
                Связи с документами
            </div>
            <span>{{relations().length}}</span>
        </div>
      }
      @if (isWarning()) {
        <div class="card__period card__period_no-margin">
            <span class="material-symbols-outlined material-symbols-filled error-icon">
                warning
            </span>
            <p class="subtitle-default error-text">Документ изменен</p>
        </div>
      }
  </div>
  <div class="card__footer">
      <app-icon-button size="small" type="circle" display="outline" icon="edit_square"
          color="outline-low" (click)="onEditClicked()" *ngif="!isTemplate()"></app-icon-button>
      <app-icon-button size="small" type="circle" display="outline" icon="visibility"
          color="outline-low" (click)="onShowClicked()"></app-icon-button>
      <app-icon-button size="small" type="circle" display="outline" icon="cancel"
          color="outline-low" (click)="onDeleteClicked()"></app-icon-button>
  </div>
</div>
