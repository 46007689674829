<div class="services-ui">
  <div class="reg-docs-header">
    <div class="reg-docs-header__left">
      <p class="reg-docs-header__title title-bold-h1">
        Регламентирующие документы
      </p>
      <app-badge
        class="reg-docs-header__badge"
        size="normal"
        display="tint"
        color="secondary-container"
        size_text="default"
        badge_content="{{ totalCount }}">
      </app-badge>
    </div>
    <app-button
      size="normal"
      type="left"
      icon="add_circle"
      [fill]="true"
      display="fill"
      color="primary"
      sizeText="default"
      buttonContent="Добавить"
      (click)="openAddModal()">
    </app-button>
  </div>
  <div class="reg-docs-search area_white_bg">
    <div class="reg-docs-search__input">
      <core-text-input
        placeholder="Поиск документа"
        leftIcon="search"
        [formControl]="name"></core-text-input>
    </div>
  </div>
  <div class="reg-docs-tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          [class.tab_active]="tab.class === currentTab"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
          @if (tab.class === 'tabs__need-attention' && needAttentionRegDocsTotal > 0) {
            <span class="tabs__counter-attention primary text-small">
              {{ needAttentionRegDocsTotal }}
            </span>
          }
        </button>
      }
    </div>
    <!--    <button class="filter">-->
    <!--      Сперва новые-->
    <!--      <span class="material-symbols-outlined">-->
    <!--        keyboard_arrow_down-->
    <!--      </span>-->
    <!--    </button>-->
  </div>
</div>
<div class="reg-docs-main">
  <div class="reg-docs-main__list">
    @switch (currentTab) {
      @case ('tabs__archive') {
        @if (!archivedRegDocs().length) {
          <p class="text-bigger">Нет архивных документов</p>
        }
        @for (container of archivedRegDocs(); track container.id) {
          <div class="list-item">
            <div class="list-item__header">
              <p class="list-item__title subtitle-big">
                {{ container.title }}
              </p>
            </div>
            <hr class="list-item__line" />
            <div class="list-item__main">
              <ul class="list-item__data on-container-low">
                <li class="data-item">
                  <div class="data-item__icon material-symbols-outlined">
                    calendar_today
                  </div>
                  <div class="data-item__date text-default">
                    {{
                      parseActivity(
                        getActualVersion(container)?.activityStart,
                        getActualVersion(container)?.activityEnd
                      )
                    }}
                  </div>
                </li>
                <li class="data-item material-symbols-outlined">
                  <div class="data-item__icon material-symbols-outlined">
                    attach_file
                  </div>
                  <div class="data-item__date text-default">Вложения</div>
                  @if (getActualVersion(container)) {
                    <div class="data-item__count subtitle-default">
                      {{ getActualVersion(container)?.attachments?.length }}
                    </div>
                  }
                </li>
                <li class="data-item">
                  <div class="data-item__icon material-symbols-outlined">
                    description
                  </div>
                  <div class="data-item__date text-default">
                    Связи с документами
                  </div>
                  <div class="data-item__count subtitle-default">
                    {{ getActualVersion(container)?.relatedDocuments?.length }}
                  </div>
                </li>
              </ul>
            </div>
            <hr class="list-item__line" />
            <div class="list-item__footer">
              <app-icon-button
                size="small"
                type="circle"
                icon="edit_square"
                display="outline"
                color="outline-low"
                (click)="openEditModal(container)"
              ></app-icon-button>
              <a
                [routerLink]="[container.id]"
                [queryParams]="{ type: 'actual' }">
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="visibility"
                  display="outline"
                  color="outline-low">
                </app-icon-button>
              </a>
              <app-icon-button
                size="small"
                type="circle"
                icon="cancel"
                display="outline"
                color="outline-low"
                (click)="handleDeleteClick(container.id, container.title)">
              </app-icon-button>
            </div>
          </div>
        }
      }
      @case ('tabs__actual') {
        @if (!regDocs().length) {
          <p class="text-bigger">Нет актуальных документов</p>
        }
        @for (container of regDocs(); track container.id) {
          <div class="list-item">
            <div class="list-item__header">
              <p class="list-item__title subtitle-big">
                {{ container.title }}
              </p>
            </div>
            <hr class="list-item__line" />
            <div class="list-item__main">
              <ul class="list-item__data on-container-low">
                <li class="data-item">
                  <div class="data-item__icon material-symbols-outlined">
                    calendar_today
                  </div>
                  <div class="data-item__date text-default">
                    {{
                      parseActivity(
                        getActualVersion(container)?.activityStart,
                        getActualVersion(container)?.activityEnd
                      )
                    }}
                  </div>
                </li>
                <li class="data-item material-symbols-outlined">
                  <div class="data-item__icon material-symbols-outlined">
                    attach_file
                  </div>
                  <div class="data-item__date text-default">Вложения</div>
                  @if (getActualVersion(container)) {
                    <div class="data-item__count subtitle-default">
                      {{ getActualVersion(container)?.attachments?.length }}
                    </div>
                  }
                </li>
                <li class="data-item">
                  <div class="data-item__icon material-symbols-outlined">
                    description
                  </div>
                  <div class="data-item__date text-default">
                    Связи с документами
                  </div>
                  <div class="data-item__count subtitle-default">
                    {{ getActualVersion(container)?.relatedDocuments?.length }}
                  </div>
                </li>
              </ul>
            </div>
            <hr class="list-item__line" />
            <div class="list-item__footer">
              <app-icon-button
                size="small"
                type="circle"
                icon="edit_square"
                display="outline"
                color="outline-low"
                (click)="openEditModal(container)"
              ></app-icon-button>
              <a
                [routerLink]="[container.id]"
                [queryParams]="{ type: 'actual' }">
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="visibility"
                  display="outline"
                  color="outline-low">
                </app-icon-button>
              </a>
              <app-icon-button
                size="small"
                type="circle"
                icon="cancel"
                display="outline"
                color="outline-low"
                (click)="handleDeleteClick(container.id, container.title)">
              </app-icon-button>
            </div>
          </div>
        }
      }
      @case ('tabs__need-attention') {
        @if (needAttentionRegDocs() && !needAttentionRegDocs().length) {
          <p class="text-bigger">Нет требующих внимания документов</p>
        }
        @for (container of needAttentionRegDocs(); track container.id) {
          <div class="list-item">
            <div class="list-item__header">
              <p class="list-item__title subtitle-big">
                {{ container.title }}
              </p>
            </div>
            <hr class="list-item__line">
            <div class="list-item__main">
              <ul class="list-item__data on-container-low ">
                <li class="data-item">
                  <div class="data-item__icon material-symbols-outlined">calendar_today</div>
                  <div class="data-item__date text-default">
                    {{
                      parseActivity(
                        getActualVersion(container)?.activityStart,
                        getActualVersion(container)?.activityEnd
                      )
                    }}
                  </div>
                </li>
                <li class="data-item material-symbols-outlined">
                  <div class="data-item__icon material-symbols-outlined">attach_file</div>
                  <div class="data-item__date text-default">Вложения</div>
                  @if (getActualVersion(container)) {
                    <div class="data-item__count subtitle-default">{{ getActualVersion(container)?.attachments?.length }}</div>
                  }
                </li>
                <li class="data-item">
                  <div class="data-item__icon material-symbols-outlined">description</div>
                  <div class="data-item__date text-default">Связи с документами</div>
                  <div class="data-item__count subtitle-default">4</div>
                </li>
              </ul>
            </div>
            <hr class="list-item__line">
            <div class="list-item__footer">
              <div class="list-item__footer-attention">
                <span class="material-symbols-outlined">
                  warning
                </span>
                <p>Есть изменения. Требуется подтверждение</p>
              </div>
            </div>
            <div class="list-item__button">
              <a [routerLink]="[container.id]" [queryParams]="{type: 'needAttention'}">
                <app-button size="smallest"
                            type="no"
                            display="outline"
                            color="outline-low"
                            sizeText="default"
                            buttonContent="Посмотреть"
                ></app-button>
              </a>
            </div>
          </div>
        }
      }
    }
  </div>
</div>
