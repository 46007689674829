import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';

export type IconInputType =
  | 'file_download'
  | 'print'
  | 'folder_open'
  | 'help'
  | 'logout'
  | 'settings'
  | 'notifications'
  | 'cancel'
  | 'calendar_today'
  | 'expand_content'
  | 'arrow_left_alt'
  | 'keyboard_arrow_up'
  | 'keyboard_arrow_down'
  | 'undo'
  | 'redo'
  | 'format_bold'
  | 'format_italic'
  | 'visibility'
  | 'visibility_off'
  | 'edit_square'
  | 'width'
  | 'remove'
  | 'add'
  | 'more_vert'
  | 'link'
  | 'arrow_top'
  | 'delete'
  | 'link_off';

export type IconDisplayType =
  | 'fill'
  | 'fill-secondary'
  | 'tint-secondary'
  | 'cell'
  | 'inverse'
  | 'outline'
  | 'overlay';

export type IconColorType =
  | 'primary'
  | 'secondary'
  | 'secondary-container'
  | 'cell'
  | 'inverse-container'
  | 'outline-low'
  | 'container-lowest';

export type IconSizeType = 'normal' | 'small' | 'smallest' | 'tiny';

export type IconType = 'square' | 'circle';

@Component({
  selector: 'app-icon-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  size = input.required<IconSizeType>();
  type = input.required<IconType>();
  icon = input<IconInputType>();
  fill = input<boolean>(false);
  display = input.required<IconDisplayType>();
  color = input.required<IconColorType>();
  tabindex = input<number>(0);
}
