import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  TextInputComponent,
} from '@core/components';
import { AlertService, ResultsTransferMethodsService } from '@core/services';
import { DocumentTransferMethod } from '@core/models';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';

import { AddResultsTransferMethodModalComponent } from '../modals/docs-transfer-method-modal/add-results-transfer-method-modal/add-results-transfer-method-modal.component';
import { EditResultsTransferMethodModalComponent } from '../modals/docs-transfer-method-modal/edit-results-transfer-method-modal/edit-results-transfer-method-modal.component';

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-results-transfer-method-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    TextInputComponent,
    FormsModule,
    ReactiveFormsModule,
    IconButtonComponent,
  ],
  templateUrl: './results-transfer-method-page.component.html',
  styleUrl: './results-transfer-method-page.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsTransferMethodPageComponent implements OnInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  public searchForm: FormGroup<SearchForm>;
  public resultsTransferMethods = signal<DocumentTransferMethod[]>([]);
  public isSearchBlocked: boolean = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly resultsTransferMethodsService: ResultsTransferMethodsService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ resultsTransferMethods }) => {
      this.resultsTransferMethods.set(resultsTransferMethods);
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  public get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.resultsTransferMethodsService
        .getResultsTransferMethods({
          name: query,
        })
        .subscribe({
          next: (docTransferMethodList: DocumentTransferMethod[]) => {
            this.resultsTransferMethods.set(docTransferMethodList);
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddResultsTransferMethodModalComponent,
      {}
    );

    modal.componentRef.instance.submit.subscribe((method: DocumentTransferMethod) => {
      this.resultsTransferMethods.update((resultsTransferMethods: DocumentTransferMethod[]) => {
        resultsTransferMethods.push(method);
        this.cdr.markForCheck();
        return resultsTransferMethods;
      });
    });
  }

  openEditModal(method: DocumentTransferMethod) {
    const modal = openModal(
      this.overlay,
      EditResultsTransferMethodModalComponent,
      {
        method: method
      }
    );

    modal.componentRef.instance.submit.subscribe((method: DocumentTransferMethod) => {
      const index = this.resultsTransferMethods().findIndex(x => x.id === method.id);

      if (index !== -1) {
        this.resultsTransferMethods.update((resultsTransferMethods: DocumentTransferMethod[]) => {
          resultsTransferMethods[index] = method;
          this.cdr.markForCheck();
          return resultsTransferMethods;
        });
      } else {
        throw new Error();
      }
    })
  }

  public handleClickDeleteTransferMethod(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить способ получения результата "${name}"?`
    );

    if (isAccepted) {
      this.resultsTransferMethodsService
        .deleteResultsTransferMethod(id)
        .subscribe({
          next: () => {
            this.resultsTransferMethods.set(
              this.resultsTransferMethods().filter(method => method.id !== id)
            );
            this.alertService.toggle('Метод удален');
          },
          error: err => console.error(err),
        });
    } else return;
  }
}
