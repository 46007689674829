import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { AlertComponent, BadgeComponent, ButtonComponent, IconButtonComponent } from '@core/components';
import { AlertService, DocsSampleService } from '@core/services';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { DocumentsTemplatesList, DocumentTemplate } from '@core/models';
import { convertFileSizeHelper, getFileExtensionHelper, openFileInNewTabHelper } from '@core/utils';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  AddDocSampleModalComponent,
} from '@scripter-admin/app/modals/docs-sample-modal/add-doc-sample-modal/add-doc-sample-modal.component';
import {
  EditDocSampleModalComponent,
} from '@scripter-admin/app/modals/docs-sample-modal/edit-doc-sample-modal/edit-doc-sample-modal.component';

interface SearchForm {
  name: FormControl<string>;
}

type Tab = {
  title: string;
  class: string;
};

@Component({
  selector: 'app-docs-sample-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    NgClass,
    IconButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './docs-sample-page.component.html',
  styleUrl: './docs-sample-page.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocsSamplePageComponent implements OnInit, AfterViewInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  searchForm: FormGroup<SearchForm>;
  public docsSamples = signal<DocumentTemplate[]>([]);
  public archived = signal<DocumentTemplate[]>([]);
  isSearchBlocked: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private docSampleService: DocsSampleService,
    private readonly alertService: AlertService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ docsSamples }) => {
      this.docsSamples.set(docsSamples.active.data);
      this.archived.set(docsSamples.archive.data);
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
  ];

  public currentTab: string = this.tabs[0].class;

  handleTabClick(tabName: string): void {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  ngAfterViewInit(): void {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  deleteDocSample(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить образец "${name}"?`
    );

    if (isAccepted) {
      this.docSampleService.deleteDocSample(id).subscribe({
        next: () => {
          this.docSampleService.getDocSamples().subscribe({
            next: docs => {
              this.docsSamples.set(docs.active.data);
              this.archived.set(docs.archive.data);
            },
          });
          this.alertService.toggle('Образец документа удален');
        },
        error: (error: Error) => {
          console.log(error);
        },
      });
    } else return;
  }

  handleSearch(query: string): void {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.docSampleService
        .getDocSamples({
          name: query,
        })
        .subscribe({
          next: (docSampleList: DocumentsTemplatesList) => {
            this.docsSamples.set(docSampleList.active.data);
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddDocSampleModalComponent,
      {}
    )

    modal.componentRef.instance.submit.subscribe((sample: DocumentTemplate) => {
      this.docsSamples.update((samples: DocumentTemplate[]) => {
        samples.unshift(sample);
        this.cdr.markForCheck();
        return samples;
      })
    })
  }

  openEditModal(sample: DocumentTemplate) {
    const modal = openModal(
      this.overlay,
      EditDocSampleModalComponent,
      {
        sample
      }
    )

    modal.componentRef.instance.submit.subscribe((method: DocumentTemplate) => {
      const index = this.docsSamples().findIndex(x => x.id === method.id);

      if (index !== -1) {
        this.docsSamples.update((samples: DocumentTemplate[]) => {
          samples[index] = method;
          this.cdr.markForCheck();
          return samples;
        })
      } else {
        throw new Error();
      }
    })
  }

  protected readonly getFileExtensionHelper = getFileExtensionHelper;
  protected readonly convertFileSizeHelper = convertFileSizeHelper;
  protected readonly openFileInNewTabHelper = openFileInNewTabHelper;
}
