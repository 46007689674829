import {
  Component,
  ElementRef,
  HostListener, inject, input,
  Input, OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';

import { AlertService, ModalService, TemplatesService, ServicesService, DocsSampleService } from '@core/services';
import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  ModalComponent,
  SearchComponent,
  SelectComponent,
} from '@core/components';
import {
  PresendedFormEnum,
  PresendedPackEnum,
  ServiceBlockTypes,
} from '@core/utils/enums';
import {
  ChartElement,
  Document,
  DocumentTemplate,
  OrgItem,
  Service,
  ServiceBlock,
  Template,
  TemplatesList,
} from '@core/models';

import { HeaderComponent } from './header/header.component';
import {
  CreateTemplateBlockDto,
  CreateTemplateDto,
  CreateServiceBlockDto
} from '@core/models/dto';
import { MenuListComponent } from '@core/components/chart/menu-list/menu-list.component';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { DateInputComponent } from '@core/components/inputs/date-input/date-input.component';
import { TextareaInputComponent } from '@core/components/inputs/textarea-input/textarea-input.component';
import { debounceTime, retry } from 'rxjs';
import * as uuid from 'uuid';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';

interface CreateTemplateForm {
  name: FormControl<string>;
  activityStart: FormControl<string>;
  activityEnd: FormControl<string>;
}

interface ChartBlockForm {
  title: FormControl<string>;
  note?: FormControl<string>;
}

interface BlockAnswersForm {
  answers: FormArray<FormGroup<Answer>>
}

interface Answer {
  id: FormControl<string | null>
  name: FormControl<string>,
  documents: FormArray<FormGroup<FormDocument>>,
}

interface FormDocument {
  title: FormControl<string>,
  note: FormControl<string>,
  documentTemplateId: FormControl<number | null>,
  presendedFormType: FormControl<PresendedFormEnum>,
  presendedPackType: FormControl<PresendedPackEnum>,
  serviceBlockId: FormControl<number | null>,
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    HeaderComponent,
    ModalComponent,
    NgClass,
    SearchComponent,
    ReactiveFormsModule,
    BadgeComponent,
    IconButtonComponent,
    SelectComponent,
    ButtonComponent,
    MenuListComponent,
    TextInputComponent,
    TextareaInputComponent,
    DateInputComponent,
    AutocompleteComponent
  ],
  providers: [AlertService, AlertComponent],
})
export class ChartComponent implements OnInit {
  modalInput = input<{service: Service, serviceBlocks: ServiceBlock[]}>();
  protected readonly ServiceBlockTypes = ServiceBlockTypes;
  protected readonly PresendedFormEnum = PresendedFormEnum;
  protected readonly PresendedPackEnum = PresendedPackEnum;
  constructor(
    private readonly router: Router,
    protected modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly templatesService: TemplatesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly servicesService: ServicesService
  ) {}
  private readonly docSampleService = inject(DocsSampleService);

  @Input() modalVersion: boolean = false;

  isEdited = false;
  type: 'service' | 'template' = 'template';
  service!: Service;
  template!: Template;
  orgItems = new Map<string, OrgItem>();

  get title(): string {
    if (this.service) {
      return this.service.title;
    }
    if (this.template) {
      return this.template.name;
    }
    return '';
  }

  get redirectUri(): string {
    if (this.service) {
      return '/services/' + this.service.id;
    }
    
    return '/templates';
  }

  get maxLevel(){
    return [...this.orgItems.values()].reduce((level, item) => Math.max(level, item.level), 0);
  }

  rootItem: OrgItem | null = null;
  selectedItem: OrgItem | null = null;
  menuOpened = false;
  showAddBar = false;
  possibleChildBlocks = new Array<OrgItem>();

  isNewBlock = false;
  editBlockType: ServiceBlockTypes = ServiceBlockTypes.Intermediate;
  showEditBlockError = false;

  blockEditForm = new FormGroup<ChartBlockForm>({
    title: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    note: new FormControl<string>('', {
      nonNullable: true,
    })
  });
  get blockTitle(): FormControl<string> {
    return this.blockEditForm.controls['title'] as FormControl<string>;
  }
  get blockNote(): FormControl<string> {
    return this.blockEditForm.controls['note'] as FormControl<string>;
  }

  blockAnswersForm = new FormGroup<BlockAnswersForm>({
    answers: new FormArray<FormGroup>([]),
  });
  get blockAnswers(){
    return this.blockAnswersForm.controls.answers;
  }

  public templatesToClone: Template[] = [];
  templateSearchForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });
  get templateSearchControl(): FormControl<string> {
    return this.templateSearchForm.get('name') as FormControl<string>;
  }

  createTemplateError: string = '';
  createTemplateForm = new FormGroup<CreateTemplateForm>({
    name: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    activityStart: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    activityEnd: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
  });
  get createName(): FormControl<string> {
    return this.createTemplateForm.controls['name'] as FormControl<string>;
  }
  get activityStart(): FormControl<string> {
    return this.createTemplateForm.controls[
      'activityStart'
    ] as FormControl<string>;
  }
  get activityEnd(): FormControl<string> {
    return this.createTemplateForm.controls[
      'activityEnd'
    ] as FormControl<string>;
  }
  get createBlockDto(): any[] {
    return [...this.orgItems.values()].map((item) => ({
            id: item.id,
            title: item.name,
            note: item.note,
            type: item.type,
            childIds: [...item.childrens!].map((child) => child.id),
            level: item.level,
            isFinal: false,
            serviceId: this.service ? this.service.id : undefined,
            templateId: item.templateId,
            documents: item.documents?.map((doc: any) => ({
              title: doc.title,
              note: doc.note,
              documentTemplateId: doc.documentTemplateId || undefined,
              presendedFormType: doc.presendedFormType,
              presendedPackType: doc.presendedPackType,
            })),
          }
      ));
  }

  @ViewChild('chart') chart?: ElementRef<SVGElement>;
  colors = ['#FFF0EE', '#EFEFFF', '#FFDAD6', '#FFFFFF'];
  WIDTH = window.innerWidth - 80;
  HEIGHT = window.innerHeight - 147;
  ELEMENT_WIDTH = 300;
  ELEMENT_HEIGHT = 100;
  PADDING = 100;
  zoom = 1;
  ZOOM_FACTOR = 1.2;
  dragMode = true;
  moveMode = false;
  startX = 0;
  startY = 0;
  x0 = 0;
  y0 = 0;
  x = 0;
  y = 0;
  zoomValue = 100;
  minZoom = 25;
  maxZoom = 200;
  zoomTimeout = false;
  pinchStartDistance: number = 0;
  viewBox = `${this.zoom * this.x} ${this.zoom * this.y} ${this.zoom * this.WIDTH} ${this.zoom * this.HEIGHT}`
  chartItemsWidth = new Map<string, number>();
  chartItemsPosition = new Map<string, { x: number, y: number }>();
  chartElementList: ChartElement[] = [];

  suggestedDocumentTemplates: DocumentTemplate[] = [];

  ngOnInit() {
    if (this.modalInput()?.service && this.modalInput()?.serviceBlocks) {
      this.service = this.modalInput()!.service;
      this.type = 'service';
      this.setOrgItems(this.modalInput()!.serviceBlocks);
    }
    this.activatedRoute.data.subscribe(
      ({
        service,
        template,
        serviceBlocks,
        templatesBlocks,
      }) => {
        if (service) {
          this.service = service;
          this.type = 'service';
        }
        if (template) {
          this.template = template;
          this.type = 'template';
        }
        if (serviceBlocks) {
          this.setOrgItems(serviceBlocks)
        }
        if (templatesBlocks) {
          this.setOrgItems(templatesBlocks)
        }

        this.setChartSize();

        // if (!this.modalVersion) {
        //   this.WIDTH = 300;
        //   this.HEIGHT = 300;
        // }
      }
    );
    this.searchTemplatesToClone("");
    this.templateSearchForm.get('name')!.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: any) => this.searchTemplatesToClone(value));
  }

  ngAfterViewInit() {
    window.setTimeout(() => this.setChartSize());
  }

  @HostListener('window:resize')
  onResize() {
    this.setChartSize();
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(e: MouseEvent) {
    if (this.dragMode) {
      this.moveMode = true;
      this.x0 = this.x;
      this.y0 = this.y;
      this.startX = e.clientX;
      this.startY = e.clientY;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    if (this.dragMode) {
      this.moveMode = false;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    if (this.moveMode) {
      const dx = e.clientX - this.startX;
      const dy = e.clientY - this.startY;
      this.x = this.x0 - dx;
      this.y = this.y0 - dy;
      this.setViewBox();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  detectRefresh(event: Event) {
    if (this.isEdited) {
      event.preventDefault();
    }
  }

  setChartSize() {
    if (this.chart) {
      this.WIDTH = this.chart.nativeElement.clientWidth;
      this.HEIGHT = this.chart.nativeElement.clientHeight;
      this.setViewBox();
      this.updateChart();
    }
  }

  setViewBox() {
    this.viewBox = `${this.zoom * this.x} ${this.zoom * this.y} ${this.zoom * this.WIDTH} ${this.zoom * this.HEIGHT}`
  }

  zoomIn(delta: number = 25 - this.zoomValue % 25) {
    if (this.zoomValue < this.maxZoom) {
      this.zoomValue += delta;
      this.zoom = 100 / this.zoomValue;
      this.setViewBox();
    }
  }

  zoomOut(delta: number = this.zoomValue % 25 || 25) {
    if (this.zoomValue > this.minZoom) {
      this.zoomValue -= delta;
      this.zoom = 100 / this.zoomValue;
      this.setViewBox();
    }
  }

  onMouseScroll(event: WheelEvent){
    if (this.zoomTimeout || !this.chartElementList.length) {
      return;
    }

    if (event.deltaY < 0) {
      this.zoomIn(1);
    } else {
      this.zoomOut(1);
    }

    //this.zoomTimeout = true;
    //setTimeout(() => this.zoomTimeout = false, 200);
  }

  onTouchStart(event: TouchEvent){
    this.pinchStartDistance = this.getPinchDistance(event);
  }

  onTouchMove(event: TouchEvent){
    if(this.zoomTimeout || !this.chartElementList.length){
      return;
    }
    const currentDistance = this.getPinchDistance(event);
    if(currentDistance && this.pinchStartDistance){
      if (currentDistance > this.pinchStartDistance) {
        this.zoomIn(1);
      } else if (currentDistance < this.pinchStartDistance) {
        this.zoomOut(1);
      }

      this.pinchStartDistance = currentDistance;
      //this.zoomTimeout = true;
      //setTimeout(() => this.zoomTimeout = false, 200);
    }
  }

  getPinchDistance(event: TouchEvent): number {
    if(event.touches.length < 2){
      return 0;
    }

    const touch1 = event.touches.item(0)!;
    const touch2 = event.touches.item(1)!;
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  onPan() {
    if (this.chartElementList.length) {
      this.dragMode = !this.dragMode;
    } else {
      this.dragMode = false;
    }
  }

  setOrgItems(serviceBlocks: ServiceBlock[]){
    this.orgItems.clear();
    serviceBlocks.forEach((block: ServiceBlock) => {
      const newItem: OrgItem = {
        id: block.clientId,
        level: block.level,
        type: block.type,
        name: block.title,
        note: block.note,
        documents: block.documents,
        templateId: block.templateId,
        parents: new Set(),
        childrens: new Set(),
      };
      this.orgItems.set(newItem.id, newItem);
    });
    serviceBlocks.forEach((block: ServiceBlock) => {
      const currentItem = this.orgItems.get(block.clientId)!;

      block.childIds?.forEach((id) => {
        const childItem = this.orgItems.get(id);
        if(childItem){
          this.setChildItem(currentItem, childItem);
        }
      });

      if(!this.rootItem || currentItem.level < this.rootItem.level){
        this.rootItem = currentItem;
      }
    });
  }

  updateChart(){
    this.chartItemsWidth.clear();
    this.chartItemsPosition.clear();
    this.chartElementList = [];
    if(this.rootItem){
      this.setChartItemsWidths(this.rootItem.level);
      this.setChartItemsPositions(this.rootItem, { x: this.WIDTH / 2, y: this.ELEMENT_HEIGHT * 2 });
      this.setChartElements();
    }
  }

  setChartItemsWidths(currentLevel: number = 0, deepest = currentLevel, branches = currentLevel != deepest){
    const levelItems = [...this.orgItems.values()].filter((item) => item.level == currentLevel);

    levelItems.forEach((item) => {
      if(branches && item.parents?.size){
        const parentsWidth = [...item.parents!].reduce((sum, parent) => (sum + (this.chartItemsWidth.get(parent.id) || 1) / parent.childrens!.size), 0);
        if(parentsWidth > (this.chartItemsWidth.get(item.id) || 1)){
          this.chartItemsWidth.set(item.id, parentsWidth);
        }
      }

      if(item.childrens?.size){
        item.childrens.forEach((child) => deepest = Math.max(deepest, child.level));
      }
    });

    branches = currentLevel + 1 != deepest || levelItems.filter((item) => item.childrens?.size).length > 1;

    if(currentLevel < this.maxLevel){
      this.setChartItemsWidths(currentLevel + 1, deepest, branches);
    }

    levelItems.forEach((item) => {
      if(branches && item.childrens!.size){
        const childrensWidth = [...item.childrens!].reduce((sum, child) => (sum + (this.chartItemsWidth.get(child.id) || 1) / child.parents!.size), 0);
        if(childrensWidth > (this.chartItemsWidth.get(item.id) || 1)){
          this.chartItemsWidth.set(item.id, childrensWidth);
        }
      }

      if(item.parents?.size){
        item.parents.forEach((parent) => deepest = Math.min(deepest, parent.level));
      }
    });
  }

  setChartItemsPositions(item: OrgItem, position: { x: number, y: number }){
    this.chartItemsPosition.set(item.id, position);
    if(item.childrens?.size){
      const childrensWidth = [...item.childrens].reduce((sum, child) => sum + (this.chartItemsWidth.get(child.id) || 1), 0);
      let offset = -0.5 * childrensWidth;
      item.childrens.forEach((child) => {
        if(this.chartItemsPosition.has(child.id)) return;
        const childWidth = this.chartItemsWidth.get(child.id) || 1;
        offset += childWidth;
        this.setChartItemsPositions(child, {
          x: position.x + (offset - childWidth / 2) * 1.5 * this.ELEMENT_WIDTH,
          y: position.y + (child.level - item.level) * 2 * this.ELEMENT_HEIGHT
        });
      });
    }
  }

  setChartElements(){
    this.chartElementList = [...this.orgItems.values()]
      .sort((prev, next) => prev.level - next.level)
      .filter((item) => this.chartItemsPosition.has(item.id))
      .map((item) => {
        const { x, y } = this.chartItemsPosition.get(item.id)!;
        return {
          x, y, item
        }
      });
  }

  getTopConnections(e: ChartElement): string[] {
    return [...e.item.parents!].map((parent) => {
      const parentPosition = this.chartItemsPosition.get(parent.id)!;
      return (
        `M ${parentPosition.x} ${parentPosition.y + this.ELEMENT_HEIGHT / 2} ` +
        `${parentPosition.x} ${e.y - this.ELEMENT_HEIGHT} ` +
        `${e.x} ${e.y - this.ELEMENT_HEIGHT} ` +
        `${e.x} ${e.y - this.ELEMENT_HEIGHT / 2 - 8} `
        );
    });
  }

  getBottomConnection(e: ChartElement): string {
    return (
      `M ${e.x} ${e.y + this.ELEMENT_HEIGHT / 1.95} ` +
        `${e.x} ${e.y + this.ELEMENT_HEIGHT - 15}` ?? ''
    );
  }

  showAddOptions(block?: OrgItem) {
    this.showAddBar = true;
    if(block){
      this.menuOpened = false;
      this.selectedItem = block;
      this.setPossibleChildBlocks();
    }
  }

  hideAddOptions() {
    this.selectedItem = null;
    this.showAddBar = false;
  }

  showBlockDocuments(block: OrgItem) {
    this.selectedItem = block;
    this.modalService.open('documents-modal');
  }

  toggleBlockMenu(block: OrgItem) {
    if (this.menuOpened && this.selectedItem === block) {
      this.menuOpened = false;
    } else {
      this.selectedItem = block;
      this.menuOpened = true;
    }
  }

  openAddForm(type: ServiceBlockTypes) {
    this.showAddBar = false;
    this.dragMode = false;
    
    this.blockEditForm.reset();
    this.blockAnswers.clear();

    if(type === ServiceBlockTypes.Template){
      this.modalService.open('add-template-modal');
    } else {
      this.isNewBlock = true;
      this.editBlockType = type;
      this.modalService.open('add-modal');
    }
    
    if(type === ServiceBlockTypes.Result){
      this.blockEditForm.controls.title.setValue("Успешное окончание");
      this.handleSubmitForm();
    }
  }

  openEditForm() {
    if(this.selectedItem){
      this.showAddBar = false;
      this.dragMode = false;

      this.isNewBlock = false;
      this.editBlockType = this.selectedItem.type;
      this.blockEditForm.reset();
      this.blockEditForm.setValue({
        title: this.selectedItem.name,
        note: this.selectedItem.note,
      });
      
      this.blockAnswers.clear();
      this.modalService.open('add-modal');

      if(this.selectedItem.type === ServiceBlockTypes.Intermediate){
        this.selectedItem.childrens!.forEach((answer) => {
          const formAnswer = this.addNewAnswer();
          formAnswer.controls.id.setValue(answer.id);
          formAnswer.controls.name.setValue(answer.name);
          if(answer.documents){
            answer.documents.forEach((document => {
              const formDocument = this.newDocument;
              formDocument.controls.documentTemplateId.setValue(document.documentTemplateId || null);
              formDocument.controls.title.setValue(document.title);
              formDocument.controls.note.setValue(document.note);
              formDocument.controls.presendedFormType.setValue(document.presendedFormType);
              formDocument.controls.presendedPackType.setValue(document.presendedPackType);
              formAnswer.controls.documents.push(formDocument as any);
            }));
          }
        });
        this.searchDocumentTemplates("");
      }

    }
  }

  addNewAnswer(): FormGroup<Answer> {
    const control = new FormGroup<Answer>({
      id: new FormControl<string>(""),
      name: new FormControl<string>("", {
        validators: Validators.required,
        nonNullable: true,
      }),
      documents: new FormArray<FormGroup<FormDocument>>([]),
    })
    this.blockAnswers.push(control);
    return control;
  }

  removeAnswer(index: number): void {
    const isNewAnswer = !this.blockAnswers.value[index].id;
    if(isNewAnswer || confirm("Вы действительно хотите удалить этот ответ?")){
      this.blockAnswers.removeAt(index);
    }
  }

  handleAnswerSortTopClick(index: number): void {
    if (index === 0) return;
    const temp = this.blockAnswers.controls[index];
    this.blockAnswers.controls[index] = this.blockAnswers.controls[index - 1];
    this.blockAnswers.controls[index - 1] = temp;
  }

  handleAnswerSortBottomClick(index: number): void {
    if (index + 1 === this.blockAnswers.length) return;
    const temp = this.blockAnswers.controls[index];
    this.blockAnswers.controls[index] = this.blockAnswers.controls[index + 1];
    this.blockAnswers.controls[index + 1] = temp;
  }

  get newDocument(): FormGroup<FormDocument>{
    return new FormGroup({
      title: new FormControl("", { nonNullable: true }),
      note: new FormControl<string>("", { nonNullable: true }),
      documentTemplateId: new FormControl<number | null>(null),
      presendedFormType: new FormControl<PresendedFormEnum>(PresendedFormEnum.Original, { nonNullable: true }),
      presendedPackType: new FormControl<PresendedPackEnum>( PresendedPackEnum.Original, { nonNullable: true }),
      serviceBlockId: new FormControl<number | null>(null),
    });
  }

  addDocumentClick(index: number): void {
    const documents = this.getAnswerDocuments(index);
    documents.push(this.newDocument as any);
    this.searchDocumentTemplates("");

  }

  removeDocumentClick(index: number, documentId: number): void {
    (this.blockAnswers.controls[index].get('documents') as FormArray)?.removeAt(
      documentId
    );
  }

  getAnswerDocuments(index: number) {
    const answer = this.blockAnswers.controls[index];
    return answer.controls.documents;
  }

  presendedFormString(en: string) {
    const string =
      en === 'Original'
        ? 'Оригинал'
        : en === 'Copy'
          ? 'Копия'
          : en === 'CertifiedCopy'
            ? 'Заверенная копия'
            : en === 'Information'
              ? 'Информация'
              : '';
    return string;
  }

  isEqualDocumentPresendedForm(
    index: number,
    documentId: number,
    form: PresendedFormEnum
  ): boolean {
    const document = this.getAnswerDocuments(index)?.value[documentId];
    return document && document.presendedFormType === form;
  }

  handleDocumentPresendedFormClick(
    index: number,
    documentId: number,
    form: PresendedFormEnum
  ): void {
    const document = this.getAnswerDocuments(index)?.value[documentId];
    if (document) {
      document.presendedFormType = form;
    }
  }

  presendedPackString(en: string) {
    const string =
      en === 'Original'
        ? 'Оригинал'
        : en === 'Scan'
          ? 'Скан'
          : en === 'NotIncluded'
            ? 'Нет'
            : '';
    return string;
  }

  isEqualDocumentPresendedPack(
    index: number,
    documentId: number,
    pack: PresendedPackEnum
  ): boolean {
    const document = this.getAnswerDocuments(index)?.value[documentId];
    return document && document.presendedPackType === pack;
  }

  handleDocumentPresendedPackClick(
    index: number,
    documentId: number,
    pack: PresendedPackEnum
  ): void {
    const document = this.getAnswerDocuments(index)?.value[documentId];
    if (document) {
      document.presendedPackType = pack;
    }
  }

  handleCancelForm(){
    this.modalService.close();
    this.blockEditForm.reset();
    this.blockAnswers.clear();
    this.dragMode = true;
    this.showEditBlockError = false;
  }

  handleSubmitForm() {
    if(this.blockEditForm.invalid || this.blockAnswersForm.invalid){
      this.showEditBlockError = true;
      return;
    }

    this.showEditBlockError = false;

    if(this.isNewBlock){
      this.handleCreateBlock();
    } else {
      this.handleUpdateBlock();
    }

    this.modalService.close();
    this.blockEditForm.reset();
    this.blockAnswers.clear();
    this.dragMode = true;
  }

  handleCreateBlock(){
    this.isEdited = true;
    const newItem = this.createOrgItem(this.editBlockType, this.blockEditForm.value.title!, this.blockEditForm.value.note!);
    if(!this.rootItem){
      this.rootItem = newItem;
    } else if(this.selectedItem){
      this.setChildItem(this.selectedItem, newItem);
    }

    if(this.editBlockType === ServiceBlockTypes.Intermediate){
      this.blockAnswers.value.forEach((answer) => {
        const answerItem = this.createOrgItem(ServiceBlockTypes.Answer, answer.name!, "");
        answerItem.documents = answer.documents as any;
        this.setChildItem(newItem, answerItem);
      });
    }

    this.updateChart();
  }

  handleUpdateBlock() {
    if(this.selectedItem){
      this.isEdited = true;

      this.selectedItem.name = this.blockEditForm.value.title as string;
      this.selectedItem.note = this.blockEditForm.value.note as string;

      if(this.editBlockType === ServiceBlockTypes.Intermediate){
        // Remove deleted answers
        const answersToRemove = [...this.selectedItem.childrens!].filter((answer) => !this.blockAnswers.value.find((formAnswer) => formAnswer.id === answer.id));
        answersToRemove.forEach((answer) => this.deleteOrgItem(answer));

        this.selectedItem?.childrens?.clear();
        this.blockAnswers.controls.forEach(({ value: formAnswer }) => {
          if(formAnswer.id){
            // Update existed answer
            const existedItem = this.orgItems.get(formAnswer.id);
            if(existedItem){
              existedItem.name = formAnswer.name as any;
              existedItem.documents = formAnswer.documents as any;
              this.setChildItem(this.selectedItem!, existedItem);
            }
          } else {
            // Add new answer
            const newItem = this.createOrgItem(ServiceBlockTypes.Answer, formAnswer.name as any);
            newItem.documents = formAnswer.documents as any;
            this.setChildItem(this.selectedItem!, newItem);
          }
        });
      }

      this.updateChart();
    }
  }

  handleDeleteBlock() {
    if(this.selectedItem && confirm("Вы действительно хотите удалить этот блок?")){
      this.isEdited = true;
      if (this.rootItem === this.selectedItem) {
        this.rootItem = null;
        this.orgItems.clear();
      } else {
        this.deleteOrgItem(this.selectedItem);
      }
      this.selectedItem = null;
      this.updateChart();
    }
  }

  createOrgItem(type: ServiceBlockTypes, name: string, note: string = ''){
    const newItem: OrgItem = {
      type,
      name,
      note,
      level: 1,
      parents: new Set(),
      childrens: new Set(),
      id: uuid.v4(),
    };
    this.orgItems.set(newItem.id, newItem);
    return newItem;

  }

  setChildItem(parent: OrgItem, child: OrgItem){
    child.parents?.add(parent);
    this.updateItemLevel(child)
    parent.childrens?.add(child);
    return parent;
  }

  removeChildItem(parent: OrgItem, child: OrgItem){
    child.parents?.delete(parent);
    if(child.parents?.size){
      this.updateItemLevel(child);
    } else {
      this.deleteOrgItem(child);
    }
    parent.childrens?.delete(child);
    return parent;
  }

  updateItemLevel(item: OrgItem){
    item.level = 1;
    item.parents?.forEach(parent => item.level = Math.max(item.level, parent.level + 1));
    item.childrens?.forEach(child => this.updateItemLevel(child));
    return item;
  }

  deleteOrgItem(orgItem: OrgItem){
    orgItem.parents?.forEach((parent) => parent.childrens?.delete(orgItem));
    orgItem.childrens?.forEach((child) => this.deleteOrgItem(child));
    this.orgItems.delete(orgItem.id);
  }

  searchTemplatesToClone(name: string): void {
    this.templatesService
      .getTemplates({
        name,
        from: 0,
        to: 50,
        isShowBlocks: true,
        isNotEmpty: true,
      })
      .subscribe({
        next: (templates: TemplatesList) => {
          this.templatesToClone = templates.active;
        },
        error: (err: Error) => console.error(err),
      });
  }
  
  getTemplateName(template: Template){
    return template.name
  }

  handleSelectTemplate(template: Template): void {
    this.isEdited = true;

    const newItem = this.createOrgItem(ServiceBlockTypes.Template, template.name, "");
    newItem.templateId = template.id;

    if(!this.rootItem){
      this.rootItem = newItem;
    } else if (this.selectedItem){
      this.setChildItem(this.selectedItem, newItem);
    }

    this.updateChart();

    this.modalService.close();
  }

  setPossibleChildBlocks(){
    if(this.selectedItem){
      const forbiddenQuery = [this.selectedItem];
      const forbiddenItems = new Set<OrgItem>();
      while(forbiddenQuery.length){
        const item = forbiddenQuery.shift()!;
        if(!forbiddenItems.has(item)){
          forbiddenItems.add(item);
          forbiddenQuery.push(...item.parents!);
        }
      }
      this.possibleChildBlocks = [...this.orgItems.values()].filter(
        (item) => item.type !== ServiceBlockTypes.Answer && !forbiddenItems.has(item)
      );
    }
  }

  openAddChildForm(){
    this.showAddBar = false;

    if(this.selectedItem){
      this.modalService.open('add-child-modal');
    }
  }

  handleSelectChild(item: OrgItem){
    this.modalService.close();
    if(this.selectedItem){
      this.isEdited = true;
      this.setChildItem(this.selectedItem, item);
      this.updateChart();
    }
  }

  handleRemoveChilds(){
    this.menuOpened = false;
    if(this.selectedItem){
      this.isEdited = true;
      this.selectedItem.childrens?.forEach((child) => {
        this.removeChildItem(this.selectedItem!, child);
      });
      this.updateChart();
    }
  }

  onMenuClick() {
    this.modalService.open('about-modal');
  }

  onSaveTemplateClick() {
    this.modalService.open('save-modal');
  }

  onSaveClick(): void {
    switch (this.type) {
      case 'service':
        return this.onSaveService();
      case 'template':
        return this.onSaveTemplate();
    }
  }

  onSaveTemplate(): void {
    this.templatesService
      .createTemplateBlocks(
        this.template.id,
        this.createBlockDto as CreateTemplateBlockDto[]
      )
      .subscribe({
        next: () => {
          this.isEdited = false;
          this.alertService.toggle('Изменения сохранены');
        },
      });
  }

  onSaveService(): void {
    this.servicesService
      .createServiceBlocks(
        this.service.id,
        this.createBlockDto as CreateServiceBlockDto[]
      )
      .subscribe({
        next: () => {
          this.isEdited = false;
          this.alertService.toggle('Изменения сохранены');
        },
      });
  }

  handleSaveButtonClick() {
    if (this.createName.value.length < 5) {
      this.createTemplateError = 'Название шаблона не может быть короче 5 символов';
      return;
    }
    this.templatesService
      .createTemplate(this.createTemplateForm.value as CreateTemplateDto)
      .subscribe({
        next: template => {
          this.template = template;
          this.onSaveTemplate();
          this.templatesService.createTemplate(template);
          this.alertService.toggle('Шаблон создан');
          void this.router.navigate(['/templates']);
          this.modalService.close();
        },
        error: error => console.error(error),
      });
  }

  resetErrors() {
    this.createTemplateError = '';
  }

  onExit(){
    if(!this.isEdited || confirm("Вы действительно хотите выйти без сохранения? Данные будут утеряны.")){
      this.router.navigateByUrl(this.redirectUri);
    }
  }

  searchDocumentTemplates(name: string){
    this.docSampleService.getDocSamples({ name })
      .subscribe((templatesList) => {
        this.suggestedDocumentTemplates = templatesList.active.data;
      });
  }

  getDocumentTemplateTitle(template: DocumentTemplate){
    return template.title;
  }

  selectDocumentTemplate(template: DocumentTemplate, formDocument: FormGroup<FormDocument>){
    formDocument.controls.documentTemplateId.setValue(template.id);
    formDocument.controls.title.setValue(template.title);
  }

}
