<div class="classifiers-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">Классификаторы</h1>
  </div>
</div>

<div class="classifiers">
  @for (type of classifiersTypes; track type.key) {
    <article class="classifier">
      <div class="classifier__row">
        <h3>
          {{ type.name }}
          <div class="classifier__badge">
            <app-badge
              size="normal"
              display="tint"
              color="secondary-container"
              size_text="default"
              badge_content="{{ classifiers()[type.key].total }}"></app-badge>
          </div>
        </h3>
        <div class="classifier__buttons">
          <app-button
            size="normal"
            type="left"
            icon="add_circle"
            [fill]="true"
            display="fill"
            color="primary"
            sizeText="default"
            buttonContent="Добавить"
            (click)="openAddModal(type)"
          >
          </app-button>
          @if (classifiers()[type.key].data.length) {
            @if (type.opened) {
              <app-icon-button
                size="normal"
                type="square"
                icon="keyboard_arrow_up"
                display="fill"
                color="container-lowest"
                (click)="toggleOpened(type.key)">
              </app-icon-button>
            } @else {
              <app-icon-button
                size="normal"
                type="square"
                icon="keyboard_arrow_down"
                display="fill"
                color="container-lowest"
                (click)="toggleOpened(type.key)">
              </app-icon-button>
            }
          }
        </div>
      </div>
      @if (type.opened && classifiers()[type.key].data.length) {
        <div
          cdkDropList
          class="dropdown"
          (cdkDropListDropped)="drop(type.key, $event)"
          style="margin-top: 20px;">
          @for (cont of classifiers()[type.key].data; track cont.id) {
            <div
              class="classifier__row border_top"
              cdkDrag
              cdkDragLockAxis="y"
              cdkDragBoundary=".dropdown">
              <div class="classifier__row">
                <div class="classifier__list">
                  <div class="classifier__list_element">
                    <img
                      ngSrc="../../assets/icons/list-icon.svg"
                      alt="list"
                      height="14"
                      width="18" />
                    <p class="classifier__text">
                      {{ cont.name }}
                      @if (
                        type.key === ClassifiersEnum.departments &&
                        getSubdivisionByKey(type.key, cont.id)
                        ) {
                        <span>({{ getSubdivisionByKey(type.key, cont.id).name }})</span>
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div class="classifier__row">
                <div class="classifier__icons_list">
                  <app-icon-button
                    size="small"
                    type="circle"
                    icon="cancel"
                    display="outline"
                    color="container-lowest"
                    (click)="onDeleteClick(cont.id, type.key, cont.name)"
                  ></app-icon-button>
                  <app-icon-button
                    size="small"
                    type="circle"
                    icon="edit_square"
                    display="outline"
                    color="container-lowest"
                    (click)="
                      openEditModal(type, cont)
                    "></app-icon-button>
                </div>
              </div>
            </div>
          }
        </div>
        @if (classifiers()[type.key].total > classifiers()[type.key].data.length) {
          <app-button
            class="classifier__show-more"
            size="normal"
            type="left"
            icon="add_circle"
            [fill]="true"
            display="fill"
            color="neutral-40"
            sizeText="default"
            buttonContent="Показать больше"
            (click)="showMoreClick(type)">
          </app-button>
        }
      }
    </article>
  }
</div>
