import { ChangeDetectionStrategy, Component, input, signal, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'core-checkbox',
  standalone: true,
  imports: [
    FormsModule,
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements ControlValueAccessor {
  checked = signal<boolean>(false);
  title = input.required<string>();

  onChange = (value: boolean) => {};
  onTouched = () => {};

  registerOnChange(onChanged: any) {
    this.onChange = onChanged;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue(value: boolean) {
    this.checked.set(value);
  }

  onModelChange(value: boolean) {
    this.checked.set(value);
    this.onChange(this.checked());
  }
}
