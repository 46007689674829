import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, TextInputComponent } from '@core/components';
import {
  DocTransferMethodModal
} from '@scripter-admin/app/modals/docs-transfer-method-modal/doc-transfer-method-modal';
import { AlertService, ResultsTransferMethodsService } from '@core/services';
import { CreateDocumentTransferMethodDto } from '@core/models/dto';
import { DocumentTransferMethod } from '@core/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-results-transfer-method-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './add-results-transfer-method-modal.component.html',
  styleUrl: './add-results-transfer-method-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddResultsTransferMethodModalComponent extends DocTransferMethodModal {
  protected readonly resultsTransferMethodsService = inject(ResultsTransferMethodsService);

  formGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  formSubmit() {
    this.onFormSubmit({
      ...this.formGroup.value,
    } as CreateDocumentTransferMethodDto);
  }

  onFormSubmit(requestData: CreateDocumentTransferMethodDto) {
    if (!this.checkErrors()) return;

    this.resultsTransferMethodsService.createResultsTransferMethod(requestData).subscribe({
      next: (method: DocumentTransferMethod) => {
        this.alertService.toggle('Способ получения добавлен');
        this.submit.emit(method);
        this.close.emit();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    })
  }
}
