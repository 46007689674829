<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Новый классификатор «{{ title() }}»
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form area_white_bg"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
      <fieldset class="container-login__fieldset">
        <h5 class="title-bold-h5" style="margin-bottom: 0; margin-top: 30px">
          Название классификатора
        </h5>
        <core-text-input
          class="login-input"
          placeholder="Введите название"
          formControlName="name"
          [error]="nameError()"
          (click)="resetInputError()"
        ></core-text-input>
      </fieldset>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Добавить
      </button>
    </div>
  </form>
</div>
