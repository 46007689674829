<div class="wrapper"
     [style.height.px]="height()"
>
  <input type="date"
         class="input on-container text-big"
         [disabled]="disabled"
         [ngModel]="value()"
         (ngModelChange)="onInput($event)"
         (click)="handleClick($event)"
  >
</div>
@if (error()) {
  <p class="tooltip text-small on-container-lowest primary-color">{{ error() }}</p>
}
