<div class="services-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">Услуги</h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="inverse"
        color="inverse-container"
        sizeText="default"
        buttonContent="Создать из услуги"
        (click)="openCloneModal()"
      ></app-button>
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="openAddModal()"
      ></app-button>
    </div>
  </div>
  @if (isFiltered) {
    <div class="search-zone-with-filter">
      <div class="search-zone-with-filter__button">
        <app-button
          size="normal"
          type="left"
          icon="page_info"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          buttonContent="Фильтры"
          (click)="openFilterModal()"
        ></app-button>
      </div>
      <div class="search-zone-with-filter__button">
        <app-button
          size="normal"
          type="left"
          icon="delete_forever"
          [fill]="true"
          display="inverse"
          color="primary"
          sizeText="default"
          buttonContent="Сбросить"
          (click)="handleFiltersResetClick()"
        ></app-button>
      </div>
      <div class="search-zone-with-filter__input area_white_bg">
        <core-text-input
          placeholder="Поиск услуги"
          leftIcon="search"
          [formControl]="searchForm.controls.name"
        ></core-text-input>
      </div>
    </div>
  } @else {
    <div class="search-zone">
      <div class="search-zone__button">
        <app-button
          size="normal"
          type="left"
          icon="page_info"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          buttonContent="Фильтры"
          (click)="openFilterModal()"
        ></app-button>
      </div>
      <div class="search-zone__input area_white_bg">
        <core-text-input
          placeholder="Поиск услуги"
          leftIcon="search"
          [formControl]="searchForm.controls.name"
        ></core-text-input>
      </div>
    </div>
  }
  <div class="tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!-- <div class="tabs-and-filter-dropdown">
      @if (!isOrderOpened) {
        <button class="filter" (click)="handleChangeOrderClick()">
          {{ selectedOrder.name }}
          <span class="material-symbols-outlined"> keyboard_arrow_down </span>
        </button>
      } @else {
        <button class="filter" (click)="handleChangeOrderClick(selectedOrder)">
          {{ selectedOrder.name }}
          <span class="material-symbols-outlined"> keyboard_arrow_up </span>
        </button>
        <div class="tabs-and-filter-dropdown-content">
          @for (order of orders; track order) {
            <button class="filter" (click)="handleChangeOrderClick(order)">
              {{ order.name }}
            </button>
          }
        </div>
      }
    </div> -->
  </div>
</div>
<div class="services">
  @switch (currentTab()) {
    @case ('tabs__archive') {
      <app-services-page-list
        [services]="archivedServices()"
        (edit)="openEditModal($event)"
      ></app-services-page-list>
    }
    @case ('tabs__actual') {
      <app-services-page-list
        [services]="services()"
        (edit)="openEditModal($event)"
      ></app-services-page-list>
    }
    @case ('tabs__need-attention') {
      <app-attention [attentionServices]="needAttention()"></app-attention>
    }
  }
</div>
