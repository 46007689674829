import { NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type SelectValueType = any;

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements ControlValueAccessor {
  value = signal<SelectValueType | undefined>(undefined);

  registerOnChange(onChanged: any) {
    this.onChange = onChanged;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onChange = (value: SelectValueType) => {};
  onTouched = () => {};

  writeValue(value: SelectValueType) {
    this.value.set(value);
  }

  options = input.required<{value: SelectValueType, label: string}[]>();
  public isOpen = signal(false);
  placeholder = input<string>();
  type = input.required<'normal' | 'list' | 'picked-list'>();
  bg = input<'normal' | 'white'>('normal');
  error = input<string>('');

  public handleSelect(option: any) {
    this.value.set(option.value);
    this.onChange(this.value());
    this.isOpen.set(false);
  }

  get activeLabel() {
    return this.options().find(x => x.value === this.value())?.label;
  }
}
