<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Скопировать из услуги
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
      <div class="modal" [ngClass]="{ 'clone-modal': !serviceToClone() }">
        @if (!serviceToClone()) {
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            Выберите услугу
          </h5>
          <core-text-input
            placeholder="Поиск услуги"
            leftIcon="search"
            [formControl]="cloneSearchForm.controls.name"
          ></core-text-input>
          <ol class="clone-list">
            @for (service of servicesToClone; track service) {
              <li
                class="clone-list-element"
                (click)="handleSelectCloneServiceClick(service)"
              >
                {{ service.name }}
              </li>
            }
          </ol>
        } @else {
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            1. Общая информация
          </h5>
          <div class="modal__general csm_container area_beige_bg">
            <p class="modal__input-title">Полное наименование услуги</p>
            <div class="modal__search_general modal__search_general_multiline">
              <core-textarea-input
                placeholder="Полное наименование услуги"
                formControlName="name"
                (click)="resetInputError()"
                [error]="nameError()"
              ></core-textarea-input>
            </div>
            <p class="modal__input-title">Краткое наименование</p>
            <div class="modal__search_general">
              <core-text-input
                placeholder="Краткое наименование"
                formControlName="title"
                (click)="resetInputError()"
                [error]="titleError()"
              ></core-text-input>
            </div>
            <p class="modal__input-title">Номер СИЭР</p>
            <div class="modal__search_general">
              <core-text-input
                placeholder="Номер СИЭР"
                formControlName="sierNumber"
                (click)="resetInputError()"
                [error]="sierNumberError()"
              ></core-text-input>
            </div>
            <div class="modal__general-period">
              <p class="modal__input-title title-grid1">Дата начала активности</p>
              <core-date-input
                class="input-grid1"
                formControlName="activityStart"
              ></core-date-input>
              <p class="modal__input-title title-grid2">
                Дата окончания активности
              </p>
              <core-date-input
                class="input-grid2"
                formControlName="activityEnd"
              ></core-date-input>
            </div>
            <p class="modal__input-title">Принцип предоставления</p>
            <div class="modal__general-service-types">
              <div
                [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Federal
              }"
                (click)="
                handleChangeServiceTypeClick(ServicesTypesEnum.Federal)
              ">
                Федеральный
              </div>
              <div
                [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Regional
              }"
                (click)="
                handleChangeServiceTypeClick(ServicesTypesEnum.Regional)
              ">
                Региональный
              </div>
              <div
                [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Municipal
              }"
                (click)="
                handleChangeServiceTypeClick(ServicesTypesEnum.Municipal)
              ">
                Муниципальный
              </div>
            </div>
            <div class="modal__search_general">
              <app-input
                type="checkbox"
                label="Не предоставляется для ТОСП"
                [control]="formGroup.controls.isNotForTosp"
                (click)="resetInputError()"
                labelVersion="regular"
              ></app-input>
            </div>
            <div class="modal__search_general">
              <app-input
                type="checkbox"
                label="Не предоставляется для УРМ"
                [control]="formGroup.controls.isNotForUrm"
                (click)="resetInputError()"
                labelVersion="regular"
              ></app-input>
            </div>

            <p class="modal__input-title">Способы передачи документов</p>

            @if (selectedDocumentsTransferMethods().length) {
              <div class="badges">
                @for (transferMethod of selectedDocumentsTransferMethods(); track transferMethod) {
                  <div class="badge">
                    <app-badge
                      badge_content="{{ transferMethod.name }}"
                      size="normal"
                      display="fill"
                      color="inverse-container"
                      size_text="default"
                      right_icon="cancel"
                      [fill]="true"
                      [used_right_icon]="true"
                      (cancelClickDetection)="removeDocumentsTransferMethod(transferMethod)"
                    ></app-badge>
                  </div>
                }
              </div>
            }

            <div class="transfers-selector">
              <app-autocomplete
                placeholder="Найти cпособ передачи..."
                [suggestions]="suggestedDocumentsTransferMethods()"
                [getName]="getTransferMethodName"
                (inputValueChange)="searchDocumentsTransferMethods($event)"
                (selected)="addDocumentsTransferMethod($event)"
              ></app-autocomplete>
            </div>

            <p class="modal__input-title">Способ получения результата</p>

            @if (selectedResultsTransferMethod()) {
              <div class="badges">
                  <div class="badge">
                    <app-badge
                      badge_content="{{ selectedResultsTransferMethod()?.name }}"
                      size="normal"
                      display="fill"
                      color="inverse-container"
                      size_text="default"
                      right_icon="cancel"
                      [fill]="true"
                      [used_right_icon]="true"
                      (cancelClickDetection)="selectResultsTransferMethod(null)"
                    ></app-badge>
                  </div>
              </div>
            } @else {
              <div class="transfers-selector">
                <app-autocomplete
                  placeholder="Найти cпособ получения..."
                  [suggestions]="suggestedResultsTransferMethods()"
                  [getName]="getTransferMethodName"
                  (inputValueChange)="searchResultsTransferMethods($event)"
                  (selected)="selectResultsTransferMethod($event)"
                ></app-autocomplete>
              </div>
            }
          </div>

          <div class="modal__relations">
            <h5
              class="title-bold-h5 modal__title modal__title_info modal__title_relations">
              2. Связи с документами
            </h5>
            <div class="modal__relations-select-container">
              <app-autocomplete
                [fill]="true"
                placeholder="Выбрать документы для связи"
                [suggestions]="suggestionDocuments"
                [getName]="getDocumentName"
                (inputValueChange)="searchDocuments($event)"
                (selected)="addDocument($event)"
              ></app-autocomplete>

              @if (selectedDocuments.length) {
                <div class="modal__relations-list">
                  <app-selected-documents
                    [items]="selectedDocuments"
                    (remove)="removeDocument($event)"
                  ></app-selected-documents>
                </div>
              }
            </div>
          </div>

          <div class="modal__classifiers">
            <h5 class="title-bold-h5 modal__title modal__title_info">
              3. Классификаторы
            </h5>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Жизненные ситуации
              </h5>

              @if (selectedClassifiers.get('lifeSituations').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('lifeSituations'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('lifeSituations', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['lifeSituations']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('lifeSituations', $event)"
                  (selected)="addClassifier('lifeSituations', $event)"
                ></app-autocomplete>
              </div>
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Категория заявителей
              </h5>

              @if (selectedClassifiers.get('applicantCategories').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('applicantCategories'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('applicantCategories', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['applicantCategories']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('applicantCategories', $event)"
                  (selected)="addClassifier('applicantCategories', $event)"
                ></app-autocomplete>
              </div>
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Подразделения МФЦ
              </h5>

              @if (selectedClassifiers.get('departments').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('departments'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('departments', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['departments']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('departments', $event)"
                  (selected)="addClassifier('departments', $event)"
                ></app-autocomplete>
              </div>
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Ведомства
              </h5>

              @if (selectedClassifiers.get('divisions').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('divisions'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('divisions', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              } @else {
                <div class="classifiers-selector">
                  <app-autocomplete
                    placeholder="Найти классификатор..."
                    [suggestions]="suggestionClassifiers['divisions']"
                    [getName]="getClassifierName"
                    (inputValueChange)="searchClassifiers('divisions', $event)"
                    (selected)="addClassifier('divisions', $event)"
                  ></app-autocomplete>
                </div>
              }
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Территориальность
              </h5>

              @if (selectedClassifiers.get('territoriality').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('territoriality'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('territoriality', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['territoriality']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('territoriality', $event)"
                  (selected)="addClassifier('territoriality', $event)"
                ></app-autocomplete>
              </div>
            </div>
          </div>

          <div class="element">
            <app-button
              size="small"
              type="left"
              display="fill"
              color="outline-low"
              icon="arrow_left_alt"
              sizeText="default"
              buttonContent="Выбрать другую услугу"
              (click)="handleResetCloneServiceClick()">
            </app-button>
          </div>
        }
      </div>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Копировать
      </button>
    </div>
  </form>
</div>
