<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Изменить роль
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form area_white_bg"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
      <div class="add-role-input">
        <p class="text-big">Название роли</p>
        <core-text-input
          placeholder="Введите название"
          formControlName="name"
          (click)="resetInputError()"
          [error]="nameError()"
        ></core-text-input>
      </div>
      <div class="add-role-permissions">
        @for (permission of permissions(); track permission; let i = $index) {
          <div class="add-role-permissions__element">
            <div class="title">
              {{ permission.description }}
            </div>
            <div class="selector">
              @if (!hasSelectedPermission(permission.id)) {
                <span class="selected" (click)="onSelectClick(permission)">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95801 11L0.208008 6.25002L1.39551 5.06252L4.95801 8.62502L12.6038 0.979187L13.7913 2.16669L4.95801 11Z"
                    fill="#D8402D" />
                </svg>
                Нет доступа
              </span>
              } @else {
                <span (click)="onSelectClick(permission)">Нет доступа</span>
              }
              @if (hasSelectedPermission(permission.id) === AccessEnum.VIEW) {
                <span class="selected" (click)="onSelectClick(permission, AccessEnum.VIEW)">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95801 11L0.208008 6.25002L1.39551 5.06252L4.95801 8.62502L12.6038 0.979187L13.7913 2.16669L4.95801 11Z"
                    fill="#D8402D" />
                </svg>
                Просмотр
                  <!-- 3 галочка НЕТ-->
              </span>
              } @else {
                <span (click)="onSelectClick(permission, AccessEnum.VIEW)"
                >Просмотр</span
                >
              }
              @if (hasSelectedPermission(permission.id) === AccessEnum.FULL) {
                <span class="selected" (click)="onSelectClick(permission, AccessEnum.FULL)">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95801 11L0.208008 6.25002L1.39551 5.06252L4.95801 8.62502L12.6038 0.979187L13.7913 2.16669L4.95801 11Z"
                    fill="#D8402D" />
                </svg>
                Управление
              </span>
              } @else {
                <span (click)="onSelectClick(permission, AccessEnum.FULL)">Управление</span>
              }
            </div>
          </div>
        }
      </div>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Изменить
      </button>
    </div>
  </form>
</div>
