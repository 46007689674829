import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, input, signal } from '@angular/core';
import { RegDocModal } from '@scripter-admin/app/modals/reg-docs-modal/reg-doc-modal';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { AlertComponent, BadgeComponent, DateInputComponent, TextInputComponent } from '@core/components';
import { SelectedDocumentsComponent } from '@core/components/selected-documents/selected-documents.component';
import { NgClass, UpperCasePipe } from '@angular/common';
import { Document, DocumentsList } from '@core/models';
import { CreateRegDocDto } from '@core/models/dto';
import { ParseDataToFormHelper } from '@core/utils';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-add-reg-doc-modal',
  standalone: true,
  imports: [
    AutocompleteComponent,
    BadgeComponent,
    DateInputComponent,
    ReactiveFormsModule,
    SelectedDocumentsComponent,
    TextInputComponent,
    UpperCasePipe,
    NgClass,
  ],
  templateUrl: './add-reg-doc-modal.component.html',
  styleUrl: './add-reg-doc-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRegDocModalComponent extends RegDocModal implements OnInit {
  private regDocs = input.required<Document[]>();
  formGroup = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    activityStart: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    activityEnd: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    })
  });
  suggestionDocuments = signal<Document[]>([]);
  selectedDocuments = signal<Document[]>([]);
  public dateStartError = signal<string>('');
  public dateEndError = signal<string>('');
  public fileUploadError = signal<string>('');

  ngOnInit() {
    this.searchDocuments('');
  }

  formSubmit() {
    const values = this.formGroup.value;
    const requestData = {
      title: values.title,
      note: '',
      activityStart: values.activityStart
        ? new Date(values.activityStart).toISOString()
        : null,
      activityEnd: values.activityEnd
        ? new Date(values.activityEnd).toISOString()
        : null,
      documentsIds: this.selectedDocuments().map((doc: Document) => doc.id),
    }

    this.onFormSubmit(requestData)
  }

  onFormSubmit(requestData: CreateRegDocDto) {
    if (!this.checkErrors()) return;

    const form = ParseDataToFormHelper(
      requestData,
      this.files()
    );

    this.regDocsService.createRegDoc(form).subscribe({
      next: (document: Document) => {
        this.alertService.toggle('Документ добавлен');
        this.submit.emit(document);
        this.close.emit();
      },
      error: (error: Error) => {
        console.log(error);
      },
    })
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files: File[] = Array.from(event.dataTransfer.files);

      for (const file of files) {
        if (!this.files().some((e: File) => e.name === file.name)) {
          this.files().push(file);
        }
      }
    }
  }

  public onFileSelected(event: any): void {
    const files: File[] = event.target.files;

    for (const file of files) {
      if (!this.files().some((e: File) => e.name === file.name)) {
        this.files().push(file);
      }
    }

    event.target.value = '';
  }

  public removeFile(index: number): void {
    this.files().splice(index, 1);
  }

  getDocumentName(doc: Document){
    return doc.title;
  }

  addDocument(doc: Document){
    if (this.selectedDocuments().indexOf(doc) < 0) {
      this.selectedDocuments.update(docs => [...docs, doc]);
      this.suggestionDocuments.update(docs => docs.filter(d => d != doc));
    }
  }

  searchDocuments(name: string){
    this.regDocsService
      .getRegDocs({
        name,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          const selectedIds = this.selectedDocuments().map((document: Document) => document.id);
          const suggestions = documents.active.data.filter((doc) => !selectedIds.includes(doc.id));
          this.suggestionDocuments.set(suggestions);
        },
        error: (err: Error) => console.error(err),
      });
  }

  removeDocument(doc: Document){
    if (this.selectedDocuments().indexOf(doc) >= 0) {
      this.selectedDocuments.update(docs => docs.filter(d => d != doc));
      this.suggestionDocuments.update(docs => [...docs, doc]);
    }
  }

  protected override checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (!values.activityStart) {
      errors['activityStart'] = 'Документ должен иметь дату начала действия';
    }
    if (!values.activityEnd) {
      errors['activityEnd'] = 'Документ должен иметь дату окончания действия';
    }
    if (!this.files().length) {
      errors['fileUploadError'] = 'Список вложений не может быть пустым'
    }

    this.dateStartError.set(errors['activityStart']);
    this.dateEndError.set(errors['activityEnd']);
    this.fileUploadError.set(errors['fileUploadError']);

    return super.checkErrors() && !Object.keys(errors).length;
  }

  override resetInputError() {
    super.resetInputError();
    this.dateStartError.set('');
    this.dateEndError.set('');
    this.fileUploadError.set('');
  }
}
