<div class="top-menu">
  <div class="top-menu__buttons">
    <div class="top-menu__buttons-logo">
      <img
        src="assets/spravka-menu.svg"
        alt="logo"
        (click)="handleLogoClick()" />
    </div>
    <div class="top-menu__buttons-list">
      <div class="top-menu__department">
        <button class="top-menu__department-select" (click)="openEditModal()">
          <div class="text">
            @if (user && user.usersDepartments) {
              {{user.usersDepartments.department.name}}
            }
            @else {
              Выбрать подразделение
            }
          </div>
        </button>

        @if (user && user.usersDepartments) {
          <button class="top-menu__department-clear" (click)="handleClearDepartment()">
            <span class="material-symbols-outlined"> cancel </span>
          </button>
        }
      </div>
    </div>
  </div>
  <div class="top-menu__search">
    <app-search
      class="top-menu__search-input"
      borderRadius="large"
      placeholder="Поиск"
      [control]="searchName"
      (changeEvent)="handleSearch()"
      (clearEvent)="handleClear()"
    ></app-search>
    @if (isSearched) {
      <div class="top-menu__search-results">
        <app-search-block [services]="searchServices"></app-search-block>
      </div>
    }
  </div>
  <div class="top-menu__user">
    <p class="top-menu__name subtitle-default">
      {{ name }}
      <span class="material-symbols-outlined" (click)="handleLogout()">
        logout
      </span>
    </p>
  </div>
</div>
