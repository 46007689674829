<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Изменить регламентирующий документ
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form reg-docs-form"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
        <fieldset class="reg-docs-form__information" style="border-bottom: none">
          <h5 class="reg-docs-form__information-text title-bold-h5">
            1. Общая информация
          </h5>
          <div class="main-info area_beige_bg">
            <p class="main-info__title on-container subtitle-default">
              Название документа
            </p>
            <div class="main-info__search">
              <core-text-input
                placeholder="Полное наименование документа"
                formControlName="title"
                (click)="resetInputError()"
                [error]="titleError()"
              ></core-text-input>
            </div>
          </div>
        </fieldset>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Изменить
      </button>
    </div>
  </form>
</div>
