<button
  type="button"
  [tabindex]="tabindex()"
  [class]="size() + ' ' + display() + ' ' + color()"
  [ngClass]="{
    'outline': display() === 'outline',
    'circle': type() === 'circle',
    'square': type() === 'square'
  }"
>
  <span
    class="material-symbols-outlined"
    [ngClass]="{
      'material-symbols-filled': fill()
    }"
  >
    {{ icon() }}
  </span>
</button>
