import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AddClassifierModal } from '@scripter-admin/app/modals/classifiers-modal/add-classifier-modal';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, TextInputComponent } from '@core/components';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-add-simple-classifier-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './add-simple-classifier-modal.component.html',
  styleUrl: './add-simple-classifier-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSimpleClassifierModalComponent extends AddClassifierModal {
  formGroup = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  });

  formSubmit() {
    this.onFormSubmit({
      name: this.formGroup.value.name!,
      type: this.type().key
    });
  }
}
