import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject, input, OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { getSubdivisionTypeTranslationsHelper } from '@core/utils';
import { SearchComponent } from '@core/components';
import { Subdivision, User } from '@core/models';
import { SubdivisionsService, UserService } from '@core/services';

@Component({
  selector: 'app-client-top-menu-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SearchComponent,
  ],
  templateUrl: './client-top-menu-modal.component.html',
  styleUrl: './client-top-menu-modal.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientTopMenuModalComponent implements OnInit {
  private readonly subdivisionsService = inject(SubdivisionsService);
  private readonly changeDetector = inject(ChangeDetectorRef);
  formGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });
  close = new EventEmitter<void>();
  submit = new EventEmitter<Subdivision>();
  subdivisions: Subdivision[] = [];

  ngOnInit() {
    this.handleSubdivisionsSearch();
  }

  public handleSubdivisionsSearch(): void {
    this.subdivisionsService
      .getSubdivisions({
        name: this.formGroup.controls.name.value!,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (subdivisions: Subdivision[]) => {
          this.subdivisions = subdivisions;
          this.changeDetector.detectChanges();
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
  }

  public handleSelectSubdivisionClick(subdivision: Subdivision): void {
    this.submit.emit(subdivision);
    this.closeModal();
  }

  closeModal() {
    this.close.emit();
  }

  protected readonly getSubdivisionTypeTranslation = getSubdivisionTypeTranslationsHelper;
}
