<div class="transfer-methods-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">
      Способы передачи документов
      <span class="top-div__badge">
        <app-badge
          size="normal"
          display="tint"
          color="secondary-container"
          size_text="default"
          badge_content="{{ documentsTransferMethods().length }}"></app-badge>
      </span>
    </h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="openAddModal()"
      ></app-button>
    </div>
  </div>
  <div class="search-zone area_white_bg">
    <div class="search-zone__input">
      <core-text-input
        placeholder="Поиск способов передачи документов"
        leftIcon="search"
        [formControl]="name"></core-text-input>
    </div>
  </div>
</div>

<div class="transfer-methods">
  @if (documentsTransferMethods().length === 0) {
    <p class="text-bigger">Нет способов передачи</p>
  }
  @for (cont of documentsTransferMethods(); track cont.id) {
    <article class="subdivision">
      <div class="subdivision__row">
        <h3 class="subdivision__text text-big">{{ cont.name }}</h3>
        <div class="subdivision__buttons">
          <app-icon-button
            size="small"
            type="circle"
            icon="cancel"
            display="outline"
            color="outline-low"
            (click)="handleClickDeleteTransferMethod(cont.id, cont.name)"
          ></app-icon-button>
          <app-icon-button
            size="small"
            type="circle"
            icon="edit_square"
            display="outline"
            color="outline-low"
            (click)="openEditModal(cont)"
          ></app-icon-button>
        </div>
      </div>
    </article>
  }
</div>
