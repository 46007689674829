import {
  Component,
  Input,
  ViewEncapsulation,
  Inject,
  OnChanges,
} from '@angular/core';
import { DOCUMENT, NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-wysiwyg',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule],
  templateUrl: './wysiwyg.component.html',
  styleUrl: './wysiwyg.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
})
export class WysiwygComponent implements OnChanges {
  @Input() heading: string = '';
  @Input() control: FormControl = new FormControl();
  public value: string = this.control.value;

  constructor(@Inject(DOCUMENT) public document: Document) {}

  public bold: boolean = false;
  public italic: boolean = false;
  public underline: boolean = false;

  public expandMode: boolean = false;

  public isTextStyleVariantsOpen: boolean = false;
  public textStyleVariants: string[] = [
    'Обычный текст',
    'Заголовок 1',
    'Заголовок 2',
    'Заголовок 3',
    'Заголовок 4',
    'Заголовок 5',
    'Заголовок 6',
  ];
  public selectedStyleVariant: string = this.textStyleVariants[0];

  handleSelectTextStyle(variant: string) {
    this.handleFocus();
    switch (variant) {
      case 'Обычный текст':
        document.execCommand('formatBlock', false, 'p');
        break;
      case 'Заголовок 1':
        this.bold = true;
        document.execCommand('formatBlock', false, 'h1');
        break;
      case 'Заголовок 2':
        this.bold = true;
        document.execCommand('formatBlock', false, 'h2');
        break;
      case 'Заголовок 3':
        this.bold = true;
        document.execCommand('formatBlock', false, 'h3');
        break;
      case 'Заголовок 4':
        this.bold = true;
        document.execCommand('formatBlock', false, 'h4');
        break;
      case 'Заголовок 5':
        this.bold = true;
        document.execCommand('formatBlock', false, 'h5');
        break;
      case 'Заголовок 6':
        this.bold = true;
        document.execCommand('formatBlock', false, 'h6');
        break;
    }

    this.selectedStyleVariant = variant;

  }

  public isTextAlignVariantsOpen: boolean = false;
  public textAlignVariants: string[] = [
    'format_align_left',
    'format_align_center',
    'format_align_right',
    'format_align_justify',
  ];
  public selectedTextAlign: string = this.textAlignVariants[0];

  handleSelectTextAlign(variant: string) {
    this.handleFocus();
    this.selectedTextAlign = variant;
    this.isTextAlignVariantsOpen = false;

    switch (variant) {
      case 'format_align_left':
        this.document.execCommand('justifyLeft', false);
        break;
      case 'format_align_center':
        this.document.execCommand('justifyCenter', false);
        break;
      case 'format_align_right':
        this.document.execCommand('justifyRight', false);
        break;
      case 'format_align_justify':
        this.document.execCommand('justifyFull', false);
        break;
    }
  }

  toggleExpand() {
    this.expandMode = !this.expandMode;
    if (this.expandMode) {
      this.document.body.style.overflow = 'hidden';
    } else {
      this.document.body.style.overflow = 'auto';
    }
  }

  undo() {
    this.handleFocus();
    this.document.execCommand('undo', false);
  }

  redo() {
    this.handleFocus();
    this.document.execCommand('redo', false);
  }

  formatBold() {
    this.handleFocus();
    this.bold = !this.bold;
    this.document.execCommand('bold', false);
  }

  formatItalic() {
    this.handleFocus();
    this.italic = !this.italic;
    this.document.execCommand('italic', false);
  }

  formatUnderlined() {
    this.handleFocus();
    this.underline = !this.underline;
    this.document.execCommand('underline', false);
  }

  formatQuote() {
    const selected = this.document.getSelection();
    this.document.getSelection()?.toString() !== ''
      ? this.document.execCommand('insertText', false, '"' + selected + '"')
      : null;
  }

  formatListBulleted() {
    this.handleFocus();
    document.execCommand('insertUnorderedList', false);
  }

  formatListNumbered() {
    this.handleFocus();
    document.execCommand('insertOrderedList', false);
  }

  handleFocus() {
    this.document.getElementById('section')?.focus();
  }

  handleUnfocus() {
    this.value = this.control.value;
  }

  change(event: any): void {
    this.control.setValue(event.target.innerHTML);
  }

  ngOnChanges(): void {
    this.value = this.control.value;
  }

  handlePaste(event: ClipboardEvent){
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  }
}
