import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DocSampleModal } from '@scripter-admin/app/modals/docs-sample-modal/doc-sample-modal';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, BadgeComponent, TextInputComponent } from '@core/components';
import { UpperCasePipe } from '@angular/common';
import { CreateDocSampleDto } from '@core/models/dto';
import { ParseDataToFormHelper } from '@core/utils';
import { DocumentTemplate } from '@core/models';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-add-doc-sample-modal',
  standalone: true,
  imports: [
    BadgeComponent,
    ReactiveFormsModule,
    TextInputComponent,
    UpperCasePipe,
  ],
  templateUrl: './add-doc-sample-modal.component.html',
  styleUrl: './add-doc-sample-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDocSampleModalComponent extends DocSampleModal{
  public formGroup = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  });

  formSubmit() {
    this.onFormSubmit({
      ...this.formGroup.value,
    } as CreateDocSampleDto);
  }

  onFormSubmit(requestData: CreateDocSampleDto) {
    if (!this.checkErrors()) return;

    const form = ParseDataToFormHelper(
      requestData,
      this.files()
    );

    this.docsSampleService.createDocSample(form).subscribe({
      next: (sample: DocumentTemplate) => {
        this.alertService.toggle('Образец документа добавлен');
        this.submit.emit(sample);
        this.close.emit();
      },
      error: (error: Error) => {
        console.log(error);
      },
    })
  }
}
