import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { ServiceModal } from '@scripter-admin/app/modals/services-modal/service-modal';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ClassifierTypes, SelectedClassifiers, ServicesTypesEnum } from '@core/utils';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import {
  AlertComponent,
  BadgeComponent,
  DateInputComponent,
  InputComponent,
  MenuPointComponent, TextareaInputComponent,
  TextInputComponent, WysiwygComponent,
} from '@core/components';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { SelectedDocumentsComponent } from '@core/components/selected-documents/selected-documents.component';
import { Classifier, Document, DocumentsList, DocumentTransferMethod, Service } from '@core/models';
import { CreateServiceDto } from '@core/models/dto';
import { AlertService, ClassifiersService, DocumentsTransferMethodsService, RegDocsService, ResultsTransferMethodsService } from '@core/services';

interface CreateServiceForm {
  name: FormControl<string>;
  sierNumber: FormControl<string>;
  isNotForTosp: FormControl<boolean>;
  isNotForUrm: FormControl<boolean>;
  title: FormControl<string>;
  type: FormControl<ServicesTypesEnum>;
  activityStart: FormControl<Date | null>;
  activityEnd: FormControl<Date | null>;
  information: FormControl<string | null>;
  informationProcedure: FormControl<string | null>;
  informationTransferMethod: FormControl<string | null>;
  informationProvisionMethod: FormControl<string | null>;
  informationApplicantCategory: FormControl<string | null>;
  informationApplicantNationality: FormControl<string | null>;
  informationResidenceRequirement: FormControl<string | null>;
  informationApplicationResult: FormControl<string | null>;
  informationServiceResult: FormControl<string | null>;
  informationReceiptOfTheResult: FormControl<string | null>;
  informationAdditional: FormControl<string | null>;
  informationTermOfService: FormControl<string | null>;
  informationListOfDocuments: FormControl<string | null>;
}

@Component({
  selector: 'app-add-service-modal',
  standalone: true,
  imports: [
    AutocompleteComponent,
    BadgeComponent,
    DateInputComponent,
    FormsModule,
    InputComponent,
    MenuPointComponent,
    NgForOf,
    NgIf,
    SelectedDocumentsComponent,
    TextInputComponent,
    TextareaInputComponent,
    WysiwygComponent,
    ReactiveFormsModule,
    NgClass,
  ],
  providers: [AlertService, AlertComponent],
  templateUrl: './add-service-modal.component.html',
  styleUrl: './add-service-modal.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  // TODO: Разобраться, почему из-за него не пропадает dropdown список
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddServiceModalComponent extends ServiceModal implements OnInit {
  private readonly regDocsService = inject(RegDocsService);
  private readonly classifiersService = inject(ClassifiersService);
  private readonly documentsTransferMethodsService = inject(DocumentsTransferMethodsService);
  private readonly resultsTransferMethodsService = inject(ResultsTransferMethodsService);
  formGroup = new FormGroup<CreateServiceForm>({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    title: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    sierNumber: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    activityStart: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    activityEnd: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    type: new FormControl(ServicesTypesEnum.Federal, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isNotForTosp: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isNotForUrm: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    information: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationProcedure: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationTransferMethod: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationProvisionMethod: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationApplicantCategory: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationApplicantNationality: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationResidenceRequirement: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationApplicationResult: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationServiceResult: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationReceiptOfTheResult: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationAdditional: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationTermOfService: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    informationListOfDocuments: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });
  public isAbout = signal<boolean>(true);
  public suggestionDocuments: Document[] = [];
  public selectedDocuments: Document[] = [];

  public suggestionClassifiers = {
    lifeSituations: new Array<Classifier>(),
    applicantCategories: new Array<Classifier>(),
    departments: new Array<Classifier>(),
    divisions: new Array<Classifier>(),
    territoriality: new Array<Classifier>()
  };
  public selectedClassifiers: SelectedClassifiers = new SelectedClassifiers();
  public selectedServiceType: ServicesTypesEnum = ServicesTypesEnum.Federal;
  public activeArray: boolean[] = new Array(14).fill(true, 0, 1).fill(false, 1);

  public selectedDocumentsTransferMethods = signal<DocumentTransferMethod[]>([]);
  public suggestedDocumentsTransferMethods = signal<DocumentTransferMethod[]>([]);

  public suggestedResultsTransferMethods = signal<DocumentTransferMethod[]>([]);
  public selectedResultsTransferMethod = signal<DocumentTransferMethod | null>(null);

  ngOnInit() {
    this.searchDocuments('');
    this.searchClassifiers('lifeSituations', '');
    this.searchClassifiers('applicantCategories', '');
    this.searchClassifiers('departments', '');
    this.searchClassifiers('territoriality', '');
    this.searchClassifiers('divisions', '');
    this.searchDocumentsTransferMethods('');
    this.searchResultsTransferMethods('');
  }

  public handleMenuItemClick(count: number) {
    this.activeArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    this.activeArray[count] = true;
  }

  getDocumentName(doc: Document) {
    return doc.title;
  }

  addDocument(doc: Document) {
    if (this.selectedDocuments.indexOf(doc) < 0) {
      this.selectedDocuments.push(doc);
      this.suggestionDocuments.splice(this.suggestionDocuments.indexOf(doc), 1);
    }
  }

  searchDocuments(name: string) {
    this.regDocsService
      .getRegDocs({
        name,
        from: 0,
        to: 50,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          const selectedIds = this.selectedDocuments.map((document: Document) => document.id);
          this.suggestionDocuments = documents.active.data
            .filter((doc) => !selectedIds.includes(doc.id));
        },
        error: (err: Error) => console.error(err),
      });
  }

  removeDocument(doc: Document) {
    if (this.selectedDocuments.indexOf(doc) >= 0) {
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(doc), 1);
      this.suggestionDocuments.push(doc);
    }
  }

  searchClassifiers(type: ClassifierTypes, name: string) {
    this.classifiersService.getClassifiers({ name, [type]: { from: 0, to: 50 } })
      .subscribe((result) => {
        const selectedIds = this.selectedClassifiers.getIds(type);
        this.suggestionClassifiers[type] = result[type].data.filter((item) => !selectedIds.includes(item.id));
      });
  }

  getClassifierName(classifier: Classifier): string {
    return classifier.name;
  }

  addClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.add(type, classifier as Classifier);
    this.suggestionClassifiers[type].splice(this.suggestionClassifiers[type].indexOf(classifier), 1);
  }

  removeClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.remove(type, classifier);
    this.suggestionClassifiers[type].push(classifier);
  }

  searchDocumentsTransferMethods(name: string){
    this.documentsTransferMethodsService.getDocumentTransferMethods({ name })
      .subscribe((results) => {
        const selectedMethodsIds = this.selectedDocumentsTransferMethods().map(method => method.id);
        this.suggestedDocumentsTransferMethods.set(results.filter(method => !selectedMethodsIds.includes(method.id)));
      });
  }

  addDocumentsTransferMethod(transferMethod: DocumentTransferMethod){
    this.selectedDocumentsTransferMethods.update(methods => [...methods, transferMethod]);
    this.suggestedDocumentsTransferMethods.update(methods => methods.filter(method => method != transferMethod));
  }

  removeDocumentsTransferMethod(transferMethod: DocumentTransferMethod){
    this.suggestedDocumentsTransferMethods.update(methods => [...methods, transferMethod]);
    this.selectedDocumentsTransferMethods.update(methods => methods.filter(method => method != transferMethod));
  }

  searchResultsTransferMethods(name: string){
    this.resultsTransferMethodsService.getResultsTransferMethods({ name })
      .subscribe((results) => {
        this.suggestedResultsTransferMethods.set(results.filter(method => method.id != this.selectedResultsTransferMethod()?.id));
      });
  }

  selectResultsTransferMethod(transferMethod: DocumentTransferMethod | null){
    this.suggestedResultsTransferMethods.update(methods => {
      const deselectedMethod = this.selectedResultsTransferMethod();
      if(transferMethod){
        methods.splice(methods.indexOf(transferMethod), 1);
      }
      if(deselectedMethod){
        methods.push(deselectedMethod);
      }
      return methods;
    });

    this.selectedResultsTransferMethod.set(transferMethod);
  }

  getTransferMethodName(transferMethod: DocumentTransferMethod){
    return transferMethod.name;
  }

  public handleChangeServiceTypeClick(
    type: ServicesTypesEnum,
  ): void {
    this.formGroup.get('type')?.setValue(type);
    this.selectedServiceType = type;
  }

  public getWysiwygDisplay(index: number): string {
    return `display: ${this.activeArray[index] ? 'unset' : 'none'}`;
  }

  formSubmit() {
    const values = this.formGroup.value;

    const requestData: CreateServiceDto = {
      name: values.name,
      sierNumber: values.sierNumber!,
      title: values.title,
      type: this.selectedServiceType,
      activityStart: values.activityStart
        ? new Date(values.activityStart).toISOString()
        : undefined,
      activityEnd: values.activityEnd
        ? new Date(values.activityEnd).toISOString()
        : undefined,
      isNotForTosp: values.isNotForTosp!,
      isNotForUrm: values.isNotForUrm!,
      information: values.information!,
      informationProcedure: values.informationProcedure!,
      informationTransferMethod: values.informationTransferMethod!,
      informationProvisionMethod: values.informationProvisionMethod!,
      informationApplicantCategory: values.informationApplicantCategory!,
      informationApplicantNationality: values.informationApplicantNationality!,
      informationResidenceRequirement: values.informationResidenceRequirement!,
      informationApplicationResult: values.informationApplicationResult!,
      informationServiceResult: values.informationServiceResult!,
      informationReceiptOfTheResult: values.informationReceiptOfTheResult!,
      informationAdditional: values.informationAdditional!,
      informationTermOfService: values.informationTermOfService!,
      informationListOfDocuments: values.informationListOfDocuments!,
      territorialityIds: this.selectedClassifiers.getIds('territoriality'),
      departmentsIds: this.selectedClassifiers.getIds('departments'),
      applicantCategoriesIds: this.selectedClassifiers.getIds(
        'applicantCategories'
      ),
      lifeSituationsIds: this.selectedClassifiers.getIds('lifeSituations'),
      divisionsIds: this.selectedClassifiers.getIds('divisions'),
      documentsIds: this.selectedDocuments.map((doc: Document) => doc.id),
      documentsTransferMethodsIds: this.selectedDocumentsTransferMethods().map(method => method.id),
      resultsTransferMethodId: this.selectedResultsTransferMethod()?.id
    };

    this.onFormSubmit(requestData);
  }


  onFormSubmit(requestData: CreateServiceDto) {
    if (!this.checkErrors()) return;

    this.servicesService.createService(requestData).subscribe({
      next: (service: Service) => {
        this.alertService.toggle('Услуга добавлена');
        this.submit.emit(service);
        this.close.emit();
      },
      error: (err: Error) => console.error(err),
    })
  }

  protected readonly ServicesTypesEnum = ServicesTypesEnum;
}
