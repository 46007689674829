import { Component, EventEmitter, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Service, ServicesList } from '@core/models';
import { AlertService, ServicesService } from '@core/services';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class ServiceModal extends Modal {
  abstract formGroup: FormGroup;
  protected readonly servicesService = inject(ServicesService);
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<Service>();
  public nameError = signal<string>('');
  public titleError = signal<string>('');
  public sierNumberError = signal<string>('');

  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (!values.name?.trim().length) {
      errors['name'] = 'Полное наименование не должно быть пустым';
    }
    if (!values.title?.trim().length) {
      errors['title'] = 'Краткое наименование не должно быть пустым';
    }
    if (!values.sierNumber?.trim().length) {
      errors['sierNumber'] = 'Номер СИЭР не должен быть пустым';
    }

    this.nameError.set(errors['name']);
    this.titleError.set(errors['title']);
    this.sierNumberError.set(errors['sierNumber']);

    return !Object.keys(errors).length;
  }

  public resetInputError(): void {
    this.nameError.set('');
    this.titleError.set('');
    this.sierNumberError.set('');
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }
}
