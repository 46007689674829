import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BadgeComponent,
  IconButtonComponent,
  ButtonComponent,
} from '@core/components';
import { Tab } from '@scripter-admin/app/services-page/service-page.component';
import { RegDocActualComponent } from '@scripter-admin/app/reg-docs-page/reg-doc/tabs/actual/reg-doc-actual.component';
import { RegDocHistoryComponent } from '@scripter-admin/app/reg-docs-page/reg-doc/tabs/history/reg-doc-history.component';
import { Document, DocumentHistory, DocumentsList } from '@core/models';
import { RegDocsService } from '@core/services';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  AddRegDocVersionModalComponent
} from '@scripter-admin/app/modals/reg-docs-modal/add-reg-doc-version-modal/add-reg-doc-version-modal.component';
import {
  EditRegDocModalComponent
} from '@scripter-admin/app/modals/reg-docs-modal/edit-reg-doc-modal/edit-reg-doc-modal.component';

@Component({
  selector: 'app-reg-doc',
  standalone: true,
  imports: [
    IconButtonComponent,
    ButtonComponent,
    NgClass,
    BadgeComponent,
    RegDocActualComponent,
    RegDocHistoryComponent,
  ],
  templateUrl: './reg-doc.component.html',
  styleUrl: './reg-doc.component.scss',
})
export class RegDocComponent implements OnInit, AfterViewInit {
  private overlay = inject(Overlay);
  public regDoc!: Document;
  public relatedDocuments: Document[] = [];
  public documentHistory: DocumentHistory[] = [];
  public tabs: Tab[] = [
    {
      title: 'Актуальная версия',
      class: 'tabs__actual',
    },
    {
      title: 'История версий',
      class: 'tabs__history',
    },
  ];
  public currentTab: string = this.tabs[0].class;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly regDocsService: RegDocsService
  ) {}

  ngOnInit(): void {
    this.regDocsService
      .getRegDocs({
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          this.relatedDocuments = documents.active.data
        },
        error: (err: Error) => console.error(err),
      });
    this.activatedRoute.data.subscribe(({ document }) => {
      this.regDoc = document;
    });
    this.documentHistory = [];
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  public navigate(): void {
    this.router.navigate(['/reg-docs']);
  }

  public handleTabClick(tabName: string): void {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__history':
        document.querySelector('.tabs__history')?.classList.add('tab_active');
        this.currentTab = 'tabs__history';
        break;
    }
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddRegDocVersionModalComponent,
      {
        regDocs: this.relatedDocuments,
        regDoc: this.regDoc,
      }
    );

    modal.componentRef.instance.submit.subscribe((document: Document) => {
      this.regDoc = document;
    });
  }

  openEditModal() {
    const modal = openModal(
      this.overlay,
      EditRegDocModalComponent,
      {
        document: this.regDoc
      }
    );

    modal.componentRef.instance.submit.subscribe((document: Document) => {
      this.regDoc = document;
    });
  }
}
