import { Component, EventEmitter, inject, input, OnInit, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Permission, Role } from '@core/models';
import { AccessEnum } from '@core/utils';
import { AlertService, RolesService } from '@core/services';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class RoleModal extends Modal implements OnInit{
  public permissions = input.required<Permission[]>();
  public selectedPermissions = input.required<Permission[]>();
  public accessPermissions: {id: number, access?: AccessEnum}[] = [];
  abstract formGroup: FormGroup;
  protected readonly rolesService = inject(RolesService);
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<Role>();
  public nameError = signal<string>('');

  ngOnInit() {
    this.accessPermissions = this.selectedPermissions().map(permission => ({
      id: permission.id,
      access: permission.access,
    }))
  }

  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (values.name?.trim().length < 4) {
      errors['name'] = 'Название роли не должно быть пустым или быть меньше 4 символов';
    }

    this.nameError.set(errors['name']);

    return !Object.keys(errors).length;
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }

  public resetInputError(): void {
    this.nameError.set('');
  }

  hasSelectedPermission(id: number): AccessEnum | undefined | null {
    const permission = this.accessPermissions.find(
      selectedPermission => selectedPermission.id === id
    );

    if (!permission) return null;
    return permission.access;
  }

  onSelectClick(permission: Permission, access?: AccessEnum): void {
    this.accessPermissions = this.accessPermissions.filter(
      sP => sP.id !== permission.id
    );

    if (access) {
      this.accessPermissions.push({
        ...permission,
        access,
      });
    }

    this.formGroup.markAsDirty();
  }

  protected readonly AccessEnum = AccessEnum;
}
