<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Изменить данные пользователя
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form area_white_bg"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
      <div class="add-user-role-select">
        <app-select
          type="picked-list"
          formControlName="role"
          [options]="rolesList"
          placeholder="Выберите роли"
        ></app-select>
        @for (role of selectedRoles(); track role.id) {
          <div class="add-user-role-select__picked">
            {{ role.name}}
            <div
              class="add-user-role-select__cancel"
              (click)="handleClickDeleteSelectedRole(role.id)"
            ></div>
          </div>
        }
      </div>
      <div class="add-user-input">
        <p class="text-big">Фамилия</p>
        <core-text-input
          placeholder="Введите фамилию"
          formControlName="lastName"
          (click)="resetInputError()"
          [error]="lastNameError()"
        ></core-text-input>
      </div>
      <div class="add-user-input">
        <p class="text-big">Имя</p>
        <core-text-input
          placeholder="Введите имя"
          formControlName="firstName"
          (click)="resetInputError()"
          [error]="firstNameError()"
        ></core-text-input>
      </div>
      <div class="add-user-input">
        <p class="text-big">Отчество</p>
        <core-text-input
          placeholder="Введите отчество"
          formControlName="middleName"
          (click)="resetInputError()"
          [error]="middleNameError()"
        ></core-text-input>
      </div>
      <div class="add-user-input">
        <app-input
          type="checkbox"
          label="Заблокировать пользователя"
          [control]="formGroup.controls.isBlocked"
          (click)="resetInputError()"
        ></app-input>
        <p class="input-error">{{ blockedError() }}</p>
      </div>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Изменить
      </button>
    </div>
  </form>
</div>
