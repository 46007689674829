import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, DestroyRef, inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, timer, map, shareReplay } from 'rxjs';

import { User } from '@core/models';
import { UserService } from '@core/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-top-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent implements OnInit {
  fullDate = new Date();
  date = 'Пятница, 5 июля 2024';
  name = '';
  destroyRef = inject(DestroyRef);

  constructor(private readonly userService: UserService) {}

  public getFormatedDay(date: Date) {
    const days = [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ];
    const month = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];
    return (
      days[date.getDay()] +
      ', ' +
      date.getDate() +
      ' ' +
      month[date.getMonth()] +
      ' ' +
      date.getFullYear()
    );
  }

  private _time$: Observable<Date> = timer(0, 1000).pipe(
    map(tick => new Date()),
    shareReplay(1)
  );

  get time() {
    return this._time$;
  }

  ngOnInit(): void {
    this.userService.currentUser
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user: User | null) => {
        if (user)
          this.name = `${user.firstName} ${user.lastName[0]}. ${user?.middleName[0]}.`;
      });

    this.date = this.getFormatedDay(this.fullDate);
  }
}
