<div class="roles-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">
      Роли
      <span class="top-div__badge">
        <app-badge
          size="normal"
          display="tint"
          color="secondary-container"
          size_text="default"
          badge_content="{{ roles().length }}"></app-badge>
      </span>
    </h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="openAddModal()"
      ></app-button>
    </div>
  </div>
</div>

<div class="roles">
  @if (roles().length === 0) {
    <p class="text-bigger">Нет ролей</p>
  }
  @for (cont of roles(); track cont.id) {
    <article class="role">
      <div class="role__row">
        <div class="role__row">
          <h3 class="role__text text-big">
            {{ cont.name }}
          </h3>
          @if (isNew(cont.createdAt)) {
            <app-badge
              size="normal"
              style="margin-left: 20px;"
              display="tint"
              color="secondary-container"
              size_text="default"
              badge_content="Новый" />
          }
        </div>
        @if (cont.type !== RolesTypesEnum.Root) {
          <div class="role__buttons">
            <app-icon-button
            size="small"
              type="circle"
              icon="cancel"
              display="outline"
              color="cell"
              (click)="deleteRole(cont.id, cont.name)"
            ></app-icon-button>
            <app-icon-button
              size="small"
              type="circle"
              icon="edit_square"
              display="outline"
              color="cell"
              (click)="openEditModal(cont)"
            ></app-icon-button>
          </div>
        }
      </div>
      <!--      @if (permissions.length) {-->
      <!--        <div class="role__row">-->
      <!--          <div class="role__list">-->
      <!--            <div class="role__row role__list_header">-->
      <!--              <div class="role__list_title">Сущности</div>-->
      <!--              <div class="role__list_status">Доступ</div>-->
      <!--            </div>-->
      <!--            @if (cont.rolesPermissions) {-->
      <!--              @for (-->
      <!--                rolePermission of permissions; track rolePermission; let i = $index-->
      <!--              ) {-->
      <!--                <div class="role__row">-->
      <!--                  <div class="role__list_element">-->
      <!--                    <div class="role__list_title">-->
      <!--                      {{ rolePermission.description }}-->
      <!--                    </div>-->
      <!--                    <div class="role__list_status">-->
      <!--                      @if (hasPermission(cont.id, rolePermission.id)) {-->
      <!--                        Управление-->
      <!--                      } @else {-->
      <!--                        Нет доступа-->
      <!--                      }-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              }-->
      <!--            }-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      }-->
    </article>
  }
</div>
