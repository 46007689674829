import { Classifier } from '@core/models';

export type ClassifierTypes =
  | 'territoriality'
  | 'applicantCategories'
  | 'lifeSituations'
  | 'departments'
  | 'divisions';

export class SelectedClassifiers {
  protected applicantCategories: Classifier[] = [];
  protected territoriality: Classifier[] = [];
  protected lifeSituations: Classifier[] = [];
  protected departments: Classifier[] = [];
  protected divisions: Classifier[] = [];

  add(type: ClassifierTypes, classifier: Classifier): void {
    if (!this[type].find(cl => cl.id === classifier.id))
      this[type].push(classifier);
  }

  remove(type: ClassifierTypes, classifier: Classifier): void {
    this[type] = this[type].filter(c => c.id !== classifier.id);
  }

  show(type: ClassifierTypes): Classifier[] {
    return this[type];
  }

  insert(type: ClassifierTypes, classifiers: Classifier[]): void {
    this[type] = this[type].concat(classifiers);
  }

  getIds(type: ClassifierTypes): number[] {
    return this[type].map(c => c.id);
  }

  get(type: ClassifierTypes): Classifier[] {
    return this[type];
  }

  reset(): void {
    this.applicantCategories = [];
    this.territoriality = [];
    this.lifeSituations = [];
    this.departments = [];
    this.divisions = [];
  }
}
