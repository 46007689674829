import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  TextInputComponent,
} from '@core/components';
import { AlertService, DocumentsTransferMethodsService } from '@core/services';
import { DocumentTransferMethod } from '@core/models';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  AddDocTransferMethodModalComponent,
} from '@scripter-admin/app/modals/docs-transfer-method-modal/add-doc-transfer-method-modal/add-doc-transfer-method-modal.component';
import {
  EditDocTransferMethodModalComponent,
} from '@scripter-admin/app/modals/docs-transfer-method-modal/edit-doc-transfer-method-modal/edit-doc-transfer-method-modal.component';

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-docs-transfer-method-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    TextInputComponent,
    FormsModule,
    ReactiveFormsModule,
    IconButtonComponent,
  ],
  templateUrl: './docs-transfer-method-page.component.html',
  styleUrl: './docs-transfer-method-page.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocsTransferMethodPageComponent implements OnInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  public searchForm: FormGroup<SearchForm>;
  public documentsTransferMethods = signal<DocumentTransferMethod[]>([]);
  public isSearchBlocked: boolean = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly documentsTransferMethodsService: DocumentsTransferMethodsService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ documentsTransferMethods }) => {
      this.documentsTransferMethods.set(documentsTransferMethods);
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  public get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.documentsTransferMethodsService
        .getDocumentTransferMethods({
          name: query,
        })
        .subscribe({
          next: (docTransferMethodList: DocumentTransferMethod[]) => {
            this.documentsTransferMethods.set(docTransferMethodList);
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddDocTransferMethodModalComponent,
      {}
    );

    modal.componentRef.instance.submit.subscribe((method: DocumentTransferMethod) => {
      this.documentsTransferMethods.update((documentsTransferMethods: DocumentTransferMethod[]) => {
        documentsTransferMethods.push(method);
        this.cdr.markForCheck();
        return documentsTransferMethods;
      });
    });
  }

  openEditModal(method: DocumentTransferMethod) {
    const modal = openModal(
      this.overlay,
      EditDocTransferMethodModalComponent,
      {
        method: method
      }
    );

    modal.componentRef.instance.submit.subscribe((method: DocumentTransferMethod) => {
      const index = this.documentsTransferMethods().findIndex(x => x.id === method.id);

      if (index !== -1) {
        this.documentsTransferMethods.update((documentsTransferMethods: DocumentTransferMethod[]) => {
          documentsTransferMethods[index] = method;
          this.cdr.markForCheck();
          return documentsTransferMethods;
        });
      } else {
        throw new Error();
      }
    })
  }

  public handleClickDeleteTransferMethod(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить способ передачи документов "${name}"?`
    );

    if (isAccepted) {
      this.documentsTransferMethodsService
        .deleteDocumentTransferMethod(id)
        .subscribe({
          next: () => {
            this.documentsTransferMethods.set(
              this.documentsTransferMethods().filter(method => method.id !== id)
            );
            this.alertService.toggle('Метод удален');
          },
          error: err => console.error(err),
        });
    } else return;
  }
}
