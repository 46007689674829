@if (regDoc) {
  <div class="reg-docs-container">
    <div class="reg-docs-container__header">
      <div class="reg-docs-container__heading-zone">
        <app-icon-button
          class="reg-docs-container__heading-button"
          size="small"
          type="square"
          icon="arrow_left_alt"
          display="overlay"
          color="container-lowest"
          (click)="navigate()">
        </app-icon-button>
        <p class="reg-docs-container__heading-title title-bold-h5">
          Регламентирующие документы
        </p>
      </div>
      <div class="reg-docs-container__buttons">
        <app-button
          size="smallest"
          type="left"
          icon="add_circle"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="big"
          buttonContent="Создать новую версию"
          (click)="openAddModal()">
        </app-button>
        <app-button
          size="smallest"
          type="left"
          icon="edit_document"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="big"
          buttonContent="Редактировать"
          (click)="openEditModal()"
        >
        </app-button>
        <app-button
          size="smallest"
          type="left"
          display="outline"
          color="outline-low"
          sizeText="big"
          buttonContent="Закрыть"
          (click)="navigate()">
        </app-button>
      </div>
    </div>
    <div class="reg-docs-container__tabs">
      <div class="container__tabs">
        @for (tab of tabs; track tab) {
          <button
            class="tab"
            [ngClass]="tab.class"
            (click)="handleTabClick(tab.class)">
            {{ tab.title }}
          </button>
        }
      </div>
    </div>
    @switch (currentTab) {
      @case ('tabs__actual') {
        <app-actual [regDoc]="regDoc"></app-actual>
      }
      @case ('tabs__history') {
        <app-history
          [documentHistory]="regDoc.versions"
          [title]="regDoc.title"></app-history>
      }
    }
  </div>
}
