import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnInit, signal,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, ButtonComponent } from '@core/components';
import { AlertService, ClassifiersService, ServicesService } from '@core/services';
import { ClassifiersWithTotalList, Service, ServicesList } from '@core/models';
import { shouldLoadDataHelper } from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { debounceTime } from 'rxjs';
import {
  ServicesPageAttentionComponent,
} from '@scripter-admin/app/services-page/tabs/services-page-attention/services-page-attention.component';
import {
  ServicesPageListComponent,
} from '@scripter-admin/app/services-page/tabs/services-page-list/services-page-list.component';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  AddServiceModalComponent,
} from '@scripter-admin/app/modals/services-modal/add-service-modal/add-service-modal.component';
import {
  EditServiceModalComponent,
} from '@scripter-admin/app/modals/services-modal/edit-service-modal/edit-service-modal.component';
import {
  CloneServiceModalComponent,
} from '@scripter-admin/app/modals/services-modal/clone-service-modal/clone-service-modal.component';
import {
  FilterServiceModalComponent,
} from '@scripter-admin/app/modals/services-modal/filter-service-modal/filter-service-modal.component';

export type Tab = {
  title: string;
  class: string;
};

@Component({
  selector: 'app-services-page',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    NgClass,
    ReactiveFormsModule,
    TextInputComponent,
    ServicesPageAttentionComponent,
    ServicesPageListComponent,
  ],
  templateUrl: './service-page.component.html',
  styleUrl: './service-page.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePageComponent implements OnInit, AfterViewInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  public searchForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });
  private isLoaded: boolean = false;
  private isFullData: Map<string, boolean> = new Map<string, boolean>();
  services = signal<Service[]>([]);
  archivedServices = signal<Service[]>([]);
  needAttention = signal<Service[]>([]);
  filteredData: any = {};
  isFiltered: boolean = false;
  classifiers!: ClassifiersWithTotalList;
  tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
    {
      title: 'Требуют внимания',
      class: 'tabs__need-attention',
    },
  ];
  currentTab = signal<string>(this.tabs[0].class);

  constructor(
    private router: Router,
    private readonly servicesService: ServicesService,
    private readonly alertService: AlertService,
    private readonly classifierService: ClassifiersService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.isFullData.set('tabs__active', false);
    this.isFullData.set('tabs__archive', false);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (
      shouldLoadDataHelper() &&
      !this.isLoaded &&
      !this.isFullData.get(this.currentTab())
    ) {
      this.isLoaded = true;
      this.servicesService
        .getServices({
          ...this.filteredData,
          from:
            this.currentTab() === 'tabs__actual'
              ? this.services().length
              : this.archivedServices().length,
          to:
            this.currentTab() === 'tabs__actual'
              ? this.services().length + 5
              : this.archivedServices().length + 5,
        })
        .subscribe({
          next: (servicesList: ServicesList) => {
            if (this.currentTab() === 'tabs__actual') {
              if (servicesList.active.length === 0) {
                this.isFullData.set(this.currentTab(), true);
              } else {
                this.services.set(this.services().concat(servicesList.active));
              }
            } else {
              if (servicesList.archive.length === 0) {
                this.isFullData.set(this.currentTab(), true);
              } else {
                this.archivedServices.set(
                  this.archivedServices().concat(servicesList.archive)
                );
              }
            }
            this.isLoaded = false;
          },
        });
    }
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ services }) => {
      this.services.set(services.active);
      this.archivedServices.set(services.archive);
      this.needAttention.set(services.needAttention);
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
    this.classifierService.getClassifiers()
      .subscribe((classifiers) => {
        this.classifiers = classifiers;
      });
  }

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__need-attention':
        document
          .querySelector('.tabs__need-attention')
          ?.classList.add('tab_active');
        this.currentTab.set('tabs__need-attention');
        break;
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab.set('tabs__actual');
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab.set('tabs__archive');
        break;
    }
  }

  handleSearch(query: string): void {
    this.servicesService
      .getServices({
        name: query,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (services: ServicesList) => {
          this.services.set(services.active);
          this.archivedServices.set(services.archive);
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
  }

  handleFiltersResetClick(): void {
    this.filteredData = {};
    this.servicesService.getServices({ from: 0, to: 5 }).subscribe({
      next: (services: ServicesList) => {
        this.services.set(services.active);
        this.archivedServices.set(services.archive);
        this.isFiltered = false;
        this.isFullData.clear();
        this.isLoaded = false;
        this.alertService.toggle('Фильтр сброшен');
      },
    });
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddServiceModalComponent,
      {}
    );

    modal.componentRef.instance.submit.subscribe((service: Service) => {
      this.services.set([service].concat(this.services()));
    });
  }

  openEditModal(id: number) {
    const service = this.currentTab() === 'tabs__actual' ?
      this.services().find(service => service.id === id) :
      this.archivedServices().find(service => service.id === id);
      
    this.loadServiceInformation(id).then(
      (service) => {
        const modal = openModal(
          this.overlay,
          EditServiceModalComponent,
          {
            service: service,
          }
        );
    
        modal.componentRef.instance.submit.subscribe((service: Service) => {
          const index = this.currentTab() === 'tabs__actual' ?
            this.services().findIndex(x => x.id === service.id) :
            this.archivedServices().findIndex(x => x.id === service.id);
    
          if (index !== -1) {
            this.currentTab() === 'tabs__actual' ?
              this.services.update((services: Service[]) => {
                services[index] = service;
                return [...services];
              })
              :
              this.archivedServices.update((services: Service[]) => {
                services[index] = service;
                return [...services];
              });
          } else {
            throw new Error();
          }
        });
      }
    );
  }

  openCloneModal() {
    const modal = openModal(
      this.overlay,
      CloneServiceModalComponent,
      {}
    );

    modal.componentRef.instance.submit.subscribe((service: Service) => {
      this.router.navigate([`/services/${service.id}`]);
    })
  }

  openFilterModal() {
    const modal = openModal(
      this.overlay,
      FilterServiceModalComponent,
      {
        classifiers: this.classifiers,
      }
    );

    modal.componentRef.instance.submit.subscribe((requestData: any) => {
      this.filteredData = requestData;
      this.isFiltered = true;
      this.servicesService.getServices(requestData).subscribe({
        next: (services: ServicesList) => {
          this.services.set(services.active);
          this.archivedServices.set(services.archive);
        },
        error: (err: Error) => console.error(err),
      });
    });
  }

  loadServiceInformation(serviceId: number) {
    return new Promise<Service>((resolve) => {
      this.servicesService.getServiceById(serviceId).subscribe(resolve);
    });
  }
}
