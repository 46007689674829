import { Component, EventEmitter, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService, ClassifiersService } from '@core/services';
import { Classifier } from '@core/models';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class ClassifierModal extends Modal {
  abstract formGroup: FormGroup;
  protected readonly classifiersService = inject(ClassifiersService);
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<Classifier>();
  public nameError = signal<string>('');
  public subdivisionTypeError = signal<string>('');
  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (!values.name?.trim().length) {
      errors['name'] = 'Название не может быть пустым';
    }
    if (this.formGroup.contains('subdivisionType') && !values.subdivisionType?.trim().length) {
      errors['subdivisionType'] = 'Тип подразделения не может быть пустым';
    }

    this.nameError.set(errors['name']);
    this.subdivisionTypeError.set(errors['subdivisionType']);

    return !Object.keys(errors).length;
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }

  public resetInputError(): void {
    this.nameError.set('');
    this.subdivisionTypeError.set('');
  }
}
