import { ChangeDetectionStrategy, Component, input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, TextInputComponent } from '@core/components';
import { Role } from '@core/models';
import { UpdateRoleDto } from '@core/models/dto';
import { AlertService } from '@core/services';
import { RoleModal } from '@scripter-admin/app/modals/roles-modal/role-modal';

@Component({
  selector: 'app-edit-role-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './edit-role-modal.component.html',
  styleUrl: './edit-role-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRoleModalComponent extends RoleModal implements OnInit {
  private role = input.required<Role>();
  formGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  });

  override ngOnInit() {
    super.ngOnInit();
    this.formGroup.controls.name.setValue(this.role().name);
  }

  formSubmit() {
    this.onFormSubmit({
      name: this.formGroup.value.name!,
      permissionsIds: this.accessPermissions.map(permission => ({
        id: permission.id,
        access: permission.access,
      }))
    } as UpdateRoleDto)
  }

  protected onFormSubmit(requestData: UpdateRoleDto) {
    if (!this.checkErrors()) return;

    this.rolesService.updateRole(this.role().id, requestData).subscribe({
      next: (role: Role) => {
        this.alertService.toggle('Роль изменена');
        this.submit.emit(role);
        this.close.emit();
      },
      error: (err: Error & { statusCode: number }) => {
        if (err.statusCode === 409) {
          this.nameError.set('Роль с таким именем уже существует');
        } else {
          this.nameError.set('Произошла неизвестная ошибка, пожалуйста, попробуйте позже');
        }
      },
    })
  }
}
