import { CommonModule, NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, signal, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  imports: [NgClass, CommonModule, ReactiveFormsModule],
  standalone: true,
})
export class AutocompleteComponent<T> implements OnInit {
  @Input() fill = false;
  @Input() placeholder: string = 'Search...';
  @Input() inputControl = new FormControl(''); // Controlled input value
  @Input() suggestions: T[] = []; // Controlled suggestions
  @Input() showSelected = false; 
  @Input() getName = (suggestion: T) => '';
  @Output() inputValueChange = new EventEmitter<string>(); // Emits input changes
  @Output() selected = new EventEmitter<T>(); // Emits when a suggestion is selected

  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;

  lastSelected: T | null = null;
  filteredSuggestions: T[] = [];
  isInputFocused = false;
  showSuggestions = signal(false);
  selectedIndex = -1;

  ngOnInit(): void {
    // Listen for input changes and filter suggestions
    this.inputControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if(this.inputElement.nativeElement)
      this.inputValueChange.emit(value || ""); // Emit the input value change
      this.filterSuggestions(value || ""); // Filter suggestions based on the input value
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Listen to changes in suggestions to update filtered suggestions
    if (changes['suggestions'] && !changes['suggestions'].isFirstChange()) {
      this.filterSuggestions(this.inputControl.value || "");
    }
  }

  filterSuggestions(query: string): void {
    if (this.isInputFocused) { // Check if input is focused
      this.filteredSuggestions = this.suggestions.filter((suggestion) =>
        this.getName(suggestion).toLowerCase().includes(query.toLowerCase())
      );
      this.showSuggestions.set(this.filteredSuggestions.length > 0);
    } else {
      this.showSuggestions.set(false);
    }
    this.selectedIndex = -1;
  }

  onInputFocus(): void {
    this.isInputFocused = true;
    this.filterSuggestions(this.inputControl.value || "");
  }

  onInputBlur(): void {
    this.isInputFocused = false;
    if(this.showSelected){
      if(this.lastSelected){
        this.inputControl.setValue(this.getName(this.lastSelected));
      } else {
        this.inputControl.setValue("");
      }
    }
    setTimeout(() => {
      this.showSuggestions.set(false);
    }, 200);
  }

  onSelect(suggestion: T): void {
    this.selected.emit(suggestion); // Emit selected suggestion
    this.showSuggestions.set(false);
    if(this.showSelected){
      this.lastSelected = suggestion;
      this.inputControl.setValue(this.getName(this.lastSelected));
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' && this.selectedIndex < this.filteredSuggestions.length - 1) {
      this.selectedIndex++;
    } else if (event.key === 'ArrowUp' && this.selectedIndex > 0) {
      this.selectedIndex--;
    } else if (event.key === 'Enter' && this.selectedIndex >= 0) {
      this.onSelect(this.filteredSuggestions[this.selectedIndex]);
      event.preventDefault();
    }
  }
}