import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit, signal,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  DateInputComponent,
  IconButtonComponent,
} from '@core/components';
import { ClassifiersEnum, ClassifierTypes, ServicesTypesEnum } from '@core/utils';
import { ServiceModal } from '@scripter-admin/app/modals/services-modal/service-modal';
import { Classifier, ClassifiersWithTotalList, ClassifierType } from '@core/models';
import { AlertService, ClassifiersService } from '@core/services';
import { CheckboxComponent } from '@core/components/checkbox/checkbox.component';

interface SearchForm {
  activityStart: FormControl<Date | null>;
  activityEnd: FormControl<Date | null>;
  isNotForTosp: FormControl<boolean>;
  isNotForUrm: FormControl<boolean>;
  types: FormControl<string[]>;
  lifeSituations: FormControl<number[]>;
  applicantCategories: FormControl<number[]>;
  departments: FormControl<number[]>;
  divisions: FormControl<number[]>;
  territoriality: FormControl<number[]>;
}

type OrderBy = {
  name: string;
  key: string;
  field: string;
  by: 'asc' | 'desc';
};

@Component({
  selector: 'app-filter-service-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BadgeComponent,
    ButtonComponent,
    DateInputComponent,
    IconButtonComponent,
    CheckboxComponent,
  ],
  providers: [AlertService, AlertComponent],
  templateUrl: './filter-service-modal.component.html',
  styleUrl: './filter-service-modal.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterServiceModalComponent extends ServiceModal implements OnInit {
  private readonly classifierService = inject(ClassifiersService);
  private classifiers = input.required<ClassifiersWithTotalList>();
  lifeSituations = signal<Classifier[]>([]);
  applicantCategories = signal<Classifier[]>([]);
  departments = signal<Classifier[]>([]);
  divisions = signal<Classifier[]>([]);
  territoriality = signal<Classifier[]>([]);
  formGroup = new FormGroup<SearchForm>({
    activityStart: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    activityEnd: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    isNotForTosp: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isNotForUrm: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    types: new FormControl<string[]>([], {
      nonNullable: true
    }),
    lifeSituations: new FormControl<number[]>([], {
      nonNullable: true,
    }),
    applicantCategories: new FormControl<number[]>([], {
      nonNullable: true,
    }),
    departments: new FormControl<number[]>([], {
      nonNullable: true,
    }),
    divisions: new FormControl<number[]>([], {
      nonNullable: true,
    }),
    territoriality: new FormControl<number[]>([], {
      nonNullable: true,
    }),
  });
  classifiersTypes: ClassifierType[] = [
    {
      name: 'Жизненные ситуации',
      key: ClassifiersEnum.lifeSituations,
      opened: true,
    },
    {
      name: 'Категория заявителей',
      key: ClassifiersEnum.applicantCategories,
      opened: true,
    },
    {
      name: 'Территориальная принадлежность',
      key: ClassifiersEnum.territoriality,
      opened: true,
    },
    {
      name: 'Подразделение МФЦ',
      key: ClassifiersEnum.departments,
      opened: true,
    },
    {
      name: 'Ведомство',
      key: ClassifiersEnum.divisions,
      opened: true,
    },
  ];
  orderByList: OrderBy[] = [
    {
      name: 'Сперва новые',
      field: 'createdAt',
      key: 'createdAt_desc',
      by: 'desc',
    },
    {
      name: 'Сперва старые',
      field: 'createdAt',
      key: 'createdAt_asc',
      by: 'asc',
    },
  ];
  selectedOrder: OrderBy = this.orderByList[0];
  classifiersCountStep: number = 10;

  ngOnInit() {
    this.lifeSituations.set(this.classifiers().lifeSituations.data);
    this.applicantCategories.set(this.classifiers().applicantCategories.data);
    this.departments.set(this.classifiers().departments.data);
    this.territoriality.set(this.classifiers().territoriality.data);
    this.divisions.set(this.classifiers().divisions.data);
  }

  typeIsExists(type: string): boolean {
    return this.formGroup.controls.types.value!.includes(type);
  }

  toggleType(type: string): void {
    const control = this.formGroup.controls.types;
    const value = control.value || [];

    if (!value?.includes(type)) {
      control.setValue([...value, type]);
    } else {
      control.setValue(value.filter(x => x !== type));
    }
  }

  getTypeName(type: string): string {
    switch (type) {
      case 'Federal':
        return 'Федеральный';
      case 'Regional':
        return 'Региональный';
      case 'Municipal':
        return 'Муниципальный';
      default:
        return '';
    }
  }

  classifierHasId(type: ClassifierTypes, id: number): boolean {
    return this.formGroup.controls[type].value!.includes(id);
  }

  toggleClassifier(type: ClassifierTypes, id: number): void {
    const control = this.formGroup.controls[type];
    const value = control.value || [];

    if (!value?.includes(id)) {
      control.setValue([...value, id]);
    } else {
      control.setValue(value.filter(x => x !== id));
    }
  }

  getClassifiersListByType(type: ClassifierTypes): Classifier[] {
    return this[type]();
  }

  getClassifiersTotalByType(type: ClassifierTypes): number {
    switch (type) {
      case ClassifiersEnum.applicantCategories:
        return this.classifiers().applicantCategories.total;
      case ClassifiersEnum.lifeSituations:
        return this.classifiers().lifeSituations.total;
      case ClassifiersEnum.departments:
        return this.classifiers().departments.total;
      case ClassifiersEnum.divisions:
        return this.classifiers().divisions.total;
      case ClassifiersEnum.territoriality:
        return this.classifiers().territoriality.total;
    }
    return 0;
  }

  toggleOpened(key: string): void {
    const type = this.classifiersTypes.find(
      type => type.key === key
    ) as ClassifierType;
    type.opened = !type.opened;
  }

  showMoreClick(classifierType: ClassifierType): void {
    this.classifierService
      .getClassifiers({
        [classifierType.key]: {
          from: 0,
          to: this[classifierType.key]().length + this.classifiersCountStep,
        },
      })
      .subscribe({
        next: (classifiers: ClassifiersWithTotalList) => {
          this[classifierType.key].set(classifiers[classifierType.key].data);
        },
      });
  }

  formSubmit() {
    const values = this.formGroup.value;

    const requestData: any = {
      activityStart: values.activityStart,
      activityEnd: values.activityEnd,
      isNotForTosp: values.isNotForTosp,
      isNotForUrm: values.isNotForUrm,
      type: values.types,
      applicantCategoriesIds: values.applicantCategories,
      lifeSituationsIds: values.lifeSituations,
      departmentsIds: values.departments,
      territorialityIds: values.territoriality,
      divisionsIds: values.divisions,
      orderBy: {
        field: this.selectedOrder.field,
        by: this.selectedOrder.by,
      },
    }

    this.onFormSubmit(requestData);
  }

  onFormSubmit(requestData: any) {
    this.alertService.toggle('Фильтр применён');
    this.submit.emit(requestData);
    this.close.emit();
  }

  protected readonly ServicesTypesEnum = ServicesTypesEnum;
  protected readonly Object = Object;
}
