import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MenuPointComponent } from '@core/components';
import { Service } from '@core/models';
import { urlify } from '@core/utils/helpers/urlify';

@Component({
  selector: 'app-service-reference',
  standalone: true,
  imports: [
    MenuPointComponent,
  ],
  templateUrl: './service-reference.component.html',
  styleUrl: './service-reference.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceReferenceComponent {
  service = input.required<Service>();
  public activeArray: boolean[] = new Array(14).fill(true, 0, 1).fill(false, 1);

  handleMenuItemClick(count: number) {
    this.activeArray = this.activeArray.map((_, index) => index == count);
  }

  getInformationHTML(type: keyof Service){
    const information = this.service()[type];
    if(information && typeof information == 'string'){
      return urlify(information);
    }
    return 'Нет данных';
  }
}
