import { ChangeDetectionStrategy, Component, input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { DocSampleModal } from '@scripter-admin/app/modals/docs-sample-modal/doc-sample-modal';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DocumentTemplate } from '@core/models';
import { AlertComponent, BadgeComponent, TextInputComponent } from '@core/components';
import { UpperCasePipe } from '@angular/common';
import { ParseDataToFormHelper } from '@core/utils';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-edit-doc-sample-modal',
  standalone: true,
  imports: [
    BadgeComponent,
    ReactiveFormsModule,
    TextInputComponent,
    UpperCasePipe,
  ],
  templateUrl: './edit-doc-sample-modal.component.html',
  styleUrl: './edit-doc-sample-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDocSampleModalComponent extends DocSampleModal implements OnInit {
  public sample = input.required<DocumentTemplate>();
  public formGroup = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  });
  public currentSample: DocumentTemplate = {
    id: 0,
    createdAt: '',
    title: '',
    documentsTemplatesAttachments: [],
  };
  private attachmentsIdsToRemove: number[] = [];

  ngOnInit() {
    Object.assign(this.currentSample, this.sample());
    this.formGroup.controls.title.setValue(this.sample().title);
  }

  deleteAttachment(id: number): void {
    this.attachmentsIdsToRemove.push(id);
    this.currentSample.documentsTemplatesAttachments = this.currentSample.documentsTemplatesAttachments.filter(
      e => e.id !== id
    )
  }

  formSubmit() {
    this.onFormSubmit({
      ...this.formGroup.value,
      attachmentsIdsToRemove: this.attachmentsIdsToRemove,
    })
  }

  onFormSubmit(requestData: any) {
    if (!this.checkErrors()) return;

    const form = ParseDataToFormHelper(
      requestData,
      this.files()
    )

    this.docsSampleService.updateDocSample(this.currentSample.id, form).subscribe({
      next: (sample: DocumentTemplate) => {
        this.alertService.toggle('Образец изменен');
        this.submit.emit(sample);
        this.close.emit();
      },
      error: (error: Error) => {
        console.log(error);
      },
    })
  }
}
