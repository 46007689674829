import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlify',
  standalone: true,
  pure: true
})
export class UrlifyPipe implements PipeTransform {
  transform(value: string): string {
    return urlify(value)
  }
}

export function urlify(value: string): string {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return value.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank" class="link">' + url + '</a>';
    });
}