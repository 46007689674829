import { Component, EventEmitter, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService, RegDocsService } from '@core/services';
import { Document } from '@core/models';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class RegDocModal extends Modal {
  public files = signal<File[]>([]);
  abstract formGroup: FormGroup;
  protected readonly regDocsService = inject(RegDocsService);
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<Document>();
  public titleError = signal<string>('');

  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (!values.title?.trim().length) {
      errors['title'] = 'Полное наименование не должно быть пустым';
    }

    this.titleError.set(errors['title']);

    return !Object.keys(errors).length;
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }

  public resetInputError(): void {
    this.titleError.set('');
  }
}
