<div class="services-ui">
  <div class="docs-sample-header">
    <div class="docs-sample-header__left">
      <p class="docs-sample-header__title title-bold-h1">Образцы документов</p>
      <app-badge
        class="docs-sample-header__badge"
        size="normal"
        display="tint"
        color="secondary-container"
        size_text="default"
        badge_content="{{ docsSamples().length }}">
      </app-badge>
    </div>
    <app-button
      size="normal"
      type="left"
      icon="add_circle"
      [fill]="true"
      display="fill"
      color="primary"
      sizeText="default"
      buttonContent="Добавить"
      (click)="openAddModal()"
    ></app-button>
  </div>
  <div class="docs-sample-search area_white_bg">
    <div class="docs-sample-search__input">
      <core-text-input
        placeholder="Поиск документа"
        leftIcon="search"
        [formControl]="name"></core-text-input>
    </div>
  </div>
  <div class="docs-sample-tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!-- <button class="filter">
      Сперва новые
      <span class="material-symbols-outlined"> keyboard_arrow_down </span>
    </button> -->
  </div>
</div>
<div class="docs-sample-main">
  @if (currentTab === 'tabs__actual') {
    @if (docsSamples().length) {
      <div class="docs-sample-main__list">
        @for (docsSample of docsSamples(); track docsSample.id) {
          <div class="docs-sample-main__item">
            <div
              class="item-header"
              [ngClass]="{
                border_bottom:
                  docsSample.documentsTemplatesAttachments.length > 0
              }">
              <p class="item-header__title title-bold-h5 on-container">
                {{ docsSample.title }}
              </p>
              <div class="actions">
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="cancel"
                  display="outline"
                  color="outline-low"
                  (click)="deleteDocSample(docsSample.id, docsSample.title)"
                ></app-icon-button>
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="edit_square"
                  display="outline"
                  color="container-lowest"
                  (click)="openEditModal(docsSample)"
                ></app-icon-button>
              </div>
            </div>
            @if (
              docsSample.documentsTemplatesAttachments &&
              docsSample.documentsTemplatesAttachments.length
            ) {
              <div class="item-main">
                @for (
                  container of docsSample.documentsTemplatesAttachments;
                  track container.id
                ) {
                  <div class="item-main__point">
                    <p class="item-main__title text-default on-container">
                      {{ container.originalName }}
                    </p>
                    <!--                  <p class="item-main__author">{{ container.author }}</p>-->
                    <div class="item-main__info">
                      <!--                    <div class="item-main__info-date text-default on-container-low">от {{ container.dateStart }}</div>-->
                      <app-badge
                        size="small"
                        display="tint"
                        color="tertiary-container"
                        size_text="small"
                        badge_content="{{
                          getFileExtensionHelper(container)
                        }}"></app-badge>
                      <div
                        class="item-main__info-size text-default on-container-low">
                        {{ convertFileSizeHelper(container.size) }}
                      </div>
                      <a
                        class="item-main__info-link text-default"
                        (click)="openFileInNewTabHelper(container.link)"
                        >Открыть</a
                      >
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    } @else {
      <p class="text-bigger">Нет актуальных образцов документов</p>
    }
  } @else {
    @if (archived().length > 0) {
      <div class="docs-sample-main__list">
        @for (docsSample of archived(); track docsSample.id) {
          <div class="docs-sample-main__item">
            <div
              class="item-header"
              [ngClass]="{
                border_bottom:
                  docsSample.documentsTemplatesAttachments.length > 0
              }">
              <p class="item-header__title title-bold-h5 on-container">
                {{ docsSample.title }}
              </p>
              <div class="actions">
                <!-- <app-icon-button
                  size="small"
                  type="circle"
                  icon="cancel"
                  display="outline"
                  color="outline-low"
                  (click)="
                    deleteDocSample(docsSample.id, docsSample.title)
                  "></app-icon-button>
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="edit_square"
                  display="outline"
                  color="container-lowest"
                  (click)="openEditModal(docsSample.id)"></app-icon-button> -->
              </div>
            </div>
            @if (
              docsSample.documentsTemplatesAttachments &&
              docsSample.documentsTemplatesAttachments.length
            ) {
              <div class="item-main">
                @for (
                  container of docsSample.documentsTemplatesAttachments;
                  track container.id
                ) {
                  <div class="item-main__point">
                    <p class="item-main__title text-default on-container">
                      {{ container.originalName }}
                    </p>
                    <!--                  <p class="item-main__author">{{ container.author }}</p>-->
                    <div class="item-main__info">
                      <!--                    <div class="item-main__info-date text-default on-container-low">от {{ container.dateStart }}</div>-->
                      <app-badge
                        size="small"
                        display="tint"
                        color="tertiary-container"
                        size_text="small"
                        badge_content="{{
                          getFileExtensionHelper(container)
                        }}"></app-badge>
                      <div
                        class="item-main__info-size text-default on-container-low">
                        {{ convertFileSizeHelper(container.size) }}
                      </div>
                      <a
                        class="item-main__info-link text-default"
                        (click)="openFileInNewTabHelper(container.link)"
                        >Открыть</a
                      >
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    } @else {
      <p class="text-bigger">Нет архивных образцов документов</p>
    }
  }
</div>
