import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, TextInputComponent } from '@core/components';
import { AlertService } from '@core/services';
import { CreateRoleDto } from '@core/models/dto';
import { Role } from '@core/models';
import { RoleModal } from '@scripter-admin/app/modals/roles-modal/role-modal';

@Component({
  selector: 'app-add-role-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './add-role-modal.component.html',
  styleUrl: './add-role-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRoleModalComponent extends RoleModal {
  formGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  })

  formSubmit() {
    this.onFormSubmit({
      name: this.formGroup.value.name!,
      permissionsIds: this.accessPermissions.map(permission => ({
        id: permission.id,
        access: permission.access!,
      }))
    })
  }

  protected onFormSubmit(requestData: CreateRoleDto) {
    if (!this.checkErrors()) return;

    this.rolesService.createRole(requestData).subscribe({
      next: (role: Role) => {
        this.alertService.toggle('Роль добавлена');
        this.submit.emit(role);
        this.close.emit();
      },
      error: (err: Error & { statusCode: number }) => {
        if (err.statusCode === 409) {
          this.nameError.set('Роль с таким именем уже существует');
        } else {
          this.nameError.set('Произошла неизвестная ошибка, пожалуйста, попробуйте позже');
        }
      },
    })
  }
}
