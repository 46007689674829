import { ChangeDetectionStrategy, Component, input, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertComponent, DateInputComponent, TextInputComponent } from '@core/components';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegDocModal } from '@scripter-admin/app/modals/reg-docs-modal/reg-doc-modal';
import { Document } from '@core/models';
import { UpdateRegDocDto } from '@core/models/dto';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-edit-reg-doc-modal',
  standalone: true,
  imports: [
    DateInputComponent,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './edit-reg-doc-modal.component.html',
  styleUrl: './edit-reg-doc-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRegDocModalComponent extends RegDocModal implements OnInit {
  private document = input.required<Document>();
  formGroup = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  });

  ngOnInit() {
    this.formGroup.controls.title.setValue(this.document().title);
  }

  formSubmit() {
    this.onFormSubmit({
      ...this.formGroup.value,
    })
  }

  onFormSubmit(requestData: UpdateRegDocDto) {
    if (!this.checkErrors()) return;

    this.regDocsService.updateRegDoc(this.document().id, requestData).subscribe({
      next: (document: Document) => {
        this.alertService.toggle('Документ изменен');
        this.submit.emit(document);
        this.close.emit();
      }
    })
  }
}
