<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Обновить услугу
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form"
    [formGroup]="formGroup"
    (submit)="formSubmit()"
  >
    <div class="app-modal-container__body">
      <div class="modal">
        <div class="tabs tabs_border">
          <button
            class="tab"
            type="button"
            [ngClass]="{ tab_active: isAbout() }"
            (click)="isAbout.set(true)">
            Об услуге
          </button>
          <button
            class="tab"
            type="button"
            [ngClass]="{ tab_active: !isAbout() }"
            (click)="isAbout.set(false)">
            Справка
          </button>
        </div>
        @if (isAbout()) {
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            1. Общая информация
          </h5>
          <div class="modal__general csm_container area_beige_bg">
            <p class="modal__input-title">Полное наименование услуги</p>
            <div class="modal__search_general modal__search_general_multiline">
              <core-textarea-input
                placeholder="Полное наименование услуги"
                formControlName="name"
                (click)="resetInputError()"
                [error]="nameError()"
              ></core-textarea-input>
            </div>
            <p class="modal__input-title">Краткое наименование</p>
            <div class="modal__search_general">
              <core-text-input
                placeholder="Краткое наименование"
                formControlName="title"
                (click)="resetInputError()"
                [error]="titleError()"
              ></core-text-input>
            </div>
            <p class="modal__input-title">Номер СИЭР</p>
            <div class="modal__search_general">
              <core-text-input
                placeholder="Номер СИЭР"
                formControlName="sierNumber"
                (click)="resetInputError()"
                [error]="sierNumberError()"
              ></core-text-input>
            </div>
            <div class="modal__general-period">
              <p class="modal__input-title title-grid1">Дата начала активности</p>
              <core-date-input
                class="input-grid1"
                formControlName="activityStart"
              ></core-date-input>
              <p class="modal__input-title title-grid2">
                Дата окончания активности
              </p>
              <core-date-input
                class="input-grid2"
                formControlName="activityEnd"
              ></core-date-input>
            </div>
            <p class="modal__input-title">Принцип предоставления</p>
            <div class="modal__general-service-types">
              <div
                [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Federal
              }"
                (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Federal
                )
              ">
                Федеральный
              </div>
              <div
                [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Regional
              }"
                (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Regional
                )
              ">
                Региональный
              </div>
              <div
                [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Municipal
              }"
                (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Municipal
                )
              ">
                Муниципальный
              </div>
            </div>
            <div class="modal__search_general">
              <app-input
                type="checkbox"
                label="Не предоставляется для ТОСП"
                [control]="formGroup.controls.isNotForTosp"
                (click)="resetInputError()"
                labelVersion="regular"
              ></app-input>
            </div>
            <div class="modal__search_general">
              <app-input
                type="checkbox"
                label="Не предоставляется для УРМ"
                [control]="formGroup.controls.isNotForUrm"
                (click)="resetInputError()"
                labelVersion="regular"
              ></app-input>
            </div>

            <p class="modal__input-title">Способы передачи документов</p>

            @if (selectedDocumentsTransferMethods().length) {
              <div class="badges">
                @for (transferMethod of selectedDocumentsTransferMethods(); track transferMethod) {
                  <div class="badge">
                    <app-badge
                      badge_content="{{ transferMethod.name }}"
                      size="normal"
                      display="fill"
                      color="inverse-container"
                      size_text="default"
                      right_icon="cancel"
                      [fill]="true"
                      [used_right_icon]="true"
                      (cancelClickDetection)="removeDocumentsTransferMethod(transferMethod)"
                    ></app-badge>
                  </div>
                }
              </div>
            }

            <div class="transfers-selector">
              <app-autocomplete
                placeholder="Найти cпособ передачи..."
                [suggestions]="suggestedDocumentsTransferMethods()"
                [getName]="getTransferMethodName"
                (inputValueChange)="searchDocumentsTransferMethods($event)"
                (selected)="addDocumentsTransferMethod($event)"
              ></app-autocomplete>
            </div>

            <p class="modal__input-title">Способ получения результата</p>

            @if (selectedResultsTransferMethod()) {
              <div class="badges">
                  <div class="badge">
                    <app-badge
                      badge_content="{{ selectedResultsTransferMethod()?.name }}"
                      size="normal"
                      display="fill"
                      color="inverse-container"
                      size_text="default"
                      right_icon="cancel"
                      [fill]="true"
                      [used_right_icon]="true"
                      (cancelClickDetection)="selectResultsTransferMethod(null)"
                    ></app-badge>
                  </div>
              </div>
            } @else {
              <div class="transfers-selector">
                <app-autocomplete
                  placeholder="Найти cпособ получения..."
                  [suggestions]="suggestedResultsTransferMethods()"
                  [getName]="getTransferMethodName"
                  (inputValueChange)="searchResultsTransferMethods($event)"
                  (selected)="selectResultsTransferMethod($event)"
                ></app-autocomplete>
              </div>
            }
          </div>

          <div class="modal__relations">
            <h5
              class="title-bold-h5 modal__title modal__title_info modal__title_relations">
              2. Связи с документами
            </h5>
            <div class="modal__relations-select-container">
              <app-autocomplete
                [fill]="true"
                placeholder="Выбрать документы для связи"
                [suggestions]="suggestionDocuments"
                [getName]="getDocumentName"
                (inputValueChange)="searchDocuments($event)"
                (selected)="addDocument($event)"
              ></app-autocomplete>

              @if (selectedDocuments.length) {
                <div class="modal__relations-list">
                  <app-selected-documents
                    [items]="selectedDocuments"
                    (remove)="removeDocument($event)"
                  ></app-selected-documents>
                </div>
              }
            </div>
          </div>

          <div class="modal__classifiers">
            <h5 class="title-bold-h5 modal__title modal__title_info">
              3. Классификаторы
            </h5>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Жизненные ситуации
              </h5>

              @if (selectedClassifiers.get('lifeSituations').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('lifeSituations'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('lifeSituations', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['lifeSituations']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('lifeSituations', $event)"
                  (selected)="addClassifier('lifeSituations', $event)"
                ></app-autocomplete>
              </div>
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Категория заявителей
              </h5>

              @if (selectedClassifiers.get('applicantCategories').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('applicantCategories'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('applicantCategories', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['applicantCategories']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('applicantCategories', $event)"
                  (selected)="addClassifier('applicantCategories', $event)"
                ></app-autocomplete>
              </div>
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Подразделения МФЦ
              </h5>

              @if (selectedClassifiers.get('departments').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('departments'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('departments', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['departments']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('departments', $event)"
                  (selected)="addClassifier('departments', $event)"
                ></app-autocomplete>
              </div>
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Ведомства
              </h5>

              @if (selectedClassifiers.get('divisions').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('divisions'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('divisions', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              } @else {
                <div class="classifiers-selector">
                  <app-autocomplete
                    placeholder="Найти классификатор..."
                    [suggestions]="suggestionClassifiers['divisions']"
                    [getName]="getClassifierName"
                    (inputValueChange)="searchClassifiers('divisions', $event)"
                    (selected)="addClassifier('divisions', $event)"
                  ></app-autocomplete>
                </div>
              }
            </div>
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Территориальность
              </h5>

              @if (selectedClassifiers.get('territoriality').length) {
                <div class="badges">
                  @for (classifier of selectedClassifiers.get('territoriality'); track classifier) {
                    <div class="badge">
                      <app-badge
                        badge_content="{{ classifier.name }}"
                        size="normal"
                        display="fill"
                        color="inverse-container"
                        size_text="default"
                        right_icon="cancel"
                        [fill]="true"
                        [used_right_icon]="true"
                        (cancelClickDetection)="removeClassifier('territoriality', classifier)"
                      ></app-badge>
                    </div>
                  }
                </div>
              }

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['territoriality']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('territoriality', $event)"
                  (selected)="addClassifier('territoriality', $event)"
                ></app-autocomplete>
              </div>
            </div>
          </div>
        }

        @if (!isAbout()) {
          <div class="ref-info">
            <nav class="left-navigation left-navigation_wysiwyg">
              <app-menu-point
                text="Общее описание"
                [shouldNavigate]="false"
                [isActive]="activeArray[0]"
                (click)="handleMenuItemClick(0)"
              ></app-menu-point>

              <app-menu-point
                text="Порядок действий"
                [shouldNavigate]="false"
                [isActive]="activeArray[1]"
                (click)="handleMenuItemClick(1)"
              ></app-menu-point>

              <app-menu-point
                text="Способ передачи заявления и документов"
                [shouldNavigate]="false"
                [isActive]="activeArray[2]"
                (click)="handleMenuItemClick(2)"
              ></app-menu-point>

              <app-menu-point
                text="Уровень предоставления"
                [shouldNavigate]="false"
                [isActive]="activeArray[3]"
                (click)="handleMenuItemClick(3)"
              ></app-menu-point>

              <app-menu-point
                text="Категория заявителей"
                [shouldNavigate]="false"
                [isActive]="activeArray[4]"
                (click)="handleMenuItemClick(4)"
              ></app-menu-point>

              <app-menu-point
                text="Гражданство заявителя"
                [shouldNavigate]="false"
                [isActive]="activeArray[5]"
                (click)="handleMenuItemClick(5)"
              ></app-menu-point>

              <app-menu-point
                text="Требование проживания"
                [shouldNavigate]="false"
                [isActive]="activeArray[6]"
                (click)="handleMenuItemClick(6)"
              ></app-menu-point>

              <app-menu-point
                text="Результат приема заявления"
                [shouldNavigate]="false"
                [isActive]="activeArray[7]"
                (click)="handleMenuItemClick(7)"
              ></app-menu-point>

              <app-menu-point
                text="Результат предоставления услуги"
                [shouldNavigate]="false"
                [isActive]="activeArray[8]"
                (click)="handleMenuItemClick(8)"
              ></app-menu-point>

              <app-menu-point
                text="Поступление результата в МФЦ"
                [shouldNavigate]="false"
                [isActive]="activeArray[10]"
                (click)="handleMenuItemClick(10)"
              ></app-menu-point>

              <app-menu-point
                text="Дополнительные сведения"
                [shouldNavigate]="false"
                [isActive]="activeArray[11]"
                (click)="handleMenuItemClick(11)"
              ></app-menu-point>

              <app-menu-point
                text="Срок предоставления"
                [shouldNavigate]="false"
                [isActive]="activeArray[12]"
                (click)="handleMenuItemClick(12)"
              ></app-menu-point>
              
              <app-menu-point
                text="Список документов"
                [shouldNavigate]="false"
                [isActive]="activeArray[13]"
                (click)="handleMenuItemClick(13)"
              ></app-menu-point>
            </nav>
            <div class="texts-container texts-container_wysiwyg">
              <app-wysiwyg
                heading="Общее описание"
                [control]="formGroup.controls.information"
                style="{{ getWysiwygDisplay(0) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Порядок действий"
                [control]="formGroup.controls.informationProcedure"
                style="{{ getWysiwygDisplay(1) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Способ передачи заявления и документов"
                [control]="formGroup.controls.informationTransferMethod"
                style="{{ getWysiwygDisplay(2) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Уровень предоставления"
                [control]="formGroup.controls.informationProvisionMethod"
                style="{{ getWysiwygDisplay(3) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Категория заявителей"
                [control]="formGroup.controls.informationApplicantCategory"
                style="{{ getWysiwygDisplay(4) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Гражданство заявителя"
                [control]="formGroup.controls.informationApplicantNationality"
                style="{{ getWysiwygDisplay(5) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Требование проживания"
                [control]="formGroup.controls.informationResidenceRequirement"
                style="{{ getWysiwygDisplay(6) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Результат приема заявления"
                [control]="formGroup.controls.informationApplicationResult"
                style="{{ getWysiwygDisplay(7) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Результат предоставления услуги"
                [control]="formGroup.controls.informationServiceResult"
                style="{{ getWysiwygDisplay(8) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Поступление результата в МФЦ"
                [control]="formGroup.controls.informationReceiptOfTheResult"
                style="{{ getWysiwygDisplay(10) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Дополнительные сведения"
                [control]="formGroup.controls.informationAdditional"
                style="{{ getWysiwygDisplay(11) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Срок предоставления"
                [control]="formGroup.controls.informationTermOfService"
                style="{{ getWysiwygDisplay(12) }}"
              ></app-wysiwyg>
              <app-wysiwyg
                heading="Список документов"
                [control]="formGroup.controls.informationListOfDocuments"
                style="{{ getWysiwygDisplay(13) }}"
              ></app-wysiwyg>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
      >
        Сохранить
      </button>
    </div>
  </form>
</div>
