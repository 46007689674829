import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, inject, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Service, ServicesList, Subdivision, User } from '@core/models';
import { ServicesService, UserService } from '@core/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SearchComponent } from '@core/components';
import { SearchBlockComponent } from '@core/components/overlay/client-top-menu/search-block/search-block.component';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import { Overlay } from '@angular/cdk/overlay';
import { ClientTopMenuModalComponent } from '@scripter-admin/app/modals/client-side-modals/client-top-menu-modal/client-top-menu-modal.component';

interface SearchForm {
  name: FormControl<string | null>;
}

@Component({
  selector: 'app-client-menu',
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    SearchBlockComponent,
  ],
  templateUrl: './client-top-menu.component.html',
  styleUrl: './client-top-menu.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
})
export class ClientTopMenuComponent implements OnInit {
  private overlay = inject(Overlay);
  private destroyRef = inject(DestroyRef);
  searchServices = signal<Service[]>([]);
  searchForm: FormGroup<SearchForm>;
  user: User | null = null;
  name: string = '';
  isSearchBlocked = false;
  isSearched: boolean = false;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly servicesService: ServicesService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.searchForm = new FormGroup<SearchForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: false,
      }),
    });
  }

  ngOnInit(): void {
    this.userService.currentUser
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user: User | null) => {
        this.user = user;
        if (this.user)
          this.name = `${this.user.firstName} ${this.user.lastName[0]}. ${this.user.middleName[0]}.`;
      });
    this.router.events.subscribe(() => {
      this.isSearched = false;
      this.isSearchBlocked = false;
      this.searchForm.reset();
    });
  }

  get searchName(): FormControl<string | null> {
    return this.searchForm.controls.name;
  }

  handleSearch(): void {
    const data = {
      name: (this.searchName?.value as string) ?? '',
      from: 0,
      to: 5,
    };
    if (!data.name.length) this.isSearched = false;
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.servicesService.getServices(data).subscribe({
        next: (services: ServicesList) => {
          this.searchServices.set(services.active);
          this.isSearchBlocked = false;
          this.isSearched = !!data.name.length;
        },
        error: (err: Error) => {
          console.error(err);
          this.isSearchBlocked = false;
          this.isSearched = false;
        },
      });
    }
  }

  handleLogout() {
    this.userService.logout()
  }

  handleClear(): void {
    this.isSearched = false;
  }

  handleClearDepartment() {
    this.userService.clearSubdivision().subscribe({
      next: () => {
        this.changeDetector.detectChanges();
      }
    });
  }

  async handleLogoClick(): Promise<void> {
    await this.router.navigate(['/']);
  }

  openEditModal() {
    const modal = openModal(
      this.overlay,
      ClientTopMenuModalComponent,
      {}
    );

    modal.componentRef.instance.submit.subscribe((subdivision: Subdivision) => {
      this.userService.selectSubdivision(subdivision.id).subscribe({
        next: () => {
          if (this.user!.usersDepartments?.department) {
            this.user!.usersDepartments!.department.name = subdivision.name;
            this.changeDetector.detectChanges();
          }
        },
      });
    })
  }
}
