import { ChangeDetectionStrategy, Component, OnInit, signal, ViewEncapsulation } from '@angular/core';
import {
  AlertComponent,
  EmailInputComponent,
  InputComponent,
  SelectComponent,
  TextInputComponent,
} from '@core/components';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserModal } from '@scripter-admin/app/modals/users-modal/user-modal';
import { CreateUserDto } from '@core/models/dto';
import { User } from '@core/models';
import { AlertService } from '@core/services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-user-modal',
  standalone: true,
  imports: [
    EmailInputComponent,
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
  ],
  templateUrl: './add-user-modal.component.html',
  styleUrl: './add-user-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUserModalComponent extends UserModal implements OnInit {
  formGroup = new FormGroup({
    role: new FormControl<string | undefined>(undefined),
    lastName: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    firstName: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    middleName: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    login: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    })
  });
  public loginError = signal<string>('');

  ngOnInit() {
    this.formGroup.controls.role.valueChanges.subscribe(value => {
      if (value) {
        const role = this.roles().find(x => x.id === Number(value));
        if (role) {
          this.selectedRoles().push(role);
        }
        this.formGroup.controls.role.setValue(undefined);
      }
    });
  }

  formSubmit() {
    const { role, ...otherValues } = this.formGroup.value;

    this.onFormSubmit({
      ...otherValues,
      rolesIds: this.selectedRoles().map(role => role.id),
    } as CreateUserDto)
  }

  onFormSubmit(requestData: CreateUserDto) {
    if (!this.checkErrors()) return;

    this.usersService.createUser(requestData).subscribe({
      next: (user: User) => {
        this.alertService.toggle('Пользователь добавлен');
        this.submit.emit(user);
        this.close.emit();
      },
      error: (err: HttpErrorResponse & { statusCode: number }) => {
        if (err.statusCode === 409) {
          this.loginError.set('Пользователь с таким email уже существует');
        } else {
          console.error(err);
        }
      },
    })
  }
  protected override checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (!values.login || !/^\S+@\S+\.\S+$/.test(values.login)) {
      errors['login'] = 'Неверный формат email';
    }

    this.loginError.set(errors['login']);
    return super.checkErrors() && !Object.keys(errors).length;
  }

  override resetInputError() {
    super.resetInputError();
    this.loginError.set('');
  }
}
