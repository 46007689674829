import { ChangeDetectionStrategy, Component, input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertComponent, TextInputComponent } from '@core/components';
import { AlertService } from '@core/services';
import {
  DocTransferMethodModal
} from '@scripter-admin/app/modals/docs-transfer-method-modal/doc-transfer-method-modal';
import { DocumentTransferMethod } from '@core/models';
import { CreateDocumentTransferMethodDto, UpdateDocumentTransferMethodDto } from '@core/models/dto';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-doc-transfer-method-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './edit-doc-transfer-method-modal.component.html',
  styleUrl: './edit-doc-transfer-method-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDocTransferMethodModalComponent extends DocTransferMethodModal implements OnInit {
  private method = input.required<DocumentTransferMethod>();
  formGroup = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  ngOnInit() {
    this.formGroup.controls.name.setValue(this.method().name);
  }

  formSubmit() {
    this.onFormSubmit({
      ...this.formGroup.value,
    } as CreateDocumentTransferMethodDto);
  }

  onFormSubmit(requestData: UpdateDocumentTransferMethodDto) {
    if (!this.checkErrors()) return;

    this.documentsTransferMethodsService.updateDocumentTransferMethod(this.method().id, requestData).subscribe({
      next: (method: DocumentTransferMethod) => {
        this.alertService.toggle('Способ передачи изменен');
        this.submit.emit(method);
        this.close.emit();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    })
  }
}
