<div class="service-container">
  <core-section-header title="Услуга" [buttons]="buttons"></core-section-header>
  <div class="tabs">
    <button
      class="tab"
      [ngClass]="{ tab_active: isAbout }"
      (click)="isAbout = true">
      Об услуге
    </button>
    <button
      class="tab"
      [ngClass]="{ tab_active: !isAbout }"
      (click)="isAbout = false">
      Справка
    </button>
    @if (route.snapshot.queryParams['type'] === 'needAttention' || isNeedAttention) {
      <p class="text_error text-big">
        <img src="../../../assets/icons/error-icon.svg" alt="Ошибка" />
        Изменены регулирующие документы. Подтвердите актуальность услуги
      </p>
    }
  </div>
  @if (isAbout) {
    <app-service-about [service]="service"></app-service-about>
  }
  @else {
    <app-service-reference [service]="service"></app-service-reference>
  }
</div>
