<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      Изменить подразделение
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form"
    [formGroup]="formGroup"
  >
    <div class="app-modal-container__body">
      <div class="subdivision">
        <div class="subdivision_block">
          <h1>Выберите подразделение</h1>
          <app-search
            class="top-menu__search-input"
            borderRadius="large"
            placeholder="Поиск"
            [control]="formGroup.controls.name"
            (changeEvent)="handleSubdivisionsSearch()"
          ></app-search>
          <div class="subdivision_block-list">
            @for (subdivision of subdivisions; track $index) {
              <div
                class="subdivision_block-list-element"
                (click)="handleSelectSubdivisionClick(subdivision)"
              >
                {{ $index + 1 + '. ' + subdivision.name }}
                <span>({{ getSubdivisionTypeTranslation(subdivision) }})</span>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal-container__buttons">
      <button
        class="app-modal-container__cancel modal-button subtitle-default"
        type="button"
        (click)="closeModal()"
      >
        Отменить
      </button>
      <button
        class="app-modal-container__submit modal-button subtitle-default"
        type="button"
      >
        Изменить
      </button>
    </div>
  </form>
</div>
