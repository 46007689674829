import { Component, EventEmitter, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService, DocumentsTransferMethodsService } from '@core/services';
import { DocumentTransferMethod } from '@core/models';
import { Modal } from '../modal';

@Component({
  template: ``,
})
export abstract class DocTransferMethodModal extends Modal {
  abstract formGroup: FormGroup;
  protected readonly documentsTransferMethodsService = inject(DocumentsTransferMethodsService);
  protected readonly alertService = inject(AlertService);
  public submit = new EventEmitter<DocumentTransferMethod>();
  public nameError = signal<string>('');

  protected checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (values.name?.trim().length < 4) {
      errors['name'] = 'Название способа передачи не может быть короче 4 символов';
    }

    this.nameError.set(errors['name']);

    return !Object.keys(errors).length;
  }

  override closeModal() {
    if (this.formGroup.dirty && !confirm(`Вы действительно хотите закрыть модальное окно?`)) return;
    this.close.emit();
  }

  public resetInputError(): void {
    this.nameError.set('');
  }
}
