import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { AlertComponent, BadgeComponent, ButtonComponent, IconButtonComponent } from '@core/components';
import { AlertService, RegDocsService } from '@core/services';
import { Document, DocumentsList } from '@core/models';
import { getDocumentActualVersionHelper, parsePeriodHelper } from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  AddRegDocModalComponent,
} from '@scripter-admin/app/modals/reg-docs-modal/add-reg-doc-modal/add-reg-doc-modal.component';
import {
  EditRegDocModalComponent,
} from '@scripter-admin/app/modals/reg-docs-modal/edit-reg-doc-modal/edit-reg-doc-modal.component';

type Tab = {
  title: string;
  class: string;
};

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-services-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    IconButtonComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
    RouterLink,
  ],
  templateUrl: './reg-docs-page.component.html',
  styleUrl: './reg-docs-page.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegDocsPageComponent implements OnInit, AfterViewInit {
  private overlay = inject(Overlay);
  private cdr = inject(ChangeDetectorRef);
  public searchForm: FormGroup<SearchForm>;
  public regDocs = signal<Document[]>([]);
  public archivedRegDocs = signal<Document[]>([]);
  public needAttentionRegDocs = signal<Document[]>([]);
  public totalCount: number = 0;
  public needAttentionRegDocsTotal: number = 0;
  public isSearchBlocked: boolean = false;

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
    {
      title: 'Требуют внимания',
      class: 'tabs__need-attention',
    },
  ];
  public currentTab: string = this.tabs[0].class;

  constructor(
    private readonly alertService: AlertService,
    private readonly regDocsService: RegDocsService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ regDocs }) => {
      this.regDocs.set(regDocs.active.data ?? []);
      this.archivedRegDocs.set(regDocs.archive.data ?? []);
      this.needAttentionRegDocs.set(regDocs.needAttention.data ?? []);
      this.totalCount = regDocs.active.total;
      this.needAttentionRegDocsTotal = regDocs.needAttention.total ?? 0;
    });

    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  public handleSearch(query: string): void {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.regDocsService
        .getRegDocs({
          name: query,
        })
        .subscribe({
          next: (regDocsList: DocumentsList) => {
            this.regDocs.set(regDocsList.active.data);
            this.archivedRegDocs.set(regDocsList.archive.data);
            this.totalCount = regDocsList.active.total;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__need-attention':
        document
          .querySelector('.tabs__need-attention')
          ?.classList.add('tab_active');
        this.currentTab = 'tabs__need-attention';
        break;
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  openAddModal() {
    const modal = openModal(
      this.overlay,
      AddRegDocModalComponent,
      {
        regDocs: this.regDocs(),
      }
    );

    modal.componentRef.instance.submit.subscribe((document: Document) => {
      this.regDocs.update((Documents: Document[]) => {
        Documents.unshift(document);
        this.cdr.markForCheck();
        return Documents;
      });
    });
  }

  openEditModal(document: Document) {
    const modal = openModal(
      this.overlay,
      EditRegDocModalComponent,
      {
        document: document
      }
    );

    modal.componentRef.instance.submit.subscribe((document: Document) => {
      const index = this.regDocs().findIndex(x => x.id === document.id);

      if (index !== -1) {
        this.regDocs.update((Documents: Document[]) => {
          Documents[index] = document;
          this.cdr.markForCheck();
          return Documents;
        })
      } else {
        throw new Error();
      }
    })
  }

  public handleDeleteClick(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить документ "${name}"?`
    );

    if (isAccepted) {
      this.regDocsService.deleteRegDoc(id).subscribe({
        next: () => {
          this.regDocs.set(this.regDocs().filter(document => document.id !== id));
          this.archivedRegDocs.set(
            this.archivedRegDocs().filter(document => document.id !== id)
          );
          this.alertService.toggle('Документ удален');
        },
        error: err => console.error(err),
      });
    } else return;
  }

  parseActivity = parsePeriodHelper;
  getActualVersion = getDocumentActualVersionHelper;
}
