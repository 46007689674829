import { Component, DestroyRef, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  ButtonComponent,
  IconButtonComponent,
  ModalComponent,
} from '@core/components';
import { RecoverService } from '@core/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface RecoverForm {
  login: FormControl<string>;
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    ModalComponent,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './recover.component.html',
  styleUrl: './recover.component.scss',
})
export class RecoverComponent {
  destroyRef = inject(DestroyRef);
  isSubmitted = false;
  recoverForm: FormGroup;
  showSuccess = false;
  error = '';

  constructor(private readonly recoverService: RecoverService) {
    this.recoverForm = new FormGroup<RecoverForm>({
      login: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  get login(): FormControl<string> {
    return this.recoverForm.get('login') as FormControl<string>;
  }

  private validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  hideLogin(): string {
    if (this.login.value) {
      const sliced = this.login.value.split('@');
      const first = sliced[0].slice(0, 2) + '*'.repeat(sliced[0].length - 2);
      return first + '@' + sliced[1];
    }
    return '';
  }

  onFormSubmit() {
    if(!this.login.value){
      this.error = 'Введите Email';
      return;
    }
    
    if (!this.validateEmail(this.login.value)) {
      this.error = 'Email введен не корректно';
      return;
    }
    
    this.isSubmitted = true;

    this.recoverService
      .recoverPassword({ login: this.login.value })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.showSuccess = true;
        },
        error: (err: Error & { statusCode: number }) => {
          if (err.statusCode === 400) {
            this.error = 'Email введен не корректно';
          } else if (err.statusCode === 404) {
            this.error = 'Такой пользователь не зарегистрирован';
          } else {
            this.error = 'Ошибка сервера, повторите попытку позже';
          }
          this.login.reset();
          this.isSubmitted = false;
        },
      });
  }

  resetError(): void {
    this.error = '';
  }
}
