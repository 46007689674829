import { ChangeDetectionStrategy, Component, input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { UserModal } from '@scripter-admin/app/modals/users-modal/user-modal';
import {
  AlertComponent,
  EmailInputComponent,
  InputComponent,
  SelectComponent,
  TextInputComponent,
} from '@core/components';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Role, User, UserRole } from '@core/models';
import { AlertService } from '@core/services';
import { UpdateUserDto } from '@core/models/dto';

@Component({
  selector: 'app-edit-user-modal',
  standalone: true,
  imports: [
    EmailInputComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
    InputComponent,
  ],
  templateUrl: './edit-user-modal.component.html',
  styleUrl: './edit-user-modal.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserModalComponent extends UserModal implements OnInit {
  private userRoles = input.required<UserRole[]>();
  private user = input.required<User>();
  public blockedError = signal<string>('');

  formGroup = new FormGroup({
    role: new FormControl<string | undefined>(undefined),
    lastName: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    firstName: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    middleName: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    isBlocked: new FormControl(false, {
      validators: [Validators.required],
      nonNullable: true,
    })
  })

  ngOnInit() {
    this.formGroup.controls.role.valueChanges.subscribe(value => {
      if (value) {
        const role = this.roles().find(x => x.id === Number(value));
        if (role) {
          this.selectedRoles().push(role);
        }
        this.formGroup.controls.role.setValue(undefined);
      }
    });
    this.selectedRoles.set(this.userRoles().map(item => item.role))
    this.formGroup.controls.lastName.setValue(this.user().lastName);
    this.formGroup.controls.firstName.setValue(this.user().firstName);
    this.formGroup.controls.middleName.setValue(this.user().middleName);
    this.formGroup.controls.isBlocked.setValue(this.user().isBlocked);
  }

  formSubmit() {
    const { role, ...otherValues } = this.formGroup.value;

    this.onFormSubmit({
      ...otherValues,
      rolesIds: this.selectedRoles().map(role => role.id),
    } as UpdateUserDto)
  }

  onFormSubmit(requestData: UpdateUserDto) {
    if (!this.checkErrors()) return;

    this.usersService.updateUser(this.user().id, requestData).subscribe({
      next: (user: User) => {
        this.alertService.toggle('Данные пользователя изменены');
        this.submit.emit(user);
        this.close.emit();
      },
      error: (error: any) => console.error(error),
    })
  }

  protected override checkErrors(): boolean {
    const values = this.formGroup.value;
    const errors: Record<string, string> = {};

    if (values.isBlocked) {
      const currentUser = JSON.parse(
        localStorage.getItem('user') as string
      ) as User;
      if (currentUser.id === this.user().id) {
        errors['isBlocked'] = 'Нельзя заблокировать самого себя';
      }
    }

    this.blockedError.set(errors['isBlocked']);
    return super.checkErrors() && !Object.keys(errors).length;
  }

  override resetInputError() {
    super.resetInputError();
    this.blockedError.set('');
  }
}
